'use client'

import Icon from '@susu/headless-commerce/components/Icon/Icon'
import Loader from '@susu/headless-commerce/components/Loader/Loader'
import { useCountry } from '@susu/headless-commerce/contexts/country/client'
import { useLocale } from '@susu/headless-commerce/contexts/locale/client'
import { useUpdateSubscribeMutation } from '@susu/headless-commerce/gql/generated/updateSubscribe.urql'
import { usePageType } from '@susu/headless-commerce/hooks/usePageType'
import { convertLocaleToCommerceGraphQLFormat } from '@susu/headless-commerce/utils/localeUtils'
import { URLData } from '@susu/headless-commerce/utils/tracking/segment'
import { trackEvent } from '@susu/headless-commerce/utils/tracking/tracking'
import { isValidEmailAddress } from '@susu/headless-commerce/utils/validation'
import { useI18N } from '@susu/i18n/client'
import { useCallback, useEffect, useMemo, useState } from 'react'
import styles from './FooterNewsletter.module.scss'

export default function ClientFooterNewsletter() {
  const locale = useLocale()
  const [email, setEmail] = useState<string>('')
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [invalid, setInvalid] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)
  const country = useCountry()
  const { t } = useI18N()
  const pageType = usePageType()
  const { url, locationId } = URLData()
  const [errorMessage, setErrorMessage] = useState<string>(
    t('footer.please_enter_email'),
  )

  const [
    { fetching: subscribeFetching, data: subscribeData, error },
    mutateFunction,
  ] = useUpdateSubscribeMutation()

  const loaderStyle = useMemo(() => ({ width: '100%' }), [])

  if (error) {
    if (success === true) {
      setSuccess(false)
    }
    if (invalid === true) {
      setInvalid(false)
    }
  }

  const trackSubscribeEvent = useCallback(
    async (trackSuccess: boolean) => {
      await trackEvent({
        ga: {
          eventCategory: 'Footer_Interactions',
          eventAction: 'Newsletter_Subscribe',
          eventLabel: 'Email_Verified',
        },
        segment: {
          event: 'newsletterSubscribe',
          enrichProps: {
            locale,
            country,
          },
          properties: {
            eventCategory: 'footer_interactions',
            eventLabel: trackSuccess
              ? 'email_verified'
              : `email_error_${errorMessage}`,
            eventLocation: 'footer',
            locationId,
            pageType,
            url,
          },
        },
      })
    },
    [country, errorMessage, locale, locationId, pageType, url],
  )

  const handleSubmit = useCallback(
    async (event: React.SyntheticEvent<HTMLFormElement>) => {
      event.preventDefault()

      setSubmitted(true)

      if (!isValidEmailAddress(email) || email === '') {
        setInvalid(true)
        trackSubscribeEvent(false)
        return
      }

      await mutateFunction(
        {
          updateSubscribeInput: {
            subscribe: true,
            email: email.trim(),
            fromCheckout: false,
            locale: convertLocaleToCommerceGraphQLFormat(locale),
          },
        },
        {
          clientName: 'commerce',
        },
      )

      trackSubscribeEvent(true)
      setEmail('')
    },
    [email, locale, mutateFunction, trackSubscribeEvent],
  )

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (success === true) {
        setSuccess(false)
      }
      if (invalid === true) {
        setInvalid(false)
      }
      if (email !== event.target.value) {
        setEmail(event.target.value)
      }
    },
    [email, invalid, success],
  )

  const handleOnBlur = useCallback(() => {
    if (success === true) {
      setSuccess(false)
    }
    if (!isValidEmailAddress(email)) {
      setInvalid(true)
    }
  }, [email, success])

  useEffect(() => {
    if (submitted && email === '') {
      setErrorMessage(t('footer.please_enter_email'))
    } else if (invalid) {
      setErrorMessage(t('footer.invalid_email_address'))
    }
  }, [invalid, submitted, t, email])

  useEffect(() => {
    if (subscribeFetching && !submitted) {
      setSubmitted(true)
    } else if (!subscribeFetching && (subscribeData || error)) {
      setSubmitted(false)

      if (subscribeData?.updateSubscribe?.success) {
        setSuccess(true)
      } else if (subscribeData?.updateSubscribe?.success === false) {
        setSuccess(false)
        setInvalid(true)
      }
    }
  }, [
    subscribeData?.updateSubscribe?.success,
    error,
    submitted,
    subscribeData,
    subscribeFetching,
  ])

  return (
    <>
      <form
        className={`${styles['footer-newsletter-form']} ${
          invalid
            ? styles['footer-newsletter-form-error']
            : styles['footer-newsletter-form-valid']
        } `}
        onSubmit={handleSubmit}
      >
        <input
          className={`${styles['footer-newsletter-input']} body-regular-sm body-regular-lg`}
          value={email}
          type="text"
          name="email"
          placeholder={t('footer.enter_your_email')}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
        />
        <button
          className={`${styles['footer-newsletter-button']} body-small-regular-sm body-regular-lg`}
          type="submit"
        >
          <span
            className={`${styles['footer-newsletter-icon']} ${
              invalid || !success ? styles.hidden : styles.visible
            }`}
          >
            <Icon icon="check" aria-hidden="true" />
          </span>
          {subscribeFetching ? (
            <Loader shade="dark" style={loaderStyle} />
          ) : (
            t('footer.subscribe')
          )}
        </button>
      </form>
      {invalid && (
        <div
          className={`${styles['footer-newsletter-message']} ${styles['footer-newsletter-error']} caption-light`}
        >
          {errorMessage}
        </div>
      )}
      {success && !invalid && (
        <div
          className={`${styles['footer-newsletter-message']} ${styles['footer-newsletter-success']} caption-light`}
        >
          {t('footer.sign_up')}
        </div>
      )}
    </>
  )
}
