'use client'

import type { ProductTrackingData } from '@susu/headless-commerce/components/ProductCard/ClientProductCardImageLink'
import { customerPromise } from '@susu/headless-commerce/contexts/customer'
import type {
  Maybe,
  Product,
} from '@susu/headless-commerce/gql/generated/graphql'
import type {
  CreativeValueType,
  PageLoadedProperties,
  PageTypeValueType,
  ProductListViewedProperties,
  Products,
} from '@susu/headless-commerce/libs/avo/avo'
import {
  inspectorPromise,
  loaded,
} from '@susu/headless-commerce/libs/segment/avoDestination'
import type { EnrichEventsProps } from '@susu/headless-commerce/libs/segment/utils'
import {
  enrichEvent,
  gaLoaded,
  gaPromise,
} from '@susu/headless-commerce/libs/segment/utils'
import type { PageType } from '@susu/headless-commerce/types/PageType'
import { addScript } from '@susu/headless-commerce/utils/addScript'
import {
  isBrowser,
  isDevelopment,
  isServer,
} from '@susu/headless-commerce/utils/environment'
import { observeOnce } from '@susu/headless-commerce/utils/interserctionObserver'
import {
  GAPromotionData,
  GATrackingProductClick,
  productImpressions,
  pushGA,
} from '@susu/headless-commerce/utils/tracking/GA'
import log from '@susu/log'
import { isNotNull } from '@susu/null'
import { parallel } from '@susu/promise'
import { isNotUndefined, isUndefined } from '@susu/undefined'
import type { RefObject } from 'react'
import type { TrackEventSegmentProps } from './TrackEventSegmentProps'
import { GA_DEFAULT_EVENT } from './constants'
import { getElementRefPosition } from './getElementRefPosition'
import type { CommonEventProperties, SegmentProductProps } from './segment'
import {
  URLData,
  segmentProduct,
  segmentPromotionData,
  segmentTrackingProductClick,
} from './segment'
import { queueTrackingEvent } from './trackingEventsList'

declare global {
  interface Window {
    dataLayer: Array<Record<string, unknown>>
  }
}

export type PromotionEvents = {
  promotionView?: Maybe<string>
  promotionClick?: Maybe<string>
  promotionCreative?: Maybe<string>
  promotionDimension24?: Maybe<string>
  promotionDimension26?: Maybe<string>
  promotionEventAction?: Maybe<string>
  promotionEventCategory?: Maybe<string>
  promotionEventLabel?: Maybe<string>
  promotionPosition?: Maybe<number>
}

export type BaseEventProps = {
  enrichProps: EnrichEventsProps
}

export type GAProductTrackingData = {
  enrichProps: EnrichEventsProps
  product: Product
  tracking: ProductTrackingData
}

export type TrackEventProps = {
  ga?: {
    event?: string
    eventCategory: string
    eventAction: string
    eventLabel: string
  }
  segment?: TrackEventSegmentProps
}

declare global {
  interface Window {
    googleTrackingScriptInserted: boolean
    cookieTrackingScriptInserted: boolean
  }
}

// A map of observed lists to prevent duplicate tracking.
const observedList = new Map<RefObject<Element>, boolean>()

// General tooling for tracking
export const insertCookieTrackingScript = () => {
  if (isDevelopment() || (isBrowser() && window.cookieTrackingScriptInserted)) {
    return
  }
  addScript(
    `https://cdn.cookielaw.org/consent/${process.env.NEXT_PUBLIC_ONETRUST_ID}/OtAutoBlock.js`,
  )
  addScript('https://cdn.cookielaw.org/scripttemplates/otSDKStub.js', {
    'data-document-language': 'true',
    'data-ot-ignore': 'true',
    charSet: 'UTF-8',
    'data-domain-script': `${process.env.NEXT_PUBLIC_ONETRUST_ID}`,
  })
  // @ts-ignore
  window.OptanonWrapper = () => {}
  window.cookieTrackingScriptInserted = true
}

export const serializeTrackingCountryName = (countryName: string) => {
  if (countryName === 'The Netherlands') {
    return 'Netherlands'
  }

  return countryName
}

const promise = parallel([
  () => customerPromise,
  () => inspectorPromise,
  () => gaPromise,
])

// Event tracking
export const trackEvent = async ({ ga, segment }: TrackEventProps) => {
  try {
    log.debug({
      method: 'trackEvent',
      ga,
      segment,
      isServer: isServer(),
    })

    if (isServer()) {
      return
    }

    await customerPromise

    await parallel([
      async () => {
        if (isUndefined(ga)) {
          return
        }

        if (isUndefined(gaLoaded.value) || gaLoaded.value === false) {
          throw new Error('trackEvent: Google Analytics not loaded')
        }

        log.debug({
          method: 'trackEvent:ga',
          message: 'loaded',
        })

        pushGA({
          event: ga.event ?? GA_DEFAULT_EVENT,
          eventCategory: ga.eventCategory,
          eventAction: ga.eventAction,
          eventLabel: ga.eventLabel,
        })
      },

      async () => {
        if (isUndefined(segment)) {
          return
        }

        if (isUndefined(loaded.value) || loaded.value === false) {
          throw new Error('trackEvent: Avo not loaded')
        }

        log.debug({
          method: 'trackEvent:avo',
          message: 'loaded',
        })

        const avoModule = await import('@susu/headless-commerce/libs/avo/avo')

        if (!avoModule) {
          return
        }

        const { enrichProps, properties } = segment
        const trackMethod = avoModule[
          segment.event as keyof typeof avoModule
        ] as (event: unknown) => void

        if (isUndefined(trackMethod)) {
          throw new Error(`No tracking method for event: ${segment.event}`)
        }

        trackMethod(enrichEvent(enrichProps, properties))
      },
    ])
  } catch (error: unknown) {
    log.error(error as Error)
  }
}

export const pageLoaded = async ({
  enrichProps,
  properties,
}: {
  enrichProps: EnrichEventsProps
  properties: Omit<PageLoadedProperties, keyof CommonEventProperties>
}) => {
  queueTrackingEvent('PageLoadedProperties', { enrichProps, properties })
}

// Product tracking
export const trackClickProduct = async (props: SegmentProductProps) => {
  await promise

  const { enrichProps, product, tracking } = props

  GATrackingProductClick({ enrichProps, product, tracking })
  segmentTrackingProductClick(props)
}

export const trackImpressionProduct = ({
  elementRef,
  product,
  currencyCode,
  tracking,
  siteId,
}: {
  elementRef: RefObject<Element>
  product: Partial<Product>
  currencyCode: string
  tracking: ProductTrackingData
  siteId: string
}) => {
  if (elementRef.current) {
    observeOnce(elementRef, async () => {
      await promise
      const { list, listId, position } = tracking

      pushGA({
        event: 'loadedImpressions',
        ecommerce: {
          currencyCode,
          impressions: productImpressions({
            product,
            list,
            listId,
            siteId,
            position,
          }),
        },
      })
    })
  }
}

export const trackViewProductsList = async <T extends Element>({
  elementRef,
  enrichProps,
  pageType,
  products,
  tracking,
}: {
  elementRef: RefObject<T>
  enrichProps: EnrichEventsProps
  pageType: PageType
  products: Array<Product>
  tracking: Omit<ProductTrackingData, 'position'>
}) => {
  if (!observedList.get(elementRef) && elementRef.current) {
    observedList.set(
      elementRef,
      observeOnce(elementRef, async () => {
        let mainIndex = 0
        const trackingProducts: Products[] = []
        // price * quantity
        let value = 0
        // // biome-ignore lint/complexity/noForEach: <explanation>
        products.forEach((prd, index) => {
          if (
            prd.type === 'set' &&
            isNotUndefined(prd.setProducts) &&
            isNotNull(prd.setProducts)
          ) {
            for (const setProduct of prd.setProducts) {
              value += setProduct.price
              trackingProducts.push(
                segmentProduct({
                  enrichProps,
                  product: setProduct,
                  tracking: {
                    ...tracking,
                    position: index === 0 ? ++mainIndex : mainIndex,
                  },
                  pageType,
                  quantity: 1,
                  variant: '',
                  productSetId: prd.id,
                }) as unknown as Products,
              )
            }
          }

          if (prd.type === 'master' || prd.type === 'item') {
            value += prd.price
            trackingProducts.push(
              segmentProduct({
                enrichProps,
                product: prd,
                tracking: {
                  ...tracking,
                  position: ++mainIndex,
                },
                pageType,
                quantity: 1,
                variant: '',
              }) as unknown as Products,
            )
          }
        })

        const { listId, list } = tracking
        const { url, locationId } = URLData()
        const baseProps: Omit<
          ProductListViewedProperties,
          keyof CommonEventProperties
        > = {
          listId: listId,
          itemListName: list,
          pageType,
          products: trackingProducts,
          url,
          locationId,
        }

        queueTrackingEvent('ProductListViewedProperties', {
          enrichProps,
          properties: baseProps,
        })
      }),
    )
  }
}

/**
 * Tracks a click on a promotional element and sends the relevant tracking data to Google Analytics
 * and the Segment platform.
 *
 * @param elementRef - A reference to the HTML element that was clicked.
 * @param promotionEvents - The event data related to the promotion.
 * @param pageType - The type of the page where the promotion click occurred.
 * @param enrichProps - Additional properties to enrich the event with.
 */
export const trackClickPromotion = async <T extends Element>(
  elementRef: RefObject<T>,
  promotionEvents: PromotionEvents,
  pageType: string,
  enrichProps: EnrichEventsProps,
) => {
  const promoData = GAPromotionData(promotionEvents, pageType)
  const { url, locationId } = URLData()
  promoData.position = getElementRefPosition(elementRef)

  pushGA({
    event: 'promotionClick',
    ecommerce: {
      promoClick: {
        promotions: [promoData],
      },
    },
  })

  queueTrackingEvent('PromotionClickedProperties', {
    enrichProps,
    properties: {
      pageType: pageType as PageTypeValueType,
      url,
      locationId,
      promotionId: promoData.id,
      name: promoData.name,
      creative: promoData.creative as CreativeValueType,
      position: promoData.position,
      ssMedium: null,
      ssSource: '',

      // TODO: Values for these
      eventCategory: 'configurator_interactions',
      eventLabel: '',
      eventLocation: '',
    },
  })
}

/**
 * Tracks an impression of a promotional element and sends the relevant tracking data to
 * Google Analytics and the Segment platform.
 *
 * This function ensures that the promotional element is tracked only once by using an observed list.
 *
 * @param elementRef - A reference to the HTML element that is being observed.
 * @param promotionEventsData - The event data related to the promotion.
 * @param pageType - The type of the page where the promotion impression occurred.
 * @param enrichProps - Additional properties to enrich the event with.
 */
export const trackImpressionPromotion = async <T extends Element>(
  elementRef: RefObject<T>,
  promotionEvents: PromotionEvents,
  pageType: PageType,
  enrichProps: Omit<EnrichEventsProps, 'customer'>,
) => {
  const promotionEventsData = promotionEvents || {}

  if (!observedList.get(elementRef) && elementRef.current) {
    observedList.set(
      elementRef,
      observeOnce(elementRef, async () => {
        promotionEventsData.promotionPosition =
          getElementRefPosition(elementRef)

        pushGA({
          event: 'promotionImpression',
          ecommerce: {
            promoView: {
              promotions: [GAPromotionData(promotionEventsData, pageType)],
            },
          },
        })

        queueTrackingEvent('PromotionViewedProperties', {
          enrichProps,
          properties: segmentPromotionData(promotionEventsData, pageType),
        })
      }),
    )
  }
}
