import type { ForwardedRef, RefObject } from 'react'

import { popVersionParam } from './utils'

export type ResponsiveImageV2Props = {
  alt: string
  className?: string
  desktopSizes: string
  desktopSrcSet: string
  imgRef?: ForwardedRef<HTMLImageElement>
  imgStyle?: React.CSSProperties
  lazyloading?: boolean
  mobileSizes: string
  mobileSrcSet: string
  pictureStyle?: React.CSSProperties
  src: string
  tabletSizes?: string
  tabletSrcSet?: string
  pictureRef?: RefObject<HTMLPictureElement>
}

/**
 * Renders a responsive image with different sources based on the device's screen size.
 *
 * @param alt - The alternative text for the image.
 * @param desktopSizes - The sizes attribute for the desktop source.
 * @param desktopSrcSet - The srcset attribute for the desktop source.
 * @param imgRef - The ref for the image element.
 * @param imgStyle - The style for the image element.
 * @param lazyloading - Determines if lazy loading is enabled for the image.
 * @param mobileSizes - The sizes attribute for the mobile source.
 * @param mobileSrcSet - The srcset attribute for the mobile source.
 * @param pictureStyle - The style for the picture element.
 * @param src - The source URL for the image.
 * @param tabletSizes - The sizes attribute for the tablet source.
 * @param tabletSrcSet - The srcset attribute for the tablet source.
 * @returns The JSX element representing the responsive image.
 */
export const ResponsiveImage = ({
  alt,
  className,
  desktopSizes,
  desktopSrcSet,
  imgRef,
  imgStyle,
  lazyloading,
  mobileSizes,
  mobileSrcSet,
  pictureStyle,
  src,
  tabletSizes,
  tabletSrcSet,
}: ResponsiveImageV2Props) => {
  return (
    <picture className={className} style={pictureStyle}>
      {/* Desktop, Tablet and Mobile  */}
      {tabletSrcSet && tabletSizes && (
        <>
          <source
            media="(min-width: 992px)"
            sizes={desktopSizes}
            srcSet={desktopSrcSet}
          />
          <source
            media="(min-width: 768px) and (max-width: 991px)"
            sizes={tabletSizes}
            srcSet={tabletSrcSet}
          />
          <source
            media="(max-width: 767px)"
            sizes={mobileSizes}
            srcSet={mobileSrcSet}
          />
        </>
      )}
      {/* Desktop and Mobile */}
      {!tabletSrcSet && !tabletSizes && (
        <>
          <source
            media="(min-width: 992px)"
            sizes={desktopSizes}
            srcSet={desktopSrcSet}
          />
          <source
            media="(max-width: 991px)"
            sizes={mobileSizes}
            srcSet={mobileSrcSet}
          />
        </>
      )}
      <img
        src={popVersionParam(src)}
        alt={alt}
        fetchPriority={lazyloading ? 'low' : 'high'}
        loading={lazyloading ? 'lazy' : 'eager'}
        style={imgStyle}
        ref={imgRef}
      />
    </picture>
  )
}
