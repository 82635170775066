// Generated by Avo VERSION 164.7.0. You should never have to make changes to this file.
// If you find yourself in the situation where you have to edit the file please contact us at hi@avo.app.
// If you encounter a git conflict in this file run `avo pull` and it will be resolved automatically.
/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// fetch() polyfill
(function () {
  if (typeof window === 'undefined') {
    return;
  }
  var support = {
    searchParams: 'URLSearchParams' in self,
    iterable: 'Symbol' in self && 'iterator' in Symbol,
    blob:
      'FileReader' in self &&
      'Blob' in self &&
      (function () {
        try {
          new Blob();
          return true
        } catch (e) {
          return false
        }
      })(),
    formData: 'FormData' in self,
    arrayBuffer: 'ArrayBuffer' in self
  };

  function isDataView(obj: any) {
    return obj && DataView.prototype.isPrototypeOf(obj)
  }

  if (support.arrayBuffer) {
    var viewClasses = [
      '[object Int8Array]',
      '[object Uint8Array]',
      '[object Uint8ClampedArray]',
      '[object Int16Array]',
      '[object Uint16Array]',
      '[object Int32Array]',
      '[object Uint32Array]',
      '[object Float32Array]',
      '[object Float64Array]'
    ];

    var isArrayBufferView =
      ArrayBuffer.isView ||
      function (obj) {
        return obj && viewClasses.indexOf(Object.prototype.toString.call(obj)) > -1
      };
  }

  function normalizeName(name: any) {
    if (typeof name !== 'string') {
      name = String(name);
    }
    if (/[^a-z0-9\-#$%&'*+.^_`|~]/i.test(name)) {
      throw new TypeError('Invalid character in header field name')
    }
    return name.toLowerCase()
  }

  function normalizeValue(value: any) {
    if (typeof value !== 'string') {
      value = String(value);
    }
    return value
  }

  // Build a destructive iterator for the value list
  function iteratorFor(items: any) {
    var iterator: any = {
      next: function () {
        var value = items.shift();
        return { done: value === undefined, value: value }
      }
    };

    if (support.iterable) {
      iterator[Symbol.iterator] = function () {
        return iterator
      };
    }

    return iterator
  }

  function Headers(headers: any) {
    // @ts-ignore
    (this as any).map = {};

    if (headers instanceof Headers) {
      (headers as any).forEach(function (value: any, name: any) {
        // @ts-ignore
        this.append(name, value);
        // @ts-ignore
      }, this);
    } else if (Array.isArray(headers)) {
      headers.forEach(function (header: any) {
        // @ts-ignore
        this.append(header[0], header[1]);
        // @ts-ignore
      }, this);
    } else if (headers) {
      Object.getOwnPropertyNames(headers).forEach(function (name: any) {
        // @ts-ignore
        this.append(name, headers[name]);
        // @ts-ignore
      }, this);
    }
  }

  Headers.prototype.append = function (name: any, value: any) {
    name = normalizeName(name);
    value = normalizeValue(value);
    var oldValue = this.map[name];
    this.map[name] = oldValue ? oldValue + ', ' + value : value;
  };

  Headers.prototype['delete'] = function (name: any) {
    delete this.map[normalizeName(name)];
  };

  Headers.prototype.get = function (name: any) {
    name = normalizeName(name);
    return this.has(name) ? this.map[name] : null
  };

  Headers.prototype.has = function (name: any) {
    return this.map.hasOwnProperty(normalizeName(name))
  };

  Headers.prototype.set = function (name: any, value: any) {
    this.map[normalizeName(name)] = normalizeValue(value);
  };

  Headers.prototype.forEach = function (callback: any, thisArg: any) {
    for (var name in this.map) {
      if (this.map.hasOwnProperty(name)) {
        callback.call(thisArg, this.map[name], name, this);
      }
    }
  };

  Headers.prototype.keys = function () {
    var items: any = [];
    this.forEach(function (_value: any, name: any) {
      items.push(name);
    });
    return iteratorFor(items)
  };

  Headers.prototype.values = function () {
    var items: any = [];
    this.forEach(function (value: any) {
      items.push(value);
    });
    return iteratorFor(items)
  };

  Headers.prototype.entries = function () {
    var items: any = [];
    this.forEach(function (value: any, name: any) {
      items.push([name, value]);
    });
    return iteratorFor(items)
  };

  if (support.iterable) {
    Headers.prototype[Symbol.iterator] = Headers.prototype.entries;
  }

  function consumed(body: any) {
    if (body.bodyUsed) {
      return true;
    }
    body.bodyUsed = true;
    return false;
  }

  function fileReaderReady(reader: any) {
    return new Promise(function (resolve: any, reject: any) {
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function () {
        reject(reader.error);
      };
    })
  }

  function readBlobAsArrayBuffer(blob: any) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsArrayBuffer(blob);
    return promise
  }

  function readBlobAsText(blob: any) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsText(blob);
    return promise
  }

  function readArrayBufferAsText(buf: any) {
    var view = new Uint8Array(buf);
    var chars = new Array(view.length);

    for (var i = 0; i < view.length; i++) {
      chars[i] = String.fromCharCode(view[i]!);
    }
    return chars.join('')
  }

  function bufferClone(buf: any) {
    if (buf.slice) {
      return buf.slice(0)
    } else {
      var view = new Uint8Array(buf.byteLength);
      view.set(new Uint8Array(buf));
      return view.buffer
    }
  }

  function Body() {
    // @ts-ignore
    (this as any).bodyUsed = false;

    // @ts-ignore
    (this as any)._initBody = function (body: any) {
      this._bodyInit = body;
      if (!body) {
        this._bodyText = '';
      } else if (typeof body === 'string') {
        this._bodyText = body;
      } else if (support.blob && Blob.prototype.isPrototypeOf(body)) {
        this._bodyBlob = body;
      } else if (support.formData && FormData.prototype.isPrototypeOf(body)) {
        this._bodyFormData = body;
      } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
        this._bodyText = body.toString();
      } else if (support.arrayBuffer && support.blob && isDataView(body)) {
        this._bodyArrayBuffer = bufferClone(body.buffer);
        // IE 10-11 can't handle a DataView body.
        this._bodyInit = new Blob([this._bodyArrayBuffer]);
      } else if (support.arrayBuffer && (ArrayBuffer.prototype.isPrototypeOf(body) || isArrayBufferView(body))) {
        this._bodyArrayBuffer = bufferClone(body);
      } else {
        this._bodyText = body = Object.prototype.toString.call(body);
      }

      if (!this.headers.get('content-type')) {
        if (typeof body === 'string') {
          this.headers.set('content-type', 'text/plain;charset=UTF-8');
        } else if (this._bodyBlob && this._bodyBlob.type) {
          this.headers.set('content-type', this._bodyBlob.type);
        } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
          this.headers.set('content-type', 'application/x-www-form-urlencoded;charset=UTF-8');
        }
      }
    };

    if (support.blob) {
      // @ts-ignore
      (this as any).blob = function () {
        var rejected = consumed(this);
        if (rejected) {
          return Promise.reject(new TypeError('Already read'));
        }

        if (this._bodyBlob) {
          return Promise.resolve(this._bodyBlob);
        } else if (this._bodyArrayBuffer) {
          return Promise.resolve(new Blob([this._bodyArrayBuffer]));
        } else if (this._bodyFormData) {
          throw new Error('could not read FormData body as blob');
        } else {
          return Promise.resolve(new Blob([this._bodyText]));
        }
      };

      // @ts-ignore
      (this as any).arrayBuffer = function () {
        if (this._bodyArrayBuffer) {
          if (consumed(this)) {
            return Promise.reject(new TypeError('Already read'));
          } else {
            return Promise.resolve(this._bodyArrayBuffer);
          }
        } else {
          return this.blob().then(readBlobAsArrayBuffer);
        }
      };
    }

    // @ts-ignore
    (this as any).text = function () {
      var rejected = consumed(this);
      if (rejected) {
        return Promise.reject(new TypeError('Already read'));
      }

      if (this._bodyBlob) {
        return readBlobAsText(this._bodyBlob);
      } else if (this._bodyArrayBuffer) {
        return Promise.resolve(readArrayBufferAsText(this._bodyArrayBuffer));
      } else if (this._bodyFormData) {
        throw new Error('could not read FormData body as text');
      } else {
        return Promise.resolve(this._bodyText);
      }
    };

    if (support.formData) {
      // @ts-ignore
      (this as any).formData = function () {
        return this.text().then(decode)
      };
    }

    // @ts-ignore
    (this as any).json = function () {
      return this.text().then(JSON.parse)
    };

    // @ts-ignore
    return this
  }

  // HTTP methods whose capitalization should be normalized
  var methods = ['DELETE', 'GET', 'HEAD', 'OPTIONS', 'POST', 'PUT'];

  function normalizeMethod(method: any) {
    var upcased = method.toUpperCase();
    return methods.indexOf(upcased) > -1 ? upcased : method
  }

  function Request(input: any, options: any) {
    options = options || {};
    var body = options.body;

    if (input instanceof Request) {
      if ((input as any).bodyUsed) {
        throw new TypeError('Already read')
      }
      // @ts-ignore
      (this as any).url = (input as any).url;
      // @ts-ignore
      this.credentials = (input as any).credentials;
      if (!options.headers) {
        // @ts-ignore
        this.headers = new Headers((input as any).headers);
      }
      // @ts-ignore
      this.method = (input as any).method;
      // @ts-ignore
      this.mode = (input as any).mode;
      // @ts-ignore
      this.signal = (input as any).signal;
      if (!body && (input as any)._bodyInit != null) {
        body = (input as any)._bodyInit;
        (input as any).bodyUsed = true;
      }
    } else {
      // @ts-ignore
      this.url = String(input);
    }

    // @ts-ignore
    this.credentials = options.credentials || this.credentials || 'same-origin';
    // @ts-ignore
    if (options.headers || !this.headers) {
      // @ts-ignore
      this.headers = new Headers(options.headers);
    }
    // @ts-ignore
    this.method = normalizeMethod(options.method || this.method || 'GET');
    // @ts-ignore
    this.mode = options.mode || this.mode || null;
    // @ts-ignore
    this.signal = options.signal || this.signal;
    // @ts-ignore
    this.referrer = null;

    // @ts-ignore
    if ((this.method === 'GET' || this.method === 'HEAD') && body) {
      throw new TypeError('Body not allowed for GET or HEAD requests')
    }
    // @ts-ignore
    this._initBody(body);
  }

  Request.prototype.clone = function () {
    // @ts-ignore
    return new Request(this, { body: this._bodyInit })
  };

  function decode(body: any) {
    var form = new FormData();
    body
      .trim()
      .split('&')
      .forEach(function (bytes: any) {
        if (bytes) {
          var split = bytes.split('=');
          var name = split.shift().replace(/\+/g, ' ');
          var value = split.join('=').replace(/\+/g, ' ');
          form.append(decodeURIComponent(name), decodeURIComponent(value));
        }
      });
    return form
  }

  function parseHeaders(rawHeaders: any) {
    // @ts-ignore
    var headers = new Headers();
    // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
    // https://tools.ietf.org/html/rfc7230#section-3.2
    var preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
    preProcessedHeaders.split(/\r?\n/).forEach(function (line: any) {
      var parts = line.split(':');
      var key = parts.shift().trim();
      if (key) {
        var value = parts.join(':').trim();
        headers.append(key, value);
      }
    });
    return headers
  }

  Body.call(Request.prototype);

  function Response(bodyInit: any, options: any) {
    if (!options) {
      options = {};
    }

    // @ts-ignore
    this.type = 'default';
    // @ts-ignore
    this.status = options.status === undefined ? 200 : options.status;
    // @ts-ignore
    this.ok = this.status >= 200 && this.status < 300;
    // @ts-ignore
    this.statusText = 'statusText' in options ? options.statusText : 'OK';
    // @ts-ignore
    this.headers = new Headers(options.headers);
    // @ts-ignore
    this.url = options.url || '';
    // @ts-ignore
    this._initBody(bodyInit);
  }

  Body.call(Response.prototype);

  Response.prototype.clone = function () {
    // @ts-ignore
    return new Response(this._bodyInit, {
      status: this.status,
      statusText: this.statusText,
      // @ts-ignore
      headers: new Headers(this.headers),
      url: this.url
    })
  };

  Response.error = function () {
    // @ts-ignore
    var response = new Response(null, { status: 0, statusText: '' });
    response.type = 'error';
    return response
  };

  var redirectStatuses = [301, 302, 303, 307, 308];

  Response.redirect = function (url: any, status: any) {
    if (redirectStatuses.indexOf(status) === -1) {
      throw new RangeError('Invalid status code')
    }

    // @ts-ignore
    return new Response(null, { status: status, headers: { location: url } })
  };

  (self as any).DOMException = (self as any).DOMException;
  try {
    new (self as any).DOMException();
  } catch (err) {
    (self as any).DOMException = function (message: any, name: any) {
      this.message = message;
      this.name = name;
      var error = Error(message);
      this.stack = error.stack;
    };
    (self as any).DOMException.prototype = Object.create(Error.prototype);
    (self as any).DOMException.prototype.constructor = (self as any).DOMException;
  }

  function fetch(input: any, init: any) {
    return new Promise(function (resolve, reject) {
      // @ts-ignore
      var request = new Request(input, init);

      if (request.signal && request.signal.aborted) {
        return reject(new (self as any).DOMException('Aborted', 'AbortError'))
      }

      var xhr = new XMLHttpRequest();

      function abortXhr() {
        xhr.abort();
      }

      xhr.onload = function () {
        var options = {
          status: xhr.status,
          statusText: xhr.statusText,
          headers: parseHeaders(xhr.getAllResponseHeaders() || '')
        };
        (options as any).url = 'responseURL' in xhr ? xhr.responseURL : options.headers.get('X-Request-URL');
        var body = 'response' in xhr ? xhr.response : (xhr as any).responseText;
        // @ts-ignore
        resolve(new Response(body, options));
      };

      xhr.onerror = function () {
        reject(new TypeError('Network request failed'));
      };

      xhr.ontimeout = function () {
        reject(new TypeError('Network request failed'));
      };

      xhr.onabort = function () {
        reject(new (self as any).DOMException('Aborted', 'AbortError'));
      };

      xhr.open(request.method, request.url, true);

      if (request.credentials === 'include') {
        xhr.withCredentials = true;
      } else if (request.credentials === 'omit') {
        xhr.withCredentials = false;
      }

      if ('responseType' in xhr && support.blob) {
        xhr.responseType = 'blob';
      }

      request.headers.forEach(function (value: any, name: any) {
        xhr.setRequestHeader(name, value);
      });

      if (request.signal) {
        request.signal.addEventListener('abort', abortXhr);

        xhr.onreadystatechange = function () {
          // DONE (success or failure)
          if (xhr.readyState === 4) {
            request.signal.removeEventListener('abort', abortXhr);
          }
        };
      }

      xhr.send(typeof request._bodyInit === 'undefined' ? null : request._bodyInit);
    })
  }

  fetch.polyfill = true;

  if (!self.fetch) {
    (self as any).fetch = fetch;
    (self as any).Headers = Headers;
    (self as any).Request = Request;
    (self as any).Response = Response;
  }
})();

export enum AvoEnv {
  Prod = "prod",
  Staging = "staging",
  Dev = "dev",
}

export interface CustomDestination {
  make?(env: AvoEnv, apiKey: string): void;
  logEvent?: (eventName: string, eventProperties: Record<string, any>) => void;
  setUserProperties?: (userId: string, userProperties: Record<string, any>) => void;
  identify?: (userId: string) => void;
  unidentify?: () => void;
  logPage?: (pageName: string, eventProperties: Record<string, any>) => void;
  revenue?: (amount: number, eventProperties: Record<string, any>) => void;
  setGroupProperties?: (
    groupType: string,
    groupId: string,
    groupProperties: Record<string, any>,
  ) => void;
  addCurrentUserToGroup?: (
    groupType: string,
    groupId: string,
    groupProperties: Record<string, any>,
  ) => void;
  logEventWithGroups?: (
    eventName: string,
    eventProperties: any,
    groupTypesToGroupIds: Record<string, string>,
  ) => void;
}

// @ts-ignore
interface AvoAssertMessage {
  eventName?: string;
  tag?: string;
  propertyId?: string;
  message?: string;
  additionalProperties?: string[],
  shape?: any,
  shapeUserProps?: any,
  actualType?: string
}

let __AVO_ENV__: AvoEnv | null = null;
// @ts-ignore
let __AVO_NOOP__: boolean = false;
// @ts-ignore
let __AVO_LOGGER__: AvoLogger | null = null;
// @ts-ignore
let __STRICT__: boolean | null = null;
// @ts-ignore
let __REPORT_FAILURE_AS__: 'error' | 'warn' | 'log' | null = null;

// @ts-ignore
let __WEB_DEBUGGER__: boolean = true;
export const avoInspectorApiKey = "08x2H3eWZd8gAG8XGLnE";
// @ts-ignore
interface AvoInspector {}
let __INSPECTOR__: AvoInspector | null = null;


// polyfill Object.assign
// @ts-ignore
declare interface ObjectConstructor {
  assign: any;
}
// @ts-ignore
if (typeof Object.assign !== 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, "assign", {
    value: function assign(target: any, _varArgs: any) { // .length of function is 2
      if (target == null) { // TypeError if undefined or null
        throw new TypeError('Cannot convert undefined or null to object');
      }

      let to = Object(target);

      for (let index = 1; index < arguments.length; index++) {
        let nextSource = arguments[index];

        if (nextSource != null) { // Skip over if undefined or null
          for (let nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true
  });
}

interface AvoLogger {
  logDebug(env: AvoEnv | null, message: string): boolean;
  logWarn(env: AvoEnv | null, message: string): boolean;
  logError(env: AvoEnv | null, error: string): boolean;
}

enum webDebuggerArea {
  BottomRight = "BottomRight",
  BottomLeft = "BottomLeft",
  TopRight = "TopRight",
  TopLeft = "TopLeft"
}

interface bottomRightParameters {
  bottom: number;
  right: number;
}

interface bottomLeftParameters {
  bottom: number;
  left: number;
}

interface topRightParameters {
  top: number;
  right: number;
}

interface topLeftParameters {
  top: number;
  left: number;
}

interface webDebuggerPosition {
  position: webDebuggerArea;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

interface webDebuggerOptions {
  position?: webDebuggerPosition;
}

interface WebDebuggerPositionSetter {
  BottomRight(p: bottomRightParameters): webDebuggerPosition;
  BottomLeft(p: bottomLeftParameters): webDebuggerPosition;
  TopRight(p: topRightParameters): webDebuggerPosition;
  TopLeft(p: topLeftParameters): webDebuggerPosition;
}

export const WebDebuggerPosition: WebDebuggerPositionSetter = {
  BottomRight: ({ bottom, right }) => ({
    position: webDebuggerArea.BottomRight,
    bottom,
    right,
  }),
  BottomLeft: ({ bottom, left }) => ({
    position: webDebuggerArea.BottomLeft,
    bottom,
    left,
  }),
  TopRight: ({ top, right }) => ({
    position: webDebuggerArea.TopRight,
    top,
    right,
  }),
  TopLeft: ({ top, left }) => ({
    position: webDebuggerArea.TopLeft,
    top,
    left,
  }),
}


let InternalAvoLogger: any = {
  logEventSent: function logEventSent(eventName: string, eventProperties: any, userProperties: any) {
    const message = "Event Sent:" + eventName + "Event Props:" + JSON.stringify(eventProperties) + "User Props:" + JSON.stringify(userProperties);

    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.log("[avo] Event Sent:", eventName, "Event Props:", eventProperties, "User Props:", userProperties);
  },

  log: function log(message: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.log("[avo] " + message);
  },

  warn: function warn(message: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logWarn && __AVO_LOGGER__.logWarn(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.warn("[avo] " + message);
  },

  error: function error(message: string, error: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logError && __AVO_LOGGER__.logError(__AVO_ENV__, message + error)) {
      return
    }
    typeof console !== 'undefined' && console.error("[avo] " + message, error);
  }
};

function convertPropertiesArrayToMap(propertiesArray: [{id: string, name: string, value: string}]): {string: string | null | undefined} {
    let result: {string: string} = {}

    propertiesArray.forEach(value => {
      result[value.name] = value.value
    })

    return result
}

// @ts-ignore
let array_difference: any;
// @ts-ignore
let AvoAssert: any;
array_difference = function array_difference(a1: any[], a2: any[]) {
  let result: any[] = [];
  for (let i = 0; i < a1.length; i++) {
    if (a2.indexOf(a1[i]) === -1) {
      result.push(a1[i]);
    }
  }
  return result;
}

AvoAssert = {
  assertObject: function assertObject(propertyId: string, propName: string, obj: any) {
    if (typeof obj !== 'object') {
      let message = propName +
          ' should be of type object but you provided type ' +
          typeof obj +
          ' with value ' +
          JSON.stringify(obj);
      return [{tag: 'expectedObjectType', propertyId, message, actualType: typeof obj}];
    } else {
      return [];
    }
  },

  assertString: function assertString(propertyId: string, propName: string, str: string) {
    if (typeof str !== 'string') {
      let message = propName +
          ' should be of type string but you provided type ' +
          typeof str +
          ' with value ' +
          JSON.stringify(str);
      return [{tag: 'expectedStringType', propertyId, message, actualType: typeof str}];
    } else {
      return [];
    }
  },

  assertInt: function assertInt(propertyId: string, propName: string, int: number) {
    if (typeof int === 'number' && int !== Math.round(int)) {
      let message = propName +
          ' should be of type int but you provided type float with value ' +
          JSON.stringify(int);
      return [{tag: 'expectedIntType', propertyId, message, actualType: 'float'}];
    } else if (typeof int !== 'number') {
      let message = propName +
          ' should be of type int but you provided type ' +
          typeof int +
          ' with value ' +
          JSON.stringify(int);
      return [{tag: 'expectedIntType', propertyId, message, actualType: typeof int}];
    } else {
      return [];
    }
  },

  assertLong: function assertLong(propertyId: string, propName: string, long: number) {
    if (typeof long === 'number' && long !== Math.round(long)) {
      let message = propName +
          ' should be of type long but you provided type float with value ' +
          JSON.stringify(long);
      return [{tag: 'expectedLongType', propertyId, message, actualType: 'float'}];
    } else if (typeof long !== 'number') {
      let message = propName +
          ' should be of type long but you provided type ' +
          typeof long +
          ' with value ' +
          JSON.stringify(long);
      return [{tag: 'expectedLongType', propertyId, message, actualType: typeof long}];
    } else {
      return [];
    }
  },

  assertFloat: function assertFloat(propertyId: string, propName: string, float: number) {
    if (typeof float !== 'number') {
      let message = propName +
          ' should be of type float but you provided type ' +
          typeof float +
          ' with value ' +
          JSON.stringify(float);
      return [{tag: 'expectedFloatType', propertyId, message, actualType: typeof float}];
    } else {
      return [];
    }
  },

  assertBool: function assertBool(propertyId: string, propName: string, bool: boolean) {
    if (typeof bool !== 'boolean') {
      let message = propName +
          ' should be of type boolean but you provided type ' +
          typeof bool +
          ' with value ' +
          JSON.stringify(bool);
      return [{tag: 'expectedBoolType', propertyId, message, actualType: typeof bool}];
    } else {
      return [];
    }
  },

  assertMax: function assertMax(
    propertyId: string,
    propName: string,
    max: number,
    value: number
  ) {
    if (value > max) {
      let message = propName +
        ' has a maximum value of ' +
        max +
        ' but you provided the value ' +
        JSON.stringify(value);
      return [{tag: 'expectedMax', propertyId, message}];
    } else {
      return [];
    }
  },

  assertMin: function assertMin(
    propertyId: string,
    propName: string,
    min: number,
    value: number
  ) {
    if (value < min) {
      let message = propName +
        ' has a minimum value of ' +
        min +
        ' but you provided the value ' +
        JSON.stringify(value);
      return [{tag: 'expectedMin', propertyId, message}];
    } else {
      return [];
    }
  },

  assertList: function assertList(propertyId: string, propName: string, value: any) {
    if (!Array.isArray(value)) {
      let message = propName + ' should be of type list but you provided type ' + typeof value;
      return [{tag: 'expectedList', propertyId, message}];
    } else {
      return [];
    }
  },

  assertNoAdditionalProperties: function assertNoAdditionalProperties(eventName: string, input: string[], spec: string[]) {
    let additionalKeys = array_difference(input, spec);
    if (additionalKeys.length) {
      let message = "Additional properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
      return [{tag: 'expectedNoAdditionalProperties', additionalProperties: additionalKeys, message: message}];
    } else {
      return [];
    }
  },

  assertNoAdditionalUserProperties: function assertNoAdditionalProperties(eventName: string, input: string[], spec: string[]) {
    let additionalKeys = array_difference(input, spec);
    if (additionalKeys.length) {
      let message = "Additional user properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
      return [{tag: 'expectedNoAdditionalUserProperties', additionalProperties: additionalKeys, message: message}];
    } else {
      return [];
    }
  },
};

let _avo_invoke: any;
let _avo_invoke_meta: any;
let _avo_sampling_rate = 1.0;
_avo_invoke = function _avo_invoke(env: AvoEnv, eventId: string, hash: string, messages: {tag: string, propertyId: string}[], origin: string) {
  // @ts-ignore
  if (typeof (window as any) === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "ac": "IVDQ6gtR0mmoPSXtoRcE",
          "br": "c7EFmUT2v",
          "en": env,
          "ev": eventId,
          "ha": hash,
          "sc": "JmwsgKTQe50MTmUE9FVs",
          "se": (new Date()).toISOString(),
          "so": "YVbONYzfD",
          "va": messages.length === 0,
          "me": messages,
          "or": origin
        })
      }).then(function(res: any) { return res.json(); }).then(function(data: any) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}

_avo_invoke_meta = function _avo_invoke_meta(env: AvoEnv, type: string, messages: {tag: string, propertyId: string}[], origin: string) {
  // @ts-ignore
  if (typeof (window as any) === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "ac": "IVDQ6gtR0mmoPSXtoRcE",
          "br": "c7EFmUT2v",
          "en": env,
          "ty": type,
          "sc": "JmwsgKTQe50MTmUE9FVs",
          "se": (new Date()).toISOString(),
          "so": "YVbONYzfD",
          "va": messages.length === 0,
          "me": messages,
          "or": origin
        })
      }).then(function(res: any) { return res.json(); }).then(function(data: any) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}


let _avo_debugger_log: any;
let _avo_debugger_events_during_boot: any = [];
let _avo_debugger_ready = false;

if (typeof (window as any) !== 'undefined') {
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    let iframe: any = document.getElementById("avo-debugger");
    if (iframe && event && event.data && event.data.type_ === "avo-debugger-update-style") {
      iframe.style = event.data.style;
    } else if (iframe && event && event.data && event.data.type_ === "avo-debugger-ready") {
      let message = {
        type_: "avo-debugger-boot-events",
        schemaId: "JmwsgKTQe50MTmUE9FVs",
        href: window.location.href,
        events: _avo_debugger_events_during_boot
      };
      _avo_debugger_events_during_boot = [];
      _avo_debugger_ready = true;
      iframe.contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  });
}

_avo_debugger_log = function _avo_debugger_log(eventId: string, eventName: string, messages: any[], eventProperties: any[], userProperties: any[], groupProperties: any[]) {
  if (typeof (window as any) === 'undefined') { return; }
  let event = {
    eventId: eventId,
    eventName: eventName,
    messages: messages,
    timestamp: Date.now(),
    eventProperties,
    userProperties,
    groupProperties
  };

  if (_avo_debugger_ready) {
    let message = {type_: "avo-debugger-events", events: [event]};
    (document.getElementById("avo-debugger") as any).contentWindow.postMessage(message, "https://www.avo.app/_debugger")
  } else {
    _avo_debugger_events_during_boot.push(event);
  }
}

function _avo_debugger_send_position(position: webDebuggerPosition) {
  if (typeof window === 'undefined') { return; }
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    if (event.data.type_ === "avo-debugger-ready" && position !== null) {
      var message = {type_: "avo-debugger-position", position: position};
      document.getElementById("avo-debugger").contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  })
}


export interface ProductsCart {
  amount: number
  identifier: string
  variation: string
  currency: CurrencyValueType
  quantity: number
  marketingcloudpid: string
  variantId: string
}

export interface Products {
  productId: string
  category: string
  itemCategory2: string | null | undefined
  itemCategory3: string | null | undefined
  itemCategory4: string | null | undefined
  itemCategory5: string | null | undefined
  affiliation: AffiliationValueType
  itemListName: string
  variant: string | null | undefined
  price: number
  quantity: number
  currency: CurrencyValueType
  position: number
  imageUrl: string
  onlineMaterial: string
  fitId: string | null | undefined
  plpGridImages: string
  shopTheLookId: string | null | undefined
  mixAndMatch: MixAndMatchValueType
  productSetId: string | null | undefined
  name: string
  listId: string
  sku: string
  fullLook: string | null | undefined
  value: number
  locationId: string
  url: string
}

export const MixAndMatch = {
  'TRUE': 'true',
  'FALSE': 'false',
} as const;
export type MixAndMatchType = typeof MixAndMatch;
export type MixAndMatchValueType = MixAndMatchType[keyof MixAndMatchType];

export const Language = {
  'EN': 'en',
  'ES': 'es',
  'FR': 'fr',
  'DE': 'de',
  'IT': 'it',
  'SV': 'sv',
  'PL': 'pl',
  'ZH': 'zh',
  'KO': 'ko',
} as const;
export type LanguageType = typeof Language;
export type LanguageValueType = LanguageType[keyof LanguageType];

export const LoggedIn = {
  'TRUE': 'true',
  'FALSE': 'false',
} as const;
export type LoggedInType = typeof LoggedIn;
export type LoggedInValueType = LoggedInType[keyof LoggedInType];

export const EventCategory = {
  'CHECKOUT_INTERACTIONS': 'checkout_interactions',
  'PLP_INTERACTIONS': 'plp_interactions',
  'PDP_INTERACTIONS': 'pdp_interactions',
  'GLOBAL_INTERACTIONS': 'global_interactions',
  'FOOTER_INTERACTIONS': 'footer_interactions',
  'JOURNAL_INTERACTIONS': 'journal_interactions',
  'CM_INTERACTIONS': 'cm_interactions',
  'LOOKBUILDER_INTERACTIONS': 'lookbuilder_interactions',
  'INSTORE_INTERACTIONS': 'instore_interactions',
  'ECOMMERCE_INTERACTIONS': 'ecommerce_interactions',
  'BTC_INTERACTIONS': 'btc_interactions',
  'BOOKING_INTERACTIONS': 'booking_interactions',
  'LOGIN_INTERACTIONS': 'login_interactions',
  'SP_INTERACTIONS': 'sp_interactions',
  'CONFIGURATOR_INTERACTIONS': 'configurator_interactions',
} as const;
export type EventCategoryType = typeof EventCategory;
export type EventCategoryValueType = EventCategoryType[keyof EventCategoryType];

export const Country = {
  'US': 'us',
  'CA': 'ca',
  'NL': 'nl',
  'GB': 'gb',
  'DE': 'de',
  'FR': 'fr',
  'BE': 'be',
  'IT': 'it',
  'SE': 'se',
  'CH': 'ch',
  'PL': 'pl',
  'ES': 'es',
  'FI': 'fi',
  'DK': 'dk',
  'AT': 'at',
  'NO': 'no',
  'CZ': 'cz',
  'HR': 'hr',
  'HU': 'hu',
  'EE': 'ee',
  'LV': 'lv',
  'LT': 'lt',
  'GR': 'gr',
  'IE': 'ie',
  'SG': 'sg',
  'IN': 'in',
  'AU': 'au',
  'CN': 'cn',
  'BN': 'bn',
  'JP': 'jp',
  'HK': 'hk',
  'MO': 'mo',
  'MV': 'mv',
  'MY': 'my',
  'IR': 'ir',
  'LY': 'ly',
  'PT': 'pt',
  'RO': 'ro',
  'CY': 'cy',
  'SK': 'sk',
  'BG': 'bg',
  'SI': 'si',
  'AE': 'ae',
  'GH': 'gh',
  'GI': 'gi',
  'GW': 'gw',
  'IS': 'is',
  'JE': 'je',
  'LI': 'li',
  'MC': 'mc',
  'MT': 'mt',
  'QA': 'qa',
  'DO': 'do',
  'GP': 'gp',
  'MQ': 'mq',
  'PR': 'pr',
  'TT': 'tt',
  'KR': 'kr',
  'LU': 'lu',
} as const;
export type CountryType = typeof Country;
export type CountryValueType = CountryType[keyof CountryType];

export const Affiliation = {
  'WEBSTORE_EUR': 'webstore-eur',
  'WEBSTORE_USD': 'webstore-usd',
  'WEBSTORE_CAD': 'webstore-cad',
  'WEBSTORE_PLN': 'webstore-pln',
  'WEBSTORE_AUD': 'webstore-aud',
  'WEBSTORE_SEK': 'webstore-sek',
  'WEBSTORE_CHF': 'webstore-chf',
  'WEBSTORE_DKK': 'webstore-dkk',
  'WEBSTORE_AED': 'webstore-aed',
  'WEBSTORE_NOK': 'webstore-nok',
  'WEBSTORE_CNY': 'webstore-cny',
  'WEBSTORE_SGD': 'webstore-sgd',
  'WEBSTORE_HKD': 'webstore-hkd',
  'WEBSTORE_GBP': 'webstore-gbp',
  'WEBSTORE_KRW': 'webstore-krw',
} as const;
export type AffiliationType = typeof Affiliation;
export type AffiliationValueType = AffiliationType[keyof AffiliationType];

export const Brand = {
  'SUITSUPPLY': 'suitsupply',
} as const;
export type BrandType = typeof Brand;
export type BrandValueType = BrandType[keyof BrandType];

export const OutOfStock = {
  'FALSE': 'false',
  'TRUE': 'true',
} as const;
export type OutOfStockType = typeof OutOfStock;
export type OutOfStockValueType = OutOfStockType[keyof OutOfStockType];

export const PageType = {
  'HOME': 'home',
  'PRODUCT': 'product',
  'CATEGORY': 'category',
  'JOURNAL': 'journal',
  'STORE': 'store',
  'LOOKBUILDER': 'lookbuilder',
  'LOGIN': 'login',
  'WISHLIST': 'wishlist',
  'ACCOUNT': 'account',
  'CHECKOUT': 'checkout',
  'CART': 'cart',
  'SUPPORT': 'support',
  'CUSTOMMADE': 'custommade',
  'GIFTCARD': 'giftcard',
  'ERROR': 'error',
  'INSTORE': 'instore',
  'CATEGORY_LANDING': 'category_landing',
  'BLACK_TIE_CONFIGURATOR': 'black-tie-configurator',
  'BLACK_TIE_CONFIGURATOR': 'black_tie_configurator',
  'MINI_CART': 'mini-cart',
} as const;
export type PageTypeType = typeof PageType;
export type PageTypeValueType = PageTypeType[keyof PageTypeType];

export const Currency = {
  'EUR': 'eur',
  'USD': 'usd',
  'CNY': 'cny',
  'AUD': 'aud',
  'BGN': 'bgn',
  'CAD': 'cad',
  'CZK': 'czk',
  'DKK': 'dkk',
  'HKD': 'hkd',
  'HUF': 'huf',
  'ISK': 'isk',
  'INR': 'inr',
  'JPY': 'jpy',
  'CHF': 'chf',
  'MOP': 'mop',
  'MYR': 'myr',
  'NOK': 'nok',
  'QAR': 'qar',
  'RON': 'ron',
  'SGD': 'sgd',
  'SEK': 'sek',
  'AED': 'aed',
  'GBP': 'gbp',
  'PLN': 'pln',
  'KRW': 'krw',
} as const;
export type CurrencyType = typeof Currency;
export type CurrencyValueType = CurrencyType[keyof CurrencyType];

export const Creative = {
  'HOMEPAGE_ADBLOCK': 'homepage_adblock',
  'HOMEPAGE_RELATED_ARTICLE': 'homepage_related-article',
  'HOMEPAGE_USP': 'homepage_usp',
  'HOMEPAGE_TEXTLINK': 'homepage_textlink',
  'HOMEPAGE_SEOBLOCK': 'homepage_seoblock',
  'FOOTER_LINK': 'footer_link',
  'FOOTER_USP': 'footer_usp',
  'PLP_HEADER': 'plp_header',
  'PLP_ADBLOCK': 'plp_adblock',
  'PLP_WIDGET': 'plp_widget',
  'JOURNAL_HUB_RELATED_ARTICLES': 'journal-hub_related-articles',
  'JOURNAL_HUB_ADBLOCK': 'journal-hub_adblock',
  'JOURNAL_HUB_HEADER': 'journal-hub_header',
  'JOURNAL_BUTTONLINK': 'journal_buttonlink',
  'JOURNAL_ADBLOCK': 'journal_adblock',
  'JOURNAL_TEXTLINK': 'journal_textlink',
  'PDP_ACCORDION': 'pdp_accordion',
  'PDP_EMBEDDED_ARTICLE': 'pdp_embedded-article',
  'PDP_USP': 'pdp_usp',
  'PDP_RELATED_ARTICLES': 'pdp_related-articles',
  'PDP_BUTTONLINK': 'pdp_buttonlink',
  'PDP_PANEL': 'pdp_panel',
  'STORE_TEXTLINK': 'store_textlink',
  'STORE_ADBLOCK': 'store_adblock',
  'STORE_FAQLINK': 'store_faqlink',
  'STORE_OVERVIEW_TEXTLINK': 'store-overview_textlink',
  'STORE_SEARCH_TEXTLINK': 'store-search_textlink',
  'STORE_SEARCH_SLIDER_TEXTLINK': 'store-search_slider-textlink',
  'STORE_SEARCH_ADBLOCK': 'store-search_adblock',
  'PDP_PANEL_WISHLIST': 'pdp_panel_wishlist',
  'PLP_PANEL_WISHLIST': 'plp_panel_wishlist',
  'PDP_BUTTONLINK_MWTW': 'pdp_buttonlink_mwtw',
  'PDP_PANEL_MWTW': 'pdp_panel_mwtw',
  'PDP_USP_MWTW': 'pdp_usp_mwtw',
  'PDP_PANEL_SA': 'pdp_panel_sa',
  'FOOTER_PANEL_SA': 'footer_panel_sa',
  'PANEL_MINICART': 'panel_minicart',
  'LOOKBUILDER_SUMMARY_ADBLOCK': 'lookbuilder-summary_adblock',
  'SHOP_THE_LOOK_USP': 'shop-the-look_usp',
  'SHOP_THE_LOOK_PANEL': 'shop-the-look_panel',
  'PDP_ICONLINK_MWTW': 'pdp_iconlink_mwtw',
  'CLP_ADBLOCK': 'clp_adblock',
  'CLP_BUTTONLINK': 'clp_buttonlink',
} as const;
export type CreativeType = typeof Creative;
export type CreativeValueType = CreativeType[keyof CreativeType];

let Segment: any;

export function initAvo(options: {env: AvoEnv;
    webDebugger?: boolean;
    webDebuggerOptions?: webDebuggerOptions;
    strict?: boolean;
    noop?: boolean;
    reportFailureAs?: 'error' | 'warn' | 'log';
    inspector?: AvoInspector;
    avoLogger?: AvoLogger},
    destinationOptions: any,
    SegmentDestination: CustomDestination) {
  if (__AVO_ENV__ !== null) {
    return;
  }
  __AVO_ENV__ = options.env;
  if (options.avoLogger !== undefined) {
    __AVO_LOGGER__ = options.avoLogger;
  }
  if (options.noop === true) {
    __AVO_NOOP__ = true;
  }
  if (__AVO_NOOP__ && __AVO_ENV__ == AvoEnv.Prod) {
    InternalAvoLogger.warn("[avo] ****************************************************");
    InternalAvoLogger.warn("[avo] WARNING Avo cannot be initialized in noop mode in production:");
    InternalAvoLogger.warn("[avo] - Overwriting configuration with noop=false.");
    InternalAvoLogger.warn("[avo] - Please reach out if you want to be able to run Avo in production mode with noop=true");
    InternalAvoLogger.warn("[avo] ****************************************************");
    __AVO_NOOP__ = false;
  }
  if (__AVO_NOOP__) {
    InternalAvoLogger.log("[avo] ****************************************************");
    InternalAvoLogger.log("[avo] Avo is now initialized in noop mode. This means:");
    InternalAvoLogger.log("[avo] - No events will be sent");
    InternalAvoLogger.log("[avo] - No network requests are made");
    InternalAvoLogger.log("[avo] ****************************************************");
  }
  if (options.strict !== undefined) {
    __STRICT__ = options.strict !== false;
  }
  if (options.reportFailureAs !== undefined) {
    __REPORT_FAILURE_AS__ = options.reportFailureAs;
  }
  __WEB_DEBUGGER__ = !__AVO_NOOP__ && ((typeof window !== 'undefined' && (window as any).location.search.indexOf("avo_debug=1") > -1) || (options.webDebugger !== false && __AVO_ENV__ !== AvoEnv.Prod));
  if (!__AVO_NOOP__ && options.inspector !== undefined) {
    __INSPECTOR__ = options.inspector;
  } else if (__AVO_ENV__ !== 'prod') {
    InternalAvoLogger.warn("[avo] Avo Inspector not provided in initAvo() call");
  }
  
  destinationOptions = destinationOptions || {};
  
  if (__WEB_DEBUGGER__ && !__AVO_NOOP__) {
    if (options.webDebuggerOptions?.position) {
      _avo_debugger_send_position(options.webDebuggerOptions.position)
    }

(function() {
  if (typeof (window as any) === 'undefined') { return; }
  let init = function() {
    let iframe: any = document.createElement("iframe");
    document.body.appendChild(iframe);
    iframe.id = "avo-debugger";
    iframe.src = "https://www.avo.app/_debugger";
    iframe.style = "display: none;";
  };

  if (document.body) {
    init();
  } else {
    document.addEventListener('DOMContentLoaded', init);
  }
})();
    
  }
  if (!__AVO_NOOP__) {
    if (__AVO_ENV__ === AvoEnv.Prod) {
    }
    if (__AVO_ENV__ === AvoEnv.Dev) {
    }
    if (__AVO_ENV__ === AvoEnv.Staging) {
    }
    
    Segment = SegmentDestination;
    if (__AVO_ENV__ === 'prod') {
      Segment && Segment.make && Segment.make(__AVO_ENV__, "4OhwIsrqFzQ0aeuYev8muGSWiimGtGIG");
    } else if (__AVO_ENV__ === 'dev') {
      Segment && Segment.make && Segment.make(__AVO_ENV__, null);
    } else {
      Segment && Segment.make && Segment.make(__AVO_ENV__, null);
    }
    if (__AVO_ENV__ === AvoEnv.Dev) {
      // debug console in Avo
      _avo_invoke_meta(__AVO_ENV__, 'init', [], 'init');
    }
  }
}

function assertPrice(price: number,
    _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertFloat("5RMsvPEmT", _label ? "price" + ': ' + _label : "price", price));
  messages = messages.concat(AvoAssert.assertMin("5RMsvPEmT", _label ? "price" + ": " + _label : "price", 0.000000, price));
  return messages;
}

function assertValue(value: number,
    _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertFloat("Kddfsr3Xx", _label ? "value" + ': ' + _label : "value", value));
  messages = messages.concat(AvoAssert.assertMin("Kddfsr3Xx", _label ? "value" + ": " + _label : "value", 0.000000, value));
  return messages;
}

function assertProductsCart(productsCart: ProductsCart[],
    _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertList("NbRKFviNt", "products_cart", productsCart));
  if (productsCart !== null && productsCart !== undefined && productsCart.forEach) {
    productsCart.forEach(function (value, index) {
      messages = messages.concat(AvoAssert.assertObject("NbRKFviNt", "item at index " + index + " in products_cart", value));
      messages = messages.concat(assertAmount(value.amount, "item at index " + index + " in products_cart"));
      messages = messages.concat(assertQuantity(value.quantity, "item at index " + index + " in products_cart"));
    });
  }
  return messages;
}

function assertProductsCartOptional(productsCart: ProductsCart[] | null | undefined,
    _label?: string) {
  let messages: AvoAssertMessage[] = [];
  if (productsCart !== undefined && productsCart !== null) {
    messages = messages.concat(AvoAssert.assertList("NbRKFviNt", "products_cart", productsCart));
    productsCart.forEach(function (value, index) {
      messages = messages.concat(AvoAssert.assertObject("NbRKFviNt", "item at index " + index + " in products_cart", value));
      messages = messages.concat(assertAmount(value.amount, "item at index " + index + " in products_cart"));
      messages = messages.concat(assertQuantity(value.quantity, "item at index " + index + " in products_cart"));
    });
  }
  return messages;
}

function assertAmount(amount: number,
    _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertFloat("Vk6lWRKl5", _label ? "amount" + ': ' + _label : "amount", amount));
  messages = messages.concat(AvoAssert.assertMin("Vk6lWRKl5", _label ? "amount" + ": " + _label : "amount", 0.000000, amount));
  return messages;
}

function assertQuantity(quantity: number,
    _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertInt("fg1kaNXr_", _label ? "quantity" + ': ' + _label : "quantity", quantity));
  messages = messages.concat(AvoAssert.assertMin("fg1kaNXr_", _label ? "quantity" + ": " + _label : "quantity", 1, quantity));
  return messages;
}

function assertPosition(position: number,
    _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertInt("pBPbnpdFj", _label ? "position" + ': ' + _label : "position", position));
  messages = messages.concat(AvoAssert.assertMin("pBPbnpdFj", _label ? "position" + ": " + _label : "position", 1, position));
  return messages;
}

function assertProducts(products: Products[],
    _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertList("rg6ssTw5D", "products", products));
  if (products !== null && products !== undefined && products.forEach) {
    products.forEach(function (value, index) {
      messages = messages.concat(AvoAssert.assertObject("rg6ssTw5D", "item at index " + index + " in products", value));
      messages = messages.concat(assertPrice(value.price, "item at index " + index + " in products"));
      messages = messages.concat(assertQuantity(value.quantity, "item at index " + index + " in products"));
      messages = messages.concat(assertPosition(value.position, "item at index " + index + " in products"));
      messages = messages.concat(assertValue(value.value, "item at index " + index + " in products"));
    });
  }
  return messages;
}

export function setAvoLogger(avoLogger: AvoLogger | null) {
  __AVO_LOGGER__ = avoLogger;
}

export interface ProductListViewedProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  listId: string;
  itemListName: string;
  products: Products[];
}
/**
 * Product List Viewed: What a "Similar Products" Product List is viewed in the viewport
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.listId: This describes the listname + unique location

for custommade it's: custom-made, should match regex: ^(|black-tie-package_(configurator|journal)|category_page|category_page_filtered|category-page-landing_(classic-suits|jackets-tuxedo|perennial-suits|shirts-tuxedo|suits-tuxedo|traveller-suits|tuxedo-suits|wedding-suits)|color-swatch_pdp|colour-swatch_pdp|custom-made|discover-our-collection_journal_(alter-your-fit|custom-made|the-hallmarks-of-a-quality-(shirt|shoe))|discover-the-new-collection_(csr-landing|newsletter-emailpreferences)|e\.thomas-collection_journal_e-thomas-mill|explore-the-collection_journal_(4-ply-traveller-collection|angelico-mill|candiani-mill|delfino-mill|drago-mill|fossati-mill|how-to-take-care-of-your-(knits|suits)|perennial-suits|reda-mill|reggiani-mill|relaxed-trousers-guide|weba-mill)|giftcard_landing|gift-wrapping_(cart|mini-cart)|most-popular_(store-detail|store-locator)|search|shop-(french-linen|indonesian-mother-of-pearl|the-look)_(journal_expedition-jort-the-tailoring-trail|custommade|lookbuilder_summary-screen|minicart|slider(_[1-7])?|wishlist)|view-similar-items_oos|wishlist|you-might-also-like_pdp)$
 * @param properties.itemListName: Name of list where products are shown

For custommade, it's 'custom made', should match regex: ^(|black tie package|category page|category page filtered|category page landing|color swatch|colour swatch|custom made|discover the new collection|explore the collection|gift wrapping|giftcard landing|most popular|search|shop the look|view similar items|wishlist|you might also like)$
 * @param properties.products: An array of products needed for the specific event
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/syjGhZU5cD}
 */
export function productListViewed(properties: ProductListViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "Ygy1AgJzJ", name: "list_id", value: properties.listId});
  eventPropertiesArray.push({id: "6NzVmVGcY", name: "item_list_name", value: properties.itemListName});
  eventPropertiesArray.push({id: "rg6ssTw5D", name: "products", value: properties.products.map(function(value) { return {
      "product_id": value.productId,
      "category": value.category,
      "item_category2": value.itemCategory2,
      "item_category3": value.itemCategory3,
      "item_category4": value.itemCategory4,
      "item_category5": value.itemCategory5,
      "affiliation": value.affiliation,
      "item_list_name": value.itemListName,
      "brand": "suitsupply",
      "variant": value.variant,
      "price": value.price,
      "quantity": value.quantity,
      "currency": value.currency,
      "position": value.position,
      "image_url": value.imageUrl,
      "online_material": value.onlineMaterial,
      "fit_id": value.fitId,
      "plp_grid_images": value.plpGridImages,
      "shop_the_look_id": value.shopTheLookId,
      "mix_and_match": value.mixAndMatch,
      "product_set_id": value.productSetId,
      "name": value.name,
      "list_id": value.listId,
      "sku": value.sku,
      "full_look": value.fullLook,
      "value": value.value,
      "location_id": value.locationId,
      "url": value.url,
    }
  })});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertProducts(properties.products));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "syjGhZU5cD", "9df59a1b1bc00d12fdfedc83a140577b5dab2172d9f4191293e1cd6795edf7e5", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Product List Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("syjGhZU5cD", "Product List Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Product List Viewed': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Product List Viewed", eventProperties, "syjGhZU5cD", "9df59a1b1bc00d12fdfedc83a140577b5dab2172d9f4191293e1cd6795edf7e5");
    }
    // destination Segment
    Segment.logEvent("Product List Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ProductListViewedSimilarProductsProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  products: Products[];
  eventLocation: string;
}
/**
 * Product List Viewed: What a "Similar Products" Product List is viewed in the viewport, event variant
 * 
 * When to trigger this event:
 * 1. When the "Similar products" list is shown
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/syjGhZU5cD.wSIdD0UWx/trigger/uCUQTq1a0b4
 * 2. When the "Similar products" list is shown
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/syjGhZU5cD.wSIdD0UWx/trigger/6WiyLpXsBF7
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.products: An array of products needed for the specific event
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/syjGhZU5cD.wSIdD0UWx}
 */
export function productListViewedSimilarProducts(properties: ProductListViewedSimilarProductsProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "rg6ssTw5D", name: "products", value: properties.products.map(function(value) { return {
      "product_id": value.productId,
      "category": value.category,
      "item_category2": value.itemCategory2,
      "item_category3": value.itemCategory3,
      "item_category4": value.itemCategory4,
      "item_category5": value.itemCategory5,
      "affiliation": value.affiliation,
      "item_list_name": value.itemListName,
      "brand": "suitsupply",
      "variant": value.variant,
      "price": value.price,
      "quantity": value.quantity,
      "currency": value.currency,
      "position": value.position,
      "image_url": value.imageUrl,
      "online_material": value.onlineMaterial,
      "fit_id": value.fitId,
      "plp_grid_images": value.plpGridImages,
      "shop_the_look_id": value.shopTheLookId,
      "mix_and_match": value.mixAndMatch,
      "product_set_id": value.productSetId,
      "name": value.name,
      "list_id": value.listId,
      "sku": value.sku,
      "full_look": value.fullLook,
      "value": value.value,
      "location_id": value.locationId,
      "url": value.url,
    }
  })});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: "ecommerce_interactions"});
  eventPropertiesArray.push({id: "Ygy1AgJzJ", name: "list_id", value: "similar_products"});
  eventPropertiesArray.push({id: "6NzVmVGcY", name: "item_list_name", value: "similar products"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertProducts(properties.products));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "syjGhZU5cD.wSIdD0UWx", "7ba8cda0fa63ae5f94c13d2f31b250c691ba8147c2d926f51f607268bd0e5f67", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Product List Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("syjGhZU5cD.wSIdD0UWx", "Product List Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Product List Viewed': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Product List Viewed", eventProperties, "syjGhZU5cD.wSIdD0UWx", "7ba8cda0fa63ae5f94c13d2f31b250c691ba8147c2d926f51f607268bd0e5f67");
    }
    // destination Segment
    Segment.logEvent("Product List Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ProductClickedProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  listId: string;
  itemListName: string;
  productId: string;
  category: string;
  itemCategory2: string;
  itemCategory3: string;
  itemCategory4: string;
  itemCategory5: string;
  affiliation: AffiliationValueType;
  name: string;
  variant: string;
  price: number;
  quantity: number;
  position: number;
  imageUrl: string;
  onlineMaterial: string | null | undefined;
  fitId: string | null | undefined;
  plpGridImages: string;
  shopTheLookId: string;
  cartId: string;
  mixAndMatch: MixAndMatchValueType;
  productSetId: string;
  sku: string;
  fullLook: string | null | undefined;
  value: number;
}
/**
 * Product Clicked: When clicking on a product to go to PLP, or in a panel when clicking on 'select size' When a product is shown in the viewport

 * 
 * When to trigger this event:
 * 1. When clicking on a product on a PLP
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/kHvR74th3/trigger/hFTLKlhB8
 * 2. When clicking on a product on a PDP in the 'You Might Also Like' carousel. 
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/kHvR74th3/trigger/uc6_I1PJe
 * 3. When clicking on 'Select Size' in the 'Shop the Look' panel
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/kHvR74th3/trigger/2eMjubKrf
 * 4. When clicking on a product on a Journal
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/kHvR74th3/trigger/cWUk2zzqA
 * 5. When clicking on a product on the store locator page
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/kHvR74th3/trigger/Bpf-If4w-
 * 6. When clicking on a product on a store detail page
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/kHvR74th3/trigger/D-ioHoDIM
 * 7. When clicking on 'shop giftcard' button, on the Giftcard landing page
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/kHvR74th3/trigger/ecFszVd66
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.listId: This describes the listname + unique location

for custommade it's: custom-made, should match regex: ^(|black-tie-package_(configurator|journal)|category_page|category_page_filtered|category-page-landing_(classic-suits|jackets-tuxedo|perennial-suits|shirts-tuxedo|suits-tuxedo|traveller-suits|tuxedo-suits|wedding-suits)|color-swatch_pdp|colour-swatch_pdp|custom-made|discover-our-collection_journal_(alter-your-fit|custom-made|the-hallmarks-of-a-quality-(shirt|shoe))|discover-the-new-collection_(csr-landing|newsletter-emailpreferences)|e\.thomas-collection_journal_e-thomas-mill|explore-the-collection_journal_(4-ply-traveller-collection|angelico-mill|candiani-mill|delfino-mill|drago-mill|fossati-mill|how-to-take-care-of-your-(knits|suits)|perennial-suits|reda-mill|reggiani-mill|relaxed-trousers-guide|weba-mill)|giftcard_landing|gift-wrapping_(cart|mini-cart)|most-popular_(store-detail|store-locator)|search|shop-(french-linen|indonesian-mother-of-pearl|the-look)_(journal_expedition-jort-the-tailoring-trail|custommade|lookbuilder_summary-screen|minicart|slider(_[1-7])?|wishlist)|view-similar-items_oos|wishlist|you-might-also-like_pdp)$
 * @param properties.itemListName: Name of list where products are shown

For custommade, it's 'custom made', should match regex: ^(|black tie package|category page|category page filtered|category page landing|color swatch|colour swatch|custom made|discover the new collection|explore the collection|gift wrapping|giftcard landing|most popular|search|shop the look|view similar items|wishlist|you might also like)$
 * @param properties.productId: SKU of product

for custommade:
trousers: dyo-trousers
jacket: dyo-jacket
waistcoat: dyo-waistcoat
coat: dyo-coat
shirt: dyo-shirt
 * @param properties.category: Product Category, should match regex: ^(|belts|coats|cufflinks|gift card|gift-wrapping|gloves|handkerchiefs|hats|jackets|knitwear|scarves|shirts|shoecare|shoes|shorts|socks|suits|suspenders|ties|tiessub|trousers|waistcoats)$
 * @param properties.itemCategory2: no description
 * @param properties.itemCategory3: no description
 * @param properties.itemCategory4: no description
 * @param properties.itemCategory5: no description
 * @param properties.affiliation: This should be webstore-{{currencycode lowercase}}
 * @param properties.name: Name of product as listed in Salesforce, english only, lowercase
Will be gift card for giftcard

for custommade:
trousers: dyo-trousers
jacket: dyo-jacket
waistcoat: dyo-waistcoat
coat: dyo-coat
shirt: dyo-shirt
 * @param properties.variant: Should return size for all products except giftcard. For Giftcard should return 'physical' or 'electronic', should match regex: ^(|5|6|6\.5|7|7\.5|8|8\.5|9|9\.5|10|10\.5|11|12|13|14|14\s1\/2|15|15\s1\/2|15\s3\/4|16|16\s1\/2|17|17\s1\/2|18|18\s1\/2|19|22|23|24|25|26|27|28|29|30|32|34|35|36|37|38|39|40|41|42|43|44|45|46|47|48|50|52|54|56|58|60|62|75|85|90|94|95|98|102|105|106|110|114|118|14\s1\/2s|15\s1\/2l|16l|17\s1\/2l|17\s3\/4l|17l|18\s1\/2s|29\/32|30\/32|30l|30s|31\/32|31\/34|32\/32|32\/34|32l|32s|33\/32|33\/34|34\/32|34\/34|34l|34s|35\/34|35\/36|36\/34|36l|36s|37\/38|37l|37s|38-7|38l|38s|39\/40|39-42|39-7|39l|39l\/40l|40-7|40l|40s|41\/42|41l|41l\/42l|42l|42s|43\/44|43-46|43l|43l\/44l|44l|44s|45\/46|46l|46s|47l|47s|48l|48s|52l|52s|56s|CustomMade|electronic|l|m|one-size|physical|s|xl|xs|xxl)$
 * @param properties.price: Price of single product
 * @param properties.quantity: Amount of products added to cart
 * @param properties.position: Position of product, should always start with 1 
 * @param properties.imageUrl: URL of shown image/hero of product
Can sometimes be different from viewport url
 * @param properties.onlineMaterial: Material of product
 * @param properties.fitId: no description
 * @param properties.plpGridImages: The view of the product on PLP, how many products per row, and how many pictures if 1 item is shown on a row., should match regex: ^(|1_product_default|1_product_with_[1-3]_images_default|2_products_default|2_products_grid|3_products_grid|4_products_grid)$
 * @param properties.shopTheLookId: This describes the shop the look id, if available
 * @param properties.cartId: Cart ID of customer
 * @param properties.mixAndMatch: Should return 'true' or 'false' 
 * @param properties.productSetId: Product Set Id when product is mix and match
 * @param properties.sku: SKU of product, same as product_id
 * @param properties.fullLook: Send an additional variable that returns all the SKU's of the products when a full look is added to Wishlist (lookbuilder).
 * @param properties.value: Price * Quantity
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/kHvR74th3}
 */
export function productClicked(properties: ProductClickedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "Ygy1AgJzJ", name: "list_id", value: properties.listId});
  eventPropertiesArray.push({id: "6NzVmVGcY", name: "item_list_name", value: properties.itemListName});
  eventPropertiesArray.push({id: "RmqMZ4ONr", name: "product_id", value: properties.productId});
  eventPropertiesArray.push({id: "IiKR7dr0F", name: "category", value: properties.category});
  eventPropertiesArray.push({id: "sQMBHWFY_", name: "item_category2", value: properties.itemCategory2});
  eventPropertiesArray.push({id: "JieId3fKm", name: "item_category3", value: properties.itemCategory3});
  eventPropertiesArray.push({id: "5vOpX9hBs", name: "item_category4", value: properties.itemCategory4});
  eventPropertiesArray.push({id: "AtqbQIXpb", name: "item_category5", value: properties.itemCategory5});
  eventPropertiesArray.push({id: "c5sqtMlHz", name: "affiliation", value: properties.affiliation});
  eventPropertiesArray.push({id: "A8Oa9tHEe", name: "name", value: properties.name});
  eventPropertiesArray.push({id: "rNNvglLqV", name: "variant", value: properties.variant});
  eventPropertiesArray.push({id: "5RMsvPEmT", name: "price", value: properties.price});
  eventPropertiesArray.push({id: "fg1kaNXr_", name: "quantity", value: properties.quantity});
  eventPropertiesArray.push({id: "pBPbnpdFj", name: "position", value: properties.position});
  eventPropertiesArray.push({id: "EmHDBRDoN", name: "image_url", value: properties.imageUrl});
  properties.onlineMaterial !== undefined && properties.onlineMaterial !== null ?
    eventPropertiesArray.push({id: "h_-BHBawq", name: "online_material", value: properties.onlineMaterial}) :
    eventPropertiesArray.push({id: "h_-BHBawq", name: "online_material", value: null});
  properties.fitId !== undefined && properties.fitId !== null ?
    eventPropertiesArray.push({id: "SfqMYUwv4", name: "fit_id", value: properties.fitId}) :
    eventPropertiesArray.push({id: "SfqMYUwv4", name: "fit_id", value: null});
  eventPropertiesArray.push({id: "D8L1_0pcs", name: "plp_grid_images", value: properties.plpGridImages});
  eventPropertiesArray.push({id: "vPAMuxU06", name: "shop_the_look_id", value: properties.shopTheLookId});
  eventPropertiesArray.push({id: "14smmnfJh", name: "cart_id", value: properties.cartId});
  eventPropertiesArray.push({id: "3YGdl5qO_", name: "mix_and_match", value: properties.mixAndMatch});
  eventPropertiesArray.push({id: "kjpadIQOI", name: "product_set_id", value: properties.productSetId});
  eventPropertiesArray.push({id: "Yf36kEtqN", name: "sku", value: properties.sku});
  properties.fullLook !== undefined && properties.fullLook !== null ?
    eventPropertiesArray.push({id: "1Ull1Kol-", name: "full_look", value: properties.fullLook}) :
    eventPropertiesArray.push({id: "1Ull1Kol-", name: "full_look", value: null});
  eventPropertiesArray.push({id: "Kddfsr3Xx", name: "value", value: properties.value});
  eventPropertiesArray.push({id: "cAQamFkP_", name: "brand", value: "suitsupply"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertPrice(properties.price));
    messages = messages.concat(assertQuantity(properties.quantity));
    messages = messages.concat(assertPosition(properties.position));
    messages = messages.concat(assertValue(properties.value));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "kHvR74th3", "743f7569ded94756c80eadd450d3c9d4b42134fc730195148a6959543344218a", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Product Clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("kHvR74th3", "Product Clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Product Clicked': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Product Clicked", eventProperties, "kHvR74th3", "743f7569ded94756c80eadd450d3c9d4b42134fc730195148a6959543344218a");
    }
    // destination Segment
    Segment.logEvent("Product Clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ProductClickedSimilarProductsProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  productId: string;
  category: string;
  itemCategory2: string;
  itemCategory3: string;
  itemCategory4: string;
  itemCategory5: string;
  affiliation: AffiliationValueType;
  name: string;
  variant: string;
  price: number;
  quantity: number;
  position: number;
  imageUrl: string;
  onlineMaterial: string | null | undefined;
  fitId: string | null | undefined;
  plpGridImages: string;
  shopTheLookId: string;
  cartId: string;
  mixAndMatch: MixAndMatchValueType;
  productSetId: string;
  sku: string;
  fullLook: string | null | undefined;
  value: number;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * Product Clicked: When clicking on a product to go to PLP, or in a panel when clicking on 'select size' When a product is shown in the viewport, event variant
 * 
 * When to trigger this event:
 * 1. When the user clicks on a product in the "Similar Products" list
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/kHvR74th3.ifkXa9S8M/trigger/RrxM61iG8tm
 * 2. When the user clicks on a product in the "Similar Products" list
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/kHvR74th3.ifkXa9S8M/trigger/eCmOMXGWZye
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.productId: SKU of product

for custommade:
trousers: dyo-trousers
jacket: dyo-jacket
waistcoat: dyo-waistcoat
coat: dyo-coat
shirt: dyo-shirt
 * @param properties.category: Product Category, should match regex: ^(|belts|coats|cufflinks|gift card|gift-wrapping|gloves|handkerchiefs|hats|jackets|knitwear|scarves|shirts|shoecare|shoes|shorts|socks|suits|suspenders|ties|tiessub|trousers|waistcoats)$
 * @param properties.itemCategory2: no description
 * @param properties.itemCategory3: no description
 * @param properties.itemCategory4: no description
 * @param properties.itemCategory5: no description
 * @param properties.affiliation: This should be webstore-{{currencycode lowercase}}
 * @param properties.name: Name of product as listed in Salesforce, english only, lowercase
Will be gift card for giftcard

for custommade:
trousers: dyo-trousers
jacket: dyo-jacket
waistcoat: dyo-waistcoat
coat: dyo-coat
shirt: dyo-shirt
 * @param properties.variant: Should return size for all products except giftcard. For Giftcard should return 'physical' or 'electronic', should match regex: ^(|5|6|6\.5|7|7\.5|8|8\.5|9|9\.5|10|10\.5|11|12|13|14|14\s1\/2|15|15\s1\/2|15\s3\/4|16|16\s1\/2|17|17\s1\/2|18|18\s1\/2|19|22|23|24|25|26|27|28|29|30|32|34|35|36|37|38|39|40|41|42|43|44|45|46|47|48|50|52|54|56|58|60|62|75|85|90|94|95|98|102|105|106|110|114|118|14\s1\/2s|15\s1\/2l|16l|17\s1\/2l|17\s3\/4l|17l|18\s1\/2s|29\/32|30\/32|30l|30s|31\/32|31\/34|32\/32|32\/34|32l|32s|33\/32|33\/34|34\/32|34\/34|34l|34s|35\/34|35\/36|36\/34|36l|36s|37\/38|37l|37s|38-7|38l|38s|39\/40|39-42|39-7|39l|39l\/40l|40-7|40l|40s|41\/42|41l|41l\/42l|42l|42s|43\/44|43-46|43l|43l\/44l|44l|44s|45\/46|46l|46s|47l|47s|48l|48s|52l|52s|56s|CustomMade|electronic|l|m|one-size|physical|s|xl|xs|xxl)$
 * @param properties.price: Price of single product
 * @param properties.quantity: Amount of products added to cart
 * @param properties.position: Position of product, should always start with 1 
 * @param properties.imageUrl: URL of shown image/hero of product
Can sometimes be different from viewport url
 * @param properties.onlineMaterial: Material of product
 * @param properties.fitId: no description
 * @param properties.plpGridImages: The view of the product on PLP, how many products per row, and how many pictures if 1 item is shown on a row., should match regex: ^(|1_product_default|1_product_with_[1-3]_images_default|2_products_default|2_products_grid|3_products_grid|4_products_grid)$
 * @param properties.shopTheLookId: This describes the shop the look id, if available
 * @param properties.cartId: Cart ID of customer
 * @param properties.mixAndMatch: Should return 'true' or 'false' 
 * @param properties.productSetId: Product Set Id when product is mix and match
 * @param properties.sku: SKU of product, same as product_id
 * @param properties.fullLook: Send an additional variable that returns all the SKU's of the products when a full look is added to Wishlist (lookbuilder).
 * @param properties.value: Price * Quantity
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/kHvR74th3.ifkXa9S8M}
 */
export function productClickedSimilarProducts(properties: ProductClickedSimilarProductsProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "RmqMZ4ONr", name: "product_id", value: properties.productId});
  eventPropertiesArray.push({id: "IiKR7dr0F", name: "category", value: properties.category});
  eventPropertiesArray.push({id: "sQMBHWFY_", name: "item_category2", value: properties.itemCategory2});
  eventPropertiesArray.push({id: "JieId3fKm", name: "item_category3", value: properties.itemCategory3});
  eventPropertiesArray.push({id: "5vOpX9hBs", name: "item_category4", value: properties.itemCategory4});
  eventPropertiesArray.push({id: "AtqbQIXpb", name: "item_category5", value: properties.itemCategory5});
  eventPropertiesArray.push({id: "c5sqtMlHz", name: "affiliation", value: properties.affiliation});
  eventPropertiesArray.push({id: "A8Oa9tHEe", name: "name", value: properties.name});
  eventPropertiesArray.push({id: "rNNvglLqV", name: "variant", value: properties.variant});
  eventPropertiesArray.push({id: "5RMsvPEmT", name: "price", value: properties.price});
  eventPropertiesArray.push({id: "fg1kaNXr_", name: "quantity", value: properties.quantity});
  eventPropertiesArray.push({id: "pBPbnpdFj", name: "position", value: properties.position});
  eventPropertiesArray.push({id: "EmHDBRDoN", name: "image_url", value: properties.imageUrl});
  properties.onlineMaterial !== undefined && properties.onlineMaterial !== null ?
    eventPropertiesArray.push({id: "h_-BHBawq", name: "online_material", value: properties.onlineMaterial}) :
    eventPropertiesArray.push({id: "h_-BHBawq", name: "online_material", value: null});
  properties.fitId !== undefined && properties.fitId !== null ?
    eventPropertiesArray.push({id: "SfqMYUwv4", name: "fit_id", value: properties.fitId}) :
    eventPropertiesArray.push({id: "SfqMYUwv4", name: "fit_id", value: null});
  eventPropertiesArray.push({id: "D8L1_0pcs", name: "plp_grid_images", value: properties.plpGridImages});
  eventPropertiesArray.push({id: "vPAMuxU06", name: "shop_the_look_id", value: properties.shopTheLookId});
  eventPropertiesArray.push({id: "14smmnfJh", name: "cart_id", value: properties.cartId});
  eventPropertiesArray.push({id: "3YGdl5qO_", name: "mix_and_match", value: properties.mixAndMatch});
  eventPropertiesArray.push({id: "kjpadIQOI", name: "product_set_id", value: properties.productSetId});
  eventPropertiesArray.push({id: "Yf36kEtqN", name: "sku", value: properties.sku});
  properties.fullLook !== undefined && properties.fullLook !== null ?
    eventPropertiesArray.push({id: "1Ull1Kol-", name: "full_look", value: properties.fullLook}) :
    eventPropertiesArray.push({id: "1Ull1Kol-", name: "full_look", value: null});
  eventPropertiesArray.push({id: "Kddfsr3Xx", name: "value", value: properties.value});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "cAQamFkP_", name: "brand", value: "suitsupply"});
  eventPropertiesArray.push({id: "Ygy1AgJzJ", name: "list_id", value: "similar_products"});
  eventPropertiesArray.push({id: "6NzVmVGcY", name: "item_list_name", value: "similar products"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertPrice(properties.price));
    messages = messages.concat(assertQuantity(properties.quantity));
    messages = messages.concat(assertPosition(properties.position));
    messages = messages.concat(assertValue(properties.value));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "kHvR74th3.ifkXa9S8M", "24670c7f2704dc72e1111552b97aea516436d03a76f0a8653b212aab9cb87adf", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Product Clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("kHvR74th3.ifkXa9S8M", "Product Clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Product Clicked': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Product Clicked", eventProperties, "kHvR74th3.ifkXa9S8M", "24670c7f2704dc72e1111552b97aea516436d03a76f0a8653b212aab9cb87adf");
    }
    // destination Segment
    Segment.logEvent("Product Clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ProductViewedProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  listId: string | null | undefined;
  itemListName: string | null | undefined;
  productId: string;
  category: string;
  itemCategory2: string;
  itemCategory3: string;
  itemCategory4: string;
  itemCategory5: string;
  affiliation: AffiliationValueType;
  name: string;
  variant: string | null | undefined;
  price: number;
  quantity: number;
  position: number;
  imageUrl: string;
  onlineMaterial: string | null | undefined;
  fitId: string | null | undefined;
  plpGridImages: string;
  shopTheLookId: string;
  cartId: string;
  mixAndMatch: MixAndMatchValueType;
  productSetId: string;
  sku: string;
  fullLook: string | null | undefined;
  value: number;
}
/**
 * Product Viewed: When landing an a PDP, or clicking on 'select size' in panel

 * 
 * When to trigger this event:
 * 1. When clicking on 'Select Size' in the 'Shop the Look' panel
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/4a040kO3L/trigger/biZR2zvCbF
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.listId: This describes the listname + unique location

for custommade it's: custom-made, should match regex: ^(|black-tie-package_(configurator|journal)|category_page|category_page_filtered|category-page-landing_(classic-suits|jackets-tuxedo|perennial-suits|shirts-tuxedo|suits-tuxedo|traveller-suits|tuxedo-suits|wedding-suits)|color-swatch_pdp|colour-swatch_pdp|custom-made|discover-our-collection_journal_(alter-your-fit|custom-made|the-hallmarks-of-a-quality-(shirt|shoe))|discover-the-new-collection_(csr-landing|newsletter-emailpreferences)|e\.thomas-collection_journal_e-thomas-mill|explore-the-collection_journal_(4-ply-traveller-collection|angelico-mill|candiani-mill|delfino-mill|drago-mill|fossati-mill|how-to-take-care-of-your-(knits|suits)|perennial-suits|reda-mill|reggiani-mill|relaxed-trousers-guide|weba-mill)|giftcard_landing|gift-wrapping_(cart|mini-cart)|most-popular_(store-detail|store-locator)|search|shop-(french-linen|indonesian-mother-of-pearl|the-look)_(journal_expedition-jort-the-tailoring-trail|custommade|lookbuilder_summary-screen|minicart|slider(_[1-7])?|wishlist)|view-similar-items_oos|wishlist|you-might-also-like_pdp)$
 * @param properties.itemListName: Name of list where products are shown

For custommade, it's 'custom made', should match regex: ^(|black tie package|category page|category page filtered|category page landing|color swatch|colour swatch|custom made|discover the new collection|explore the collection|gift wrapping|giftcard landing|most popular|search|shop the look|view similar items|wishlist|you might also like)$
 * @param properties.productId: SKU of product

for custommade:
trousers: dyo-trousers
jacket: dyo-jacket
waistcoat: dyo-waistcoat
coat: dyo-coat
shirt: dyo-shirt
 * @param properties.category: Product Category, should match regex: ^(|belts|coats|cufflinks|gift card|gift-wrapping|gloves|handkerchiefs|hats|jackets|knitwear|scarves|shirts|shoecare|shoes|shorts|socks|suits|suspenders|ties|tiessub|trousers|waistcoats)$
 * @param properties.itemCategory2: no description
 * @param properties.itemCategory3: no description
 * @param properties.itemCategory4: no description
 * @param properties.itemCategory5: no description
 * @param properties.affiliation: This should be webstore-{{currencycode lowercase}}
 * @param properties.name: Name of product as listed in Salesforce, english only, lowercase
Will be gift card for giftcard

for custommade:
trousers: dyo-trousers
jacket: dyo-jacket
waistcoat: dyo-waistcoat
coat: dyo-coat
shirt: dyo-shirt
 * @param properties.variant: Should return size for all products except giftcard. For Giftcard should return 'physical' or 'electronic', should match regex: ^(|5|6|6\.5|7|7\.5|8|8\.5|9|9\.5|10|10\.5|11|12|13|14|14\s1\/2|15|15\s1\/2|15\s3\/4|16|16\s1\/2|17|17\s1\/2|18|18\s1\/2|19|22|23|24|25|26|27|28|29|30|32|34|35|36|37|38|39|40|41|42|43|44|45|46|47|48|50|52|54|56|58|60|62|75|85|90|94|95|98|102|105|106|110|114|118|14\s1\/2s|15\s1\/2l|16l|17\s1\/2l|17\s3\/4l|17l|18\s1\/2s|29\/32|30\/32|30l|30s|31\/32|31\/34|32\/32|32\/34|32l|32s|33\/32|33\/34|34\/32|34\/34|34l|34s|35\/34|35\/36|36\/34|36l|36s|37\/38|37l|37s|38-7|38l|38s|39\/40|39-42|39-7|39l|39l\/40l|40-7|40l|40s|41\/42|41l|41l\/42l|42l|42s|43\/44|43-46|43l|43l\/44l|44l|44s|45\/46|46l|46s|47l|47s|48l|48s|52l|52s|56s|CustomMade|electronic|l|m|one-size|physical|s|xl|xs|xxl)$
 * @param properties.price: Price of single product
 * @param properties.quantity: Amount of products added to cart
 * @param properties.position: Position of product, should always start with 1 
 * @param properties.imageUrl: URL of shown image/hero of product
Can sometimes be different from viewport url
 * @param properties.onlineMaterial: Material of product
 * @param properties.fitId: no description
 * @param properties.plpGridImages: The view of the product on PLP, how many products per row, and how many pictures if 1 item is shown on a row., should match regex: ^(|1_product_default|1_product_with_[1-3]_images_default|2_products_default|2_products_grid|3_products_grid|4_products_grid)$
 * @param properties.shopTheLookId: This describes the shop the look id, if available
 * @param properties.cartId: Cart ID of customer
 * @param properties.mixAndMatch: Should return 'true' or 'false' 
 * @param properties.productSetId: Product Set Id when product is mix and match
 * @param properties.sku: SKU of product, same as product_id
 * @param properties.fullLook: Send an additional variable that returns all the SKU's of the products when a full look is added to Wishlist (lookbuilder).
 * @param properties.value: Price * Quantity
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/4a040kO3L}
 */
export function productViewed(properties: ProductViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  properties.listId !== undefined && properties.listId !== null ?
    eventPropertiesArray.push({id: "Ygy1AgJzJ", name: "list_id", value: properties.listId}) :
    eventPropertiesArray.push({id: "Ygy1AgJzJ", name: "list_id", value: null});
  properties.itemListName !== undefined && properties.itemListName !== null ?
    eventPropertiesArray.push({id: "6NzVmVGcY", name: "item_list_name", value: properties.itemListName}) :
    eventPropertiesArray.push({id: "6NzVmVGcY", name: "item_list_name", value: null});
  eventPropertiesArray.push({id: "RmqMZ4ONr", name: "product_id", value: properties.productId});
  eventPropertiesArray.push({id: "IiKR7dr0F", name: "category", value: properties.category});
  eventPropertiesArray.push({id: "sQMBHWFY_", name: "item_category2", value: properties.itemCategory2});
  eventPropertiesArray.push({id: "JieId3fKm", name: "item_category3", value: properties.itemCategory3});
  eventPropertiesArray.push({id: "5vOpX9hBs", name: "item_category4", value: properties.itemCategory4});
  eventPropertiesArray.push({id: "AtqbQIXpb", name: "item_category5", value: properties.itemCategory5});
  eventPropertiesArray.push({id: "c5sqtMlHz", name: "affiliation", value: properties.affiliation});
  eventPropertiesArray.push({id: "A8Oa9tHEe", name: "name", value: properties.name});
  properties.variant !== undefined && properties.variant !== null ?
    eventPropertiesArray.push({id: "rNNvglLqV", name: "variant", value: properties.variant}) :
    eventPropertiesArray.push({id: "rNNvglLqV", name: "variant", value: null});
  eventPropertiesArray.push({id: "5RMsvPEmT", name: "price", value: properties.price});
  eventPropertiesArray.push({id: "fg1kaNXr_", name: "quantity", value: properties.quantity});
  eventPropertiesArray.push({id: "pBPbnpdFj", name: "position", value: properties.position});
  eventPropertiesArray.push({id: "EmHDBRDoN", name: "image_url", value: properties.imageUrl});
  properties.onlineMaterial !== undefined && properties.onlineMaterial !== null ?
    eventPropertiesArray.push({id: "h_-BHBawq", name: "online_material", value: properties.onlineMaterial}) :
    eventPropertiesArray.push({id: "h_-BHBawq", name: "online_material", value: null});
  properties.fitId !== undefined && properties.fitId !== null ?
    eventPropertiesArray.push({id: "SfqMYUwv4", name: "fit_id", value: properties.fitId}) :
    eventPropertiesArray.push({id: "SfqMYUwv4", name: "fit_id", value: null});
  eventPropertiesArray.push({id: "D8L1_0pcs", name: "plp_grid_images", value: properties.plpGridImages});
  eventPropertiesArray.push({id: "vPAMuxU06", name: "shop_the_look_id", value: properties.shopTheLookId});
  eventPropertiesArray.push({id: "14smmnfJh", name: "cart_id", value: properties.cartId});
  eventPropertiesArray.push({id: "3YGdl5qO_", name: "mix_and_match", value: properties.mixAndMatch});
  eventPropertiesArray.push({id: "kjpadIQOI", name: "product_set_id", value: properties.productSetId});
  eventPropertiesArray.push({id: "Yf36kEtqN", name: "sku", value: properties.sku});
  properties.fullLook !== undefined && properties.fullLook !== null ?
    eventPropertiesArray.push({id: "1Ull1Kol-", name: "full_look", value: properties.fullLook}) :
    eventPropertiesArray.push({id: "1Ull1Kol-", name: "full_look", value: null});
  eventPropertiesArray.push({id: "Kddfsr3Xx", name: "value", value: properties.value});
  eventPropertiesArray.push({id: "cAQamFkP_", name: "brand", value: "suitsupply"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertPrice(properties.price));
    messages = messages.concat(assertQuantity(properties.quantity));
    messages = messages.concat(assertPosition(properties.position));
    messages = messages.concat(assertValue(properties.value));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "4a040kO3L", "2b9047c8de1be637e01cbe23b3b47cbe90e260fb9737f6b15b2653b891e06b3f", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Product Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("4a040kO3L", "Product Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Product Viewed': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Product Viewed", eventProperties, "4a040kO3L", "2b9047c8de1be637e01cbe23b3b47cbe90e260fb9737f6b15b2653b891e06b3f");
    }
    // destination Segment
    Segment.logEvent("Product Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ProductAddedProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  listId: string;
  itemListName: string;
  productId: string;
  category: string;
  itemCategory2: string;
  itemCategory3: string;
  itemCategory4: string;
  itemCategory5: string;
  affiliation: AffiliationValueType;
  name: string;
  variant: string;
  price: number;
  quantity: number;
  position: number;
  value: number;
  imageUrl: string;
  onlineMaterial: string | null | undefined;
  fitId: string | null | undefined;
  plpGridImages: string;
  shopTheLookId: string;
  productsCart: ProductsCart[];
  cartId: string;
  mixAndMatch: MixAndMatchValueType;
  productSetId: string;
  sku: string;
  fullLook: string | null | undefined;
}
/**
 * Product Added: When clicking on 'add to bag' on a PDP, panel or increasing value on the cart page. 
for Custommade: when clicking on 'add to bag' on the summary screen.
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * @param properties.listId: This describes the listname + unique location

for custommade it's: custom-made, should match regex: ^(|black-tie-package_(configurator|journal)|category_page|category_page_filtered|category-page-landing_(classic-suits|jackets-tuxedo|perennial-suits|shirts-tuxedo|suits-tuxedo|traveller-suits|tuxedo-suits|wedding-suits)|color-swatch_pdp|colour-swatch_pdp|custom-made|discover-our-collection_journal_(alter-your-fit|custom-made|the-hallmarks-of-a-quality-(shirt|shoe))|discover-the-new-collection_(csr-landing|newsletter-emailpreferences)|e\.thomas-collection_journal_e-thomas-mill|explore-the-collection_journal_(4-ply-traveller-collection|angelico-mill|candiani-mill|delfino-mill|drago-mill|fossati-mill|how-to-take-care-of-your-(knits|suits)|perennial-suits|reda-mill|reggiani-mill|relaxed-trousers-guide|weba-mill)|giftcard_landing|gift-wrapping_(cart|mini-cart)|most-popular_(store-detail|store-locator)|search|shop-(french-linen|indonesian-mother-of-pearl|the-look)_(journal_expedition-jort-the-tailoring-trail|custommade|lookbuilder_summary-screen|minicart|slider(_[1-7])?|wishlist)|view-similar-items_oos|wishlist|you-might-also-like_pdp)$
 * @param properties.itemListName: Name of list where products are shown

For custommade, it's 'custom made', should match regex: ^(|black tie package|category page|category page filtered|category page landing|color swatch|colour swatch|custom made|discover the new collection|explore the collection|gift wrapping|giftcard landing|most popular|search|shop the look|view similar items|wishlist|you might also like)$
 * @param properties.productId: SKU of product

for custommade:
trousers: dyo-trousers
jacket: dyo-jacket
waistcoat: dyo-waistcoat
coat: dyo-coat
shirt: dyo-shirt
 * @param properties.category: Product Category, should match regex: ^(|belts|coats|cufflinks|gift card|gift-wrapping|gloves|handkerchiefs|hats|jackets|knitwear|scarves|shirts|shoecare|shoes|shorts|socks|suits|suspenders|ties|tiessub|trousers|waistcoats)$
 * @param properties.itemCategory2: no description
 * @param properties.itemCategory3: no description
 * @param properties.itemCategory4: no description
 * @param properties.itemCategory5: no description
 * @param properties.affiliation: This should be webstore-{{currencycode lowercase}}
 * @param properties.name: Name of product as listed in Salesforce, english only, lowercase
Will be gift card for giftcard

for custommade:
trousers: dyo-trousers
jacket: dyo-jacket
waistcoat: dyo-waistcoat
coat: dyo-coat
shirt: dyo-shirt
 * @param properties.variant: Should return size for all products except giftcard. For Giftcard should return 'physical' or 'electronic', should match regex: ^(|5|6|6\.5|7|7\.5|8|8\.5|9|9\.5|10|10\.5|11|12|13|14|14\s1\/2|15|15\s1\/2|15\s3\/4|16|16\s1\/2|17|17\s1\/2|18|18\s1\/2|19|22|23|24|25|26|27|28|29|30|32|34|35|36|37|38|39|40|41|42|43|44|45|46|47|48|50|52|54|56|58|60|62|75|85|90|94|95|98|102|105|106|110|114|118|14\s1\/2s|15\s1\/2l|16l|17\s1\/2l|17\s3\/4l|17l|18\s1\/2s|29\/32|30\/32|30l|30s|31\/32|31\/34|32\/32|32\/34|32l|32s|33\/32|33\/34|34\/32|34\/34|34l|34s|35\/34|35\/36|36\/34|36l|36s|37\/38|37l|37s|38-7|38l|38s|39\/40|39-42|39-7|39l|39l\/40l|40-7|40l|40s|41\/42|41l|41l\/42l|42l|42s|43\/44|43-46|43l|43l\/44l|44l|44s|45\/46|46l|46s|47l|47s|48l|48s|52l|52s|56s|CustomMade|electronic|l|m|one-size|physical|s|xl|xs|xxl)$
 * @param properties.price: Price of single product
 * @param properties.quantity: Amount of products added to cart
 * @param properties.position: Position of product, should always start with 1 
 * @param properties.value: Price * Quantity
 * @param properties.imageUrl: URL of shown image/hero of product
Can sometimes be different from viewport url
 * @param properties.onlineMaterial: Material of product
 * @param properties.fitId: no description
 * @param properties.plpGridImages: The view of the product on PLP, how many products per row, and how many pictures if 1 item is shown on a row., should match regex: ^(|1_product_default|1_product_with_[1-3]_images_default|2_products_default|2_products_grid|3_products_grid|4_products_grid)$
 * @param properties.shopTheLookId: This describes the shop the look id, if available
 * @param properties.productsCart: Previously known ssm_products. Should contain an array of all products in the cart. Needs to be cleared on the 'Order Completed' event. 
 * @param properties.cartId: Cart ID of customer
 * @param properties.mixAndMatch: Should return 'true' or 'false' 
 * @param properties.productSetId: Product Set Id when product is mix and match
 * @param properties.sku: SKU of product, same as product_id
 * @param properties.fullLook: Send an additional variable that returns all the SKU's of the products when a full look is added to Wishlist (lookbuilder).
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/0mNPEK9jV}
 */
export function productAdded(properties: ProductAddedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "Ygy1AgJzJ", name: "list_id", value: properties.listId});
  eventPropertiesArray.push({id: "6NzVmVGcY", name: "item_list_name", value: properties.itemListName});
  eventPropertiesArray.push({id: "RmqMZ4ONr", name: "product_id", value: properties.productId});
  eventPropertiesArray.push({id: "IiKR7dr0F", name: "category", value: properties.category});
  eventPropertiesArray.push({id: "sQMBHWFY_", name: "item_category2", value: properties.itemCategory2});
  eventPropertiesArray.push({id: "JieId3fKm", name: "item_category3", value: properties.itemCategory3});
  eventPropertiesArray.push({id: "5vOpX9hBs", name: "item_category4", value: properties.itemCategory4});
  eventPropertiesArray.push({id: "AtqbQIXpb", name: "item_category5", value: properties.itemCategory5});
  eventPropertiesArray.push({id: "c5sqtMlHz", name: "affiliation", value: properties.affiliation});
  eventPropertiesArray.push({id: "A8Oa9tHEe", name: "name", value: properties.name});
  eventPropertiesArray.push({id: "rNNvglLqV", name: "variant", value: properties.variant});
  eventPropertiesArray.push({id: "5RMsvPEmT", name: "price", value: properties.price});
  eventPropertiesArray.push({id: "fg1kaNXr_", name: "quantity", value: properties.quantity});
  eventPropertiesArray.push({id: "pBPbnpdFj", name: "position", value: properties.position});
  eventPropertiesArray.push({id: "Kddfsr3Xx", name: "value", value: properties.value});
  eventPropertiesArray.push({id: "EmHDBRDoN", name: "image_url", value: properties.imageUrl});
  properties.onlineMaterial !== undefined && properties.onlineMaterial !== null ?
    eventPropertiesArray.push({id: "h_-BHBawq", name: "online_material", value: properties.onlineMaterial}) :
    eventPropertiesArray.push({id: "h_-BHBawq", name: "online_material", value: null});
  properties.fitId !== undefined && properties.fitId !== null ?
    eventPropertiesArray.push({id: "SfqMYUwv4", name: "fit_id", value: properties.fitId}) :
    eventPropertiesArray.push({id: "SfqMYUwv4", name: "fit_id", value: null});
  eventPropertiesArray.push({id: "D8L1_0pcs", name: "plp_grid_images", value: properties.plpGridImages});
  eventPropertiesArray.push({id: "vPAMuxU06", name: "shop_the_look_id", value: properties.shopTheLookId});
  eventPropertiesArray.push({id: "NbRKFviNt", name: "products_cart", value: properties.productsCart.map(function(value) { return {
      "amount": value.amount,
      "identifier": value.identifier,
      "variation": value.variation,
      "currency": value.currency,
      "quantity": value.quantity,
      "marketingcloudpid": value.marketingcloudpid,
      "variant_id": value.variantId,
    }
  })});
  eventPropertiesArray.push({id: "14smmnfJh", name: "cart_id", value: properties.cartId});
  eventPropertiesArray.push({id: "3YGdl5qO_", name: "mix_and_match", value: properties.mixAndMatch});
  eventPropertiesArray.push({id: "kjpadIQOI", name: "product_set_id", value: properties.productSetId});
  eventPropertiesArray.push({id: "Yf36kEtqN", name: "sku", value: properties.sku});
  properties.fullLook !== undefined && properties.fullLook !== null ?
    eventPropertiesArray.push({id: "1Ull1Kol-", name: "full_look", value: properties.fullLook}) :
    eventPropertiesArray.push({id: "1Ull1Kol-", name: "full_look", value: null});
  eventPropertiesArray.push({id: "cAQamFkP_", name: "brand", value: "suitsupply"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertPrice(properties.price));
    messages = messages.concat(assertQuantity(properties.quantity));
    messages = messages.concat(assertPosition(properties.position));
    messages = messages.concat(assertValue(properties.value));
    messages = messages.concat(assertProductsCart(properties.productsCart));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "0mNPEK9jV", "3058d182d1c458f7b8df246880df37d1f6402fdfe7ef637271c0409d1b7f48cb", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Product Added", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("0mNPEK9jV", "Product Added", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Product Added': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Product Added", eventProperties, "0mNPEK9jV", "3058d182d1c458f7b8df246880df37d1f6402fdfe7ef637271c0409d1b7f48cb");
    }
    // destination Segment
    Segment.logEvent("Product Added", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ProductAddedCmProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  listId: string;
  itemListName: string;
  productId: string;
  category: string;
  itemCategory2: string;
  itemCategory3: string;
  itemCategory4: string;
  itemCategory5: string;
  affiliation: AffiliationValueType;
  name: string;
  price: number;
  quantity: number;
  position: number;
  value: number;
  imageUrl: string;
  plpGridImages: string;
  shopTheLookId: string;
  productsCart: ProductsCart[];
  cartId: string;
  mixAndMatch: MixAndMatchValueType;
  productSetId: string;
  sku: string;
}
/**
 * Product Added: When clicking on 'add to bag' on a PDP, panel or increasing value on the cart page. 
for Custommade: when clicking on 'add to bag' on the summary screen., event variant
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * @param properties.listId: This describes the listname + unique location

for custommade it's: custom-made, should match regex: ^(|black-tie-package_(configurator|journal)|category_page|category_page_filtered|category-page-landing_(classic-suits|jackets-tuxedo|perennial-suits|shirts-tuxedo|suits-tuxedo|traveller-suits|tuxedo-suits|wedding-suits)|color-swatch_pdp|colour-swatch_pdp|custom-made|discover-our-collection_journal_(alter-your-fit|custom-made|the-hallmarks-of-a-quality-(shirt|shoe))|discover-the-new-collection_(csr-landing|newsletter-emailpreferences)|e\.thomas-collection_journal_e-thomas-mill|explore-the-collection_journal_(4-ply-traveller-collection|angelico-mill|candiani-mill|delfino-mill|drago-mill|fossati-mill|how-to-take-care-of-your-(knits|suits)|perennial-suits|reda-mill|reggiani-mill|relaxed-trousers-guide|weba-mill)|giftcard_landing|gift-wrapping_(cart|mini-cart)|most-popular_(store-detail|store-locator)|search|shop-(french-linen|indonesian-mother-of-pearl|the-look)_(journal_expedition-jort-the-tailoring-trail|custommade|lookbuilder_summary-screen|minicart|slider(_[1-7])?|wishlist)|view-similar-items_oos|wishlist|you-might-also-like_pdp)$
 * @param properties.itemListName: Name of list where products are shown

For custommade, it's 'custom made', should match regex: ^(|black tie package|category page|category page filtered|category page landing|color swatch|colour swatch|custom made|discover the new collection|explore the collection|gift wrapping|giftcard landing|most popular|search|shop the look|view similar items|wishlist|you might also like)$
 * @param properties.productId: SKU of product

for custommade:
trousers: dyo-trousers
jacket: dyo-jacket
waistcoat: dyo-waistcoat
coat: dyo-coat
shirt: dyo-shirt
 * @param properties.category: Product Category, should match regex: ^(|belts|coats|cufflinks|gift card|gift-wrapping|gloves|handkerchiefs|hats|jackets|knitwear|scarves|shirts|shoecare|shoes|shorts|socks|suits|suspenders|ties|tiessub|trousers|waistcoats)$
 * @param properties.itemCategory2: no description
 * @param properties.itemCategory3: no description
 * @param properties.itemCategory4: no description
 * @param properties.itemCategory5: no description
 * @param properties.affiliation: This should be webstore-{{currencycode lowercase}}
 * @param properties.name: Name of product as listed in Salesforce, english only, lowercase
Will be gift card for giftcard

for custommade:
trousers: dyo-trousers
jacket: dyo-jacket
waistcoat: dyo-waistcoat
coat: dyo-coat
shirt: dyo-shirt
 * @param properties.price: Price of single product
 * @param properties.quantity: Amount of products added to cart
 * @param properties.position: Position of product, should always start with 1 
 * @param properties.value: Price * Quantity
 * @param properties.imageUrl: URL of shown image/hero of product
Can sometimes be different from viewport url
 * @param properties.plpGridImages: The view of the product on PLP, how many products per row, and how many pictures if 1 item is shown on a row., should match regex: ^(|1_product_default|1_product_with_[1-3]_images_default|2_products_default|2_products_grid|3_products_grid|4_products_grid)$
 * @param properties.shopTheLookId: This describes the shop the look id, if available
 * @param properties.productsCart: Previously known ssm_products. Should contain an array of all products in the cart. Needs to be cleared on the 'Order Completed' event. 
 * @param properties.cartId: Cart ID of customer
 * @param properties.mixAndMatch: Should return 'true' or 'false' 
 * @param properties.productSetId: Product Set Id when product is mix and match
 * @param properties.sku: SKU of product, same as product_id
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/0mNPEK9jV.a9tlg0c-F}
 */
export function productAddedCm(properties: ProductAddedCmProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "Ygy1AgJzJ", name: "list_id", value: properties.listId});
  eventPropertiesArray.push({id: "6NzVmVGcY", name: "item_list_name", value: properties.itemListName});
  eventPropertiesArray.push({id: "RmqMZ4ONr", name: "product_id", value: properties.productId});
  eventPropertiesArray.push({id: "IiKR7dr0F", name: "category", value: properties.category});
  eventPropertiesArray.push({id: "sQMBHWFY_", name: "item_category2", value: properties.itemCategory2});
  eventPropertiesArray.push({id: "JieId3fKm", name: "item_category3", value: properties.itemCategory3});
  eventPropertiesArray.push({id: "5vOpX9hBs", name: "item_category4", value: properties.itemCategory4});
  eventPropertiesArray.push({id: "AtqbQIXpb", name: "item_category5", value: properties.itemCategory5});
  eventPropertiesArray.push({id: "c5sqtMlHz", name: "affiliation", value: properties.affiliation});
  eventPropertiesArray.push({id: "A8Oa9tHEe", name: "name", value: properties.name});
  eventPropertiesArray.push({id: "5RMsvPEmT", name: "price", value: properties.price});
  eventPropertiesArray.push({id: "fg1kaNXr_", name: "quantity", value: properties.quantity});
  eventPropertiesArray.push({id: "pBPbnpdFj", name: "position", value: properties.position});
  eventPropertiesArray.push({id: "Kddfsr3Xx", name: "value", value: properties.value});
  eventPropertiesArray.push({id: "EmHDBRDoN", name: "image_url", value: properties.imageUrl});
  eventPropertiesArray.push({id: "D8L1_0pcs", name: "plp_grid_images", value: properties.plpGridImages});
  eventPropertiesArray.push({id: "vPAMuxU06", name: "shop_the_look_id", value: properties.shopTheLookId});
  eventPropertiesArray.push({id: "NbRKFviNt", name: "products_cart", value: properties.productsCart.map(function(value) { return {
      "amount": value.amount,
      "identifier": value.identifier,
      "variation": value.variation,
      "currency": value.currency,
      "quantity": value.quantity,
      "marketingcloudpid": value.marketingcloudpid,
      "variant_id": value.variantId,
    }
  })});
  eventPropertiesArray.push({id: "14smmnfJh", name: "cart_id", value: properties.cartId});
  eventPropertiesArray.push({id: "3YGdl5qO_", name: "mix_and_match", value: properties.mixAndMatch});
  eventPropertiesArray.push({id: "kjpadIQOI", name: "product_set_id", value: properties.productSetId});
  eventPropertiesArray.push({id: "Yf36kEtqN", name: "sku", value: properties.sku});
  eventPropertiesArray.push({id: "cAQamFkP_", name: "brand", value: "suitsupply"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertPrice(properties.price));
    messages = messages.concat(assertQuantity(properties.quantity));
    messages = messages.concat(assertPosition(properties.position));
    messages = messages.concat(assertValue(properties.value));
    messages = messages.concat(assertProductsCart(properties.productsCart));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "0mNPEK9jV.a9tlg0c-F", "2d7b5ac8f0c2f507462308b994470dfee8c066f1726e5dec82559e465f13d686", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Product Added", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("0mNPEK9jV.a9tlg0c-F", "Product Added", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Product Added': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Product Added", eventProperties, "0mNPEK9jV.a9tlg0c-F", "2d7b5ac8f0c2f507462308b994470dfee8c066f1726e5dec82559e465f13d686");
    }
    // destination Segment
    Segment.logEvent("Product Added", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PageLoadedProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  customDestinationPageName_: string;
  localeVisited: string;
  productsCart: ProductsCart[] | null | undefined;
}
/**
 * Page Loaded: When a page or a virtual page is loaded
for custommade: products_cart and referrer not needed
 * 
 * When to trigger this event:
 * 1. Title: instore - checkin - login
url: https://suitsupply.com/en-nl//instore/checkin-login/
location_id: https://suitsupply.com/en-nl//instore/checkin-login/
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/4l0ZskS226/trigger/GzKmdfzMs
 * 2. title: instore - checkin - register
location_id: https://suitsupply.com/en-nl/instore/checkin-register/
url: https://suitsupply.com/instore/checkin-register/
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/4l0ZskS226/trigger/h5T0sz4zf
 * 3. title: instore - checkin - long succeeded
url: https://suitsupply.com/instore/checkin-succeeded-wait-long/
location_id: https://suitsupply.com/en-nl/instore/checkin-succeeded-wait-long/
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/4l0ZskS226/trigger/WL4lVMb0g
 * 4. title: instore - checkin - short succeeded
url: https://suitsupply.com/instore/checkin-succeeded-wait-short/
location_id: https://suitsupply.com/en-nl/instore/checkin-succeeded-wait-short/
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/4l0ZskS226/trigger/Djt5Cknvx
 * 5. 1. black-tie-configurator page 1 (jacket):

url: 

https://suitsupply.com/men/black-tie-package/mix-match-tuxedo/jacket


location_id:

https://suitsupply.com/en-nl/men/black-tie-package/mix-match-tuxedo/jacket
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/4l0ZskS226/trigger/bjjJ9GC32
 * 6. 3.black-tie-configurator page 3 (trousers):

url: 

https://suitsupply.com/men/black-tie-package/mix-match-tuxedo/trousers

location_id:

https://suitsupply.com/en-nl/men/black-tie-package/mix-match-tuxedo/trousers
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/4l0ZskS226/trigger/9N-3rJX7w
 * 7. 4.black-tie-configurator page 4 (shirt):

url: 

https://suitsupply.com/men/black-tie-package/mix-match-tuxedo/shirt

location_id:

https://suitsupply.com/en-nl/men/black-tie-package/mix-match-tuxedo/shirt
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/4l0ZskS226/trigger/hRyZsTCWg
 * 8. 5.black-tie-configurator page 5 (shoes):

url: 

https://suitsupply.com/men/black-tie-package/mix-match-tuxedo/shoes

location_id:

Two piece::
https://suitsupply.com/en-nl/men/black-tie-package/mix-match-tuxedo/shoes
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/4l0ZskS226/trigger/SgNDh79wH
 * 9. 6.black-tie-configurator page 6 (bow-tie):

url: 

https://suitsupply.com/men/black-tie-package/mix-match-tuxedo/bowtie

location_id:

https://suitsupply.com/en-nl/men/black-tie-package/mix-match-tuxedo/bowtie
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/4l0ZskS226/trigger/fqSg1J0Lw
 * 10. 7.black-tie-configurator page 7 (cufflinks):

url: 

https://suitsupply.com/men/black-tie-package/mix-match-tuxedo/cufflinks


location_id:

https://suitsupply.com/en-nl/men/black-tie-package/mix-match-tuxedo/cufflinks
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/4l0ZskS226/trigger/bObRbcuk9
 * 11. 8.black-tie-configurator page 8 (suspenders):

url: 

https://suitsupply.com/men/black-tie-package/mix-match-tuxedo/suspenders

location_id:

https://suitsupply.com/en-nl/men/black-tie-package/mix-match-tuxedo/suspenders
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/4l0ZskS226/trigger/sW1aJ_pG0
 * 12. 9.black-tie-configurator page 9 (summary):

url: 

https://suitsupply.com/men/black-tie-package/mix-match-tuxedo/summary

location_id:

https://suitsupply.com/en-nl/men/black-tie-package/mix-match-tuxedo/summary
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/4l0ZskS226/trigger/dmE9uQLdg
 * 13. 1b.black-tie-configurator page 1b (jacket lapel):

url: 

https://suitsupply.com/men/black-tie-package/mix-match-tuxedo/jacket/lapel


location_id:

https://suitsupply.com/en-nl/men/black-tie-package/mix-match-tuxedo/jacket/lapel
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/4l0ZskS226/trigger/tfcaQfTbT
 * 14. 2.black-tie-configurator page 1 (waistcoat):

url: 

https://suitsupply.com/men/black-tie-package/mix-match-tuxedo/waistcoat

location_id:

https://suitsupply.com/en-nl/men/black-tie-package/mix-match-tuxedo/waistcoat
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/4l0ZskS226/trigger/JtGp7KJRc
 * 15. page_type: category_landing
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/4l0ZskS226/trigger/A-zNqBmM1
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.customDestinationPageName_: Name of the page or screen
 * @param properties.localeVisited: Locale visited, for example en-us or es-us
 * @param properties.productsCart: Previously known ssm_products. Should contain an array of all products in the cart. Needs to be cleared on the 'Order Completed' event. 
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/4l0ZskS226}
 */
export function pageLoaded(properties: PageLoadedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "QSRXqGwpG", name: "locale_visited", value: properties.localeVisited});
  properties.productsCart !== undefined && properties.productsCart !== null ?
    eventPropertiesArray.push({id: "NbRKFviNt", name: "products_cart", value: properties.productsCart.map(function(value) { return {
        "amount": value.amount,
        "identifier": value.identifier,
        "variation": value.variation,
        "currency": value.currency,
        "quantity": value.quantity,
        "marketingcloudpid": value.marketingcloudpid,
        "variant_id": value.variantId,
      }
    })}) :
    eventPropertiesArray.push({id: "NbRKFviNt", name: "products_cart", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertProductsCartOptional(properties.productsCart));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "4l0ZskS226", "2f2dae5e87eca80d74d26447575e10fe5d958e85c257e987278cac47b5ae603a", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Page Loaded", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("4l0ZskS226", "Page Loaded", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Page Loaded': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    // destination Segment
    if (Segment.logPage) {
      Segment.logPage(properties.customDestinationPageName_, (Object as any).assign({}, eventProperties));
    } else {
      InternalAvoLogger.error('Page method is not implemented in the destination', '');
    }
  } else {
    // do nothing
  }
}

export interface PageLoadedVirtualPageViewProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  customDestinationPageName_: string;
  localeVisited: string;
  productsCart: ProductsCart[] | null | undefined;
}
/**
 * Page Loaded: When a page or a virtual page is loaded
for custommade: products_cart and referrer not needed, event variant
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.customDestinationPageName_: Name of the page or screen
 * @param properties.localeVisited: Locale visited, for example en-us or es-us
 * @param properties.productsCart: Previously known ssm_products. Should contain an array of all products in the cart. Needs to be cleared on the 'Order Completed' event. 
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/4l0ZskS226.zXISHUvOO}
 */
export function pageLoadedVirtualPageView(properties: PageLoadedVirtualPageViewProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "QSRXqGwpG", name: "locale_visited", value: properties.localeVisited});
  properties.productsCart !== undefined && properties.productsCart !== null ?
    eventPropertiesArray.push({id: "NbRKFviNt", name: "products_cart", value: properties.productsCart.map(function(value) { return {
        "amount": value.amount,
        "identifier": value.identifier,
        "variation": value.variation,
        "currency": value.currency,
        "quantity": value.quantity,
        "marketingcloudpid": value.marketingcloudpid,
        "variant_id": value.variantId,
      }
    })}) :
    eventPropertiesArray.push({id: "NbRKFviNt", name: "products_cart", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertProductsCartOptional(properties.productsCart));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "4l0ZskS226.zXISHUvOO", "bae265b85bc612dd344253a32c3bbcdcaf67c8b414fc1a570fdd95e210bbdafe", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Page Loaded", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("4l0ZskS226.zXISHUvOO", "Page Loaded", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Page Loaded': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    // destination Segment
    if (Segment.logPage) {
      Segment.logPage(properties.customDestinationPageName_, (Object as any).assign({}, eventProperties));
    } else {
      InternalAvoLogger.error('Page method is not implemented in the destination', '');
    }
  } else {
    // do nothing
  }
}

export interface PromotionViewedProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  promotionId: string;
  name: string;
  creative: CreativeValueType;
  position: number;
  ssMedium: string | null | undefined;
  ssSource: string | null | undefined;
  eventLabel: string | null | undefined;
  eventLocation: string | null | undefined;
}
/**
 * Promotion Viewed: When a promotion is being shown in the viewport 
Only add the 'products' object, if there are products in the promotion.

ss_source and ss_medium should only be filled for booking panel and SA panel

 * 
 * When to trigger this event:
 * 1. Journal - Support

promotion_name and id:
delivery-time-cost_support
returns-refunds_support
secure-payments_support
custommade-customizing_app
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/Xo8CpcqD3k/trigger/dmF0VfnHj
 * 2. Footer - Newsletter

promotion_id & name:
newsletter_campaign

Creative:
footer_link
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/Xo8CpcqD3k/trigger/9dwfTm8_b
 * 3. Lookbuilder - Shop the look

promotion id + name:
lookbuilder_app

creative: 
shop-the-look_panel
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/Xo8CpcqD3k/trigger/Do1oLQCdp
 * 4. Lookbuilder - wishlist

Promotion id + name:
lookbuilder_app

Creative:
panel_wishlist
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/Xo8CpcqD3k/trigger/XvOaPaSL_
 * 5. Lookbuilder - minicart

Promotion id + name:
lookbuilder_app

Creative:
panel_minicart
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/Xo8CpcqD3k/trigger/81fjlwsyo
 * 6. Lookbuilder - MWTW

Promotion id + name:
lookbuilder_app

Creative:
pdp_panel_mwtw
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/Xo8CpcqD3k/trigger/udZGnmpX6
 * 7. ﻿Lookbuilder - summary screen

Promotion id + name:
lookbuilder_app

Creative:
lookbuilder_summary
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/Xo8CpcqD3k/trigger/uqdx_UxpT
 * 8. creative: journal_adblock
promotion_id: black-tie-configurator-tuxedo_app
name: black-tie-configurator-tuxedo_app
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/Xo8CpcqD3k/trigger/wIqd9HpV3
 * 9. creative: clp_adblock
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/Xo8CpcqD3k/trigger/WmsGiomFq
 * 10. Creative: clp_buttonlink
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/Xo8CpcqD3k/trigger/hqttb-Imb
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.promotionId: ID of promotion, is the same as promotion name
 * @param properties.name: Name of product as listed in Salesforce, english only, lowercase
Will be gift card for giftcard

for custommade:
trousers: dyo-trousers
jacket: dyo-jacket
waistcoat: dyo-waistcoat
coat: dyo-coat
shirt: dyo-shirt
 * @param properties.creative: Name of location and type of the promotion
 * @param properties.position: Position of product, should always start with 1 
 * @param properties.ssMedium: To make sure utm tags are not overwriting the utm tags from ads, we want to make sure the BT entries are tracked with the ss_medium tag. Otherwise we are not able to track the costs per lead from our facebook or other campaigns., should match regex: ^$|bt_panel|cs_panel
 * @param properties.ssSource: To make sure utm tags are not overwriting the utm tags from ads, we want to make sure the BT entries are tracked with the ss_medium tag. Otherwise we are not able to track the costs per lead from our facebook or other campaigns.

For store pages: ss_source: store_{{storename}}
For store experience pages: ss_source: journal_{{journal name with “-” between words}}
for store custommade near me: ss_source: journal_store_{{journal name with “-“ between words + store parameter in new landing pages}}
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/Xo8CpcqD3k}
 */
export function promotionViewed(properties: PromotionViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "9zU3NBFWZ", name: "promotion_id", value: properties.promotionId});
  eventPropertiesArray.push({id: "A8Oa9tHEe", name: "name", value: properties.name});
  eventPropertiesArray.push({id: "oFjan4Q_Q", name: "creative", value: properties.creative});
  eventPropertiesArray.push({id: "pBPbnpdFj", name: "position", value: properties.position});
  properties.ssMedium !== undefined && properties.ssMedium !== null ?
    eventPropertiesArray.push({id: "dCzQ_0RdM", name: "ss_medium", value: properties.ssMedium}) :
    eventPropertiesArray.push({id: "dCzQ_0RdM", name: "ss_medium", value: null});
  properties.ssSource !== undefined && properties.ssSource !== null ?
    eventPropertiesArray.push({id: "PBq0iEV4f", name: "ss_source", value: properties.ssSource}) :
    eventPropertiesArray.push({id: "PBq0iEV4f", name: "ss_source", value: null});
  properties.eventLabel !== undefined && properties.eventLabel !== null ?
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel}) :
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: null});
  properties.eventLocation !== undefined && properties.eventLocation !== null ?
    eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation}) :
    eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: null});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: "ecommerce_interactions"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertPosition(properties.position));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Xo8CpcqD3k", "add9a2ce58fa29310c506708a30b99f102aa3bf1c1911f4863fea40be836827e", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Promotion Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Xo8CpcqD3k", "Promotion Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Promotion Viewed': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Promotion Viewed", eventProperties, "Xo8CpcqD3k", "add9a2ce58fa29310c506708a30b99f102aa3bf1c1911f4863fea40be836827e");
    }
    // destination Segment
    Segment.logEvent("Promotion Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PromotionClickedProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  promotionId: string;
  name: string;
  creative: CreativeValueType;
  position: number;
  ssMedium: string | null | undefined;
  ssSource: string | null | undefined;
  eventCategory: EventCategoryValueType;
}
/**
 * Promotion Clicked: When a promotion is clicked
Only add the 'products' object, if there are products in the promotion.

ss_source and ss_medium should only be filled for booking panel and SA panel
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.promotionId: ID of promotion, is the same as promotion name
 * @param properties.name: Name of product as listed in Salesforce, english only, lowercase
Will be gift card for giftcard

for custommade:
trousers: dyo-trousers
jacket: dyo-jacket
waistcoat: dyo-waistcoat
coat: dyo-coat
shirt: dyo-shirt
 * @param properties.creative: Name of location and type of the promotion
 * @param properties.position: Position of product, should always start with 1 
 * @param properties.ssMedium: To make sure utm tags are not overwriting the utm tags from ads, we want to make sure the BT entries are tracked with the ss_medium tag. Otherwise we are not able to track the costs per lead from our facebook or other campaigns., should match regex: ^$|bt_panel|cs_panel
 * @param properties.ssSource: To make sure utm tags are not overwriting the utm tags from ads, we want to make sure the BT entries are tracked with the ss_medium tag. Otherwise we are not able to track the costs per lead from our facebook or other campaigns.

For store pages: ss_source: store_{{storename}}
For store experience pages: ss_source: journal_{{journal name with “-” between words}}
for store custommade near me: ss_source: journal_store_{{journal name with “-“ between words + store parameter in new landing pages}}
 * @param properties.eventCategory: Overall event category
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/lb-B8o9Lf}
 */
export function promotionClicked(properties: PromotionClickedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "9zU3NBFWZ", name: "promotion_id", value: properties.promotionId});
  eventPropertiesArray.push({id: "A8Oa9tHEe", name: "name", value: properties.name});
  eventPropertiesArray.push({id: "oFjan4Q_Q", name: "creative", value: properties.creative});
  eventPropertiesArray.push({id: "pBPbnpdFj", name: "position", value: properties.position});
  properties.ssMedium !== undefined && properties.ssMedium !== null ?
    eventPropertiesArray.push({id: "dCzQ_0RdM", name: "ss_medium", value: properties.ssMedium}) :
    eventPropertiesArray.push({id: "dCzQ_0RdM", name: "ss_medium", value: null});
  properties.ssSource !== undefined && properties.ssSource !== null ?
    eventPropertiesArray.push({id: "PBq0iEV4f", name: "ss_source", value: properties.ssSource}) :
    eventPropertiesArray.push({id: "PBq0iEV4f", name: "ss_source", value: null});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertPosition(properties.position));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "lb-B8o9Lf", "a821c093e21491fa5e027ae0fa90292c7889f021b3cd20482653aa46a5846ad5", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Promotion Clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("lb-B8o9Lf", "Promotion Clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Promotion Clicked': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Promotion Clicked", eventProperties, "lb-B8o9Lf", "a821c093e21491fa5e027ae0fa90292c7889f021b3cd20482653aa46a5846ad5");
    }
    // destination Segment
    Segment.logEvent("Promotion Clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ProductAddedToWishlistProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  listId: string;
  itemListName: string;
  productId: string;
  category: string;
  itemCategory2: string;
  itemCategory3: string;
  itemCategory4: string;
  itemCategory5: string;
  affiliation: AffiliationValueType;
  name: string;
  variant: string;
  price: number;
  quantity: number;
  position: number;
  imageUrl: string;
  onlineMaterial: string | null | undefined;
  fitId: string | null | undefined;
  plpGridImages: string;
  shopTheLookId: string;
  cartId: string;
  mixAndMatch: MixAndMatchValueType;
  productSetId: string;
  sku: string;
  fullLook: string | null | undefined;
  value: number;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * Product Added to Wishlist: When landing an a PDP and clicking the add to wishlist icon

For full looks, fire an add_to_wishlist event for every specific product.
 * 
 * When to trigger this event:
 * 1. When selecting the 'Add to Wishlist' icon.
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/MoZcZa9Kt/trigger/21cyJ2qeD
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.listId: This describes the listname + unique location

for custommade it's: custom-made, should match regex: ^(|black-tie-package_(configurator|journal)|category_page|category_page_filtered|category-page-landing_(classic-suits|jackets-tuxedo|perennial-suits|shirts-tuxedo|suits-tuxedo|traveller-suits|tuxedo-suits|wedding-suits)|color-swatch_pdp|colour-swatch_pdp|custom-made|discover-our-collection_journal_(alter-your-fit|custom-made|the-hallmarks-of-a-quality-(shirt|shoe))|discover-the-new-collection_(csr-landing|newsletter-emailpreferences)|e\.thomas-collection_journal_e-thomas-mill|explore-the-collection_journal_(4-ply-traveller-collection|angelico-mill|candiani-mill|delfino-mill|drago-mill|fossati-mill|how-to-take-care-of-your-(knits|suits)|perennial-suits|reda-mill|reggiani-mill|relaxed-trousers-guide|weba-mill)|giftcard_landing|gift-wrapping_(cart|mini-cart)|most-popular_(store-detail|store-locator)|search|shop-(french-linen|indonesian-mother-of-pearl|the-look)_(journal_expedition-jort-the-tailoring-trail|custommade|lookbuilder_summary-screen|minicart|slider(_[1-7])?|wishlist)|view-similar-items_oos|wishlist|you-might-also-like_pdp)$
 * @param properties.itemListName: Name of list where products are shown

For custommade, it's 'custom made', should match regex: ^(|black tie package|category page|category page filtered|category page landing|color swatch|colour swatch|custom made|discover the new collection|explore the collection|gift wrapping|giftcard landing|most popular|search|shop the look|view similar items|wishlist|you might also like)$
 * @param properties.productId: SKU of product

for custommade:
trousers: dyo-trousers
jacket: dyo-jacket
waistcoat: dyo-waistcoat
coat: dyo-coat
shirt: dyo-shirt
 * @param properties.category: Product Category, should match regex: ^(|belts|coats|cufflinks|gift card|gift-wrapping|gloves|handkerchiefs|hats|jackets|knitwear|scarves|shirts|shoecare|shoes|shorts|socks|suits|suspenders|ties|tiessub|trousers|waistcoats)$
 * @param properties.itemCategory2: no description
 * @param properties.itemCategory3: no description
 * @param properties.itemCategory4: no description
 * @param properties.itemCategory5: no description
 * @param properties.affiliation: This should be webstore-{{currencycode lowercase}}
 * @param properties.name: Name of product as listed in Salesforce, english only, lowercase
Will be gift card for giftcard

for custommade:
trousers: dyo-trousers
jacket: dyo-jacket
waistcoat: dyo-waistcoat
coat: dyo-coat
shirt: dyo-shirt
 * @param properties.variant: Should return size for all products except giftcard. For Giftcard should return 'physical' or 'electronic', should match regex: ^(|5|6|6\.5|7|7\.5|8|8\.5|9|9\.5|10|10\.5|11|12|13|14|14\s1\/2|15|15\s1\/2|15\s3\/4|16|16\s1\/2|17|17\s1\/2|18|18\s1\/2|19|22|23|24|25|26|27|28|29|30|32|34|35|36|37|38|39|40|41|42|43|44|45|46|47|48|50|52|54|56|58|60|62|75|85|90|94|95|98|102|105|106|110|114|118|14\s1\/2s|15\s1\/2l|16l|17\s1\/2l|17\s3\/4l|17l|18\s1\/2s|29\/32|30\/32|30l|30s|31\/32|31\/34|32\/32|32\/34|32l|32s|33\/32|33\/34|34\/32|34\/34|34l|34s|35\/34|35\/36|36\/34|36l|36s|37\/38|37l|37s|38-7|38l|38s|39\/40|39-42|39-7|39l|39l\/40l|40-7|40l|40s|41\/42|41l|41l\/42l|42l|42s|43\/44|43-46|43l|43l\/44l|44l|44s|45\/46|46l|46s|47l|47s|48l|48s|52l|52s|56s|CustomMade|electronic|l|m|one-size|physical|s|xl|xs|xxl)$
 * @param properties.price: Price of single product
 * @param properties.quantity: Amount of products added to cart
 * @param properties.position: Position of product, should always start with 1 
 * @param properties.imageUrl: URL of shown image/hero of product
Can sometimes be different from viewport url
 * @param properties.onlineMaterial: Material of product
 * @param properties.fitId: no description
 * @param properties.plpGridImages: The view of the product on PLP, how many products per row, and how many pictures if 1 item is shown on a row., should match regex: ^(|1_product_default|1_product_with_[1-3]_images_default|2_products_default|2_products_grid|3_products_grid|4_products_grid)$
 * @param properties.shopTheLookId: This describes the shop the look id, if available
 * @param properties.cartId: Cart ID of customer
 * @param properties.mixAndMatch: Should return 'true' or 'false' 
 * @param properties.productSetId: Product Set Id when product is mix and match
 * @param properties.sku: SKU of product, same as product_id
 * @param properties.fullLook: Send an additional variable that returns all the SKU's of the products when a full look is added to Wishlist (lookbuilder).
 * @param properties.value: Price * Quantity
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/MoZcZa9Kt}
 */
export function productAddedToWishlist(properties: ProductAddedToWishlistProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "Ygy1AgJzJ", name: "list_id", value: properties.listId});
  eventPropertiesArray.push({id: "6NzVmVGcY", name: "item_list_name", value: properties.itemListName});
  eventPropertiesArray.push({id: "RmqMZ4ONr", name: "product_id", value: properties.productId});
  eventPropertiesArray.push({id: "IiKR7dr0F", name: "category", value: properties.category});
  eventPropertiesArray.push({id: "sQMBHWFY_", name: "item_category2", value: properties.itemCategory2});
  eventPropertiesArray.push({id: "JieId3fKm", name: "item_category3", value: properties.itemCategory3});
  eventPropertiesArray.push({id: "5vOpX9hBs", name: "item_category4", value: properties.itemCategory4});
  eventPropertiesArray.push({id: "AtqbQIXpb", name: "item_category5", value: properties.itemCategory5});
  eventPropertiesArray.push({id: "c5sqtMlHz", name: "affiliation", value: properties.affiliation});
  eventPropertiesArray.push({id: "A8Oa9tHEe", name: "name", value: properties.name});
  eventPropertiesArray.push({id: "rNNvglLqV", name: "variant", value: properties.variant});
  eventPropertiesArray.push({id: "5RMsvPEmT", name: "price", value: properties.price});
  eventPropertiesArray.push({id: "fg1kaNXr_", name: "quantity", value: properties.quantity});
  eventPropertiesArray.push({id: "pBPbnpdFj", name: "position", value: properties.position});
  eventPropertiesArray.push({id: "EmHDBRDoN", name: "image_url", value: properties.imageUrl});
  properties.onlineMaterial !== undefined && properties.onlineMaterial !== null ?
    eventPropertiesArray.push({id: "h_-BHBawq", name: "online_material", value: properties.onlineMaterial}) :
    eventPropertiesArray.push({id: "h_-BHBawq", name: "online_material", value: null});
  properties.fitId !== undefined && properties.fitId !== null ?
    eventPropertiesArray.push({id: "SfqMYUwv4", name: "fit_id", value: properties.fitId}) :
    eventPropertiesArray.push({id: "SfqMYUwv4", name: "fit_id", value: null});
  eventPropertiesArray.push({id: "D8L1_0pcs", name: "plp_grid_images", value: properties.plpGridImages});
  eventPropertiesArray.push({id: "vPAMuxU06", name: "shop_the_look_id", value: properties.shopTheLookId});
  eventPropertiesArray.push({id: "14smmnfJh", name: "cart_id", value: properties.cartId});
  eventPropertiesArray.push({id: "3YGdl5qO_", name: "mix_and_match", value: properties.mixAndMatch});
  eventPropertiesArray.push({id: "kjpadIQOI", name: "product_set_id", value: properties.productSetId});
  eventPropertiesArray.push({id: "Yf36kEtqN", name: "sku", value: properties.sku});
  properties.fullLook !== undefined && properties.fullLook !== null ?
    eventPropertiesArray.push({id: "1Ull1Kol-", name: "full_look", value: properties.fullLook}) :
    eventPropertiesArray.push({id: "1Ull1Kol-", name: "full_look", value: null});
  eventPropertiesArray.push({id: "Kddfsr3Xx", name: "value", value: properties.value});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "cAQamFkP_", name: "brand", value: "suitsupply"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertPrice(properties.price));
    messages = messages.concat(assertQuantity(properties.quantity));
    messages = messages.concat(assertPosition(properties.position));
    messages = messages.concat(assertValue(properties.value));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "MoZcZa9Kt", "15af838204d649de9c87dd1092725b8d082ab512296e94e70d1a625e06df1195", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Product Added to Wishlist", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("MoZcZa9Kt", "Product Added to Wishlist", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Product Added to Wishlist': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Product Added to Wishlist", eventProperties, "MoZcZa9Kt", "15af838204d649de9c87dd1092725b8d082ab512296e94e70d1a625e06df1195");
    }
    // destination Segment
    Segment.logEvent("Product Added to Wishlist", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ProductAddedToWishlistOutOfStockProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  listId: string;
  itemListName: string;
  productId: string;
  category: string;
  itemCategory2: string;
  itemCategory3: string;
  itemCategory4: string;
  itemCategory5: string;
  affiliation: AffiliationValueType;
  name: string;
  variant: string;
  price: number;
  quantity: number;
  position: number;
  imageUrl: string;
  onlineMaterial: string | null | undefined;
  fitId: string | null | undefined;
  plpGridImages: string;
  shopTheLookId: string;
  cartId: string;
  mixAndMatch: MixAndMatchValueType;
  productSetId: string;
  sku: string;
  fullLook: string | null | undefined;
  value: number;
  eventLabel: string;
  eventLocation: string;
}
/**
 * Product Added to Wishlist: When landing an a PDP and clicking the add to wishlist icon

For full looks, fire an add_to_wishlist event for every specific product., variant description: This variant is designed to monitor products added to the wishlist that are currently out of stock. Consequently, we can utilize this event to segment users who have out-of-stock items in their wishlist and will be affected by back to stock notifications.
 * 
 * When to trigger this event:
 * 1. When the user clicks in "Add to Saved" on the out of stock item message
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/MoZcZa9Kt.ArNrE0Iib/trigger/woVdajZYlqN
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.listId: This describes the listname + unique location

for custommade it's: custom-made, should match regex: ^(|black-tie-package_(configurator|journal)|category_page|category_page_filtered|category-page-landing_(classic-suits|jackets-tuxedo|perennial-suits|shirts-tuxedo|suits-tuxedo|traveller-suits|tuxedo-suits|wedding-suits)|color-swatch_pdp|colour-swatch_pdp|custom-made|discover-our-collection_journal_(alter-your-fit|custom-made|the-hallmarks-of-a-quality-(shirt|shoe))|discover-the-new-collection_(csr-landing|newsletter-emailpreferences)|e\.thomas-collection_journal_e-thomas-mill|explore-the-collection_journal_(4-ply-traveller-collection|angelico-mill|candiani-mill|delfino-mill|drago-mill|fossati-mill|how-to-take-care-of-your-(knits|suits)|perennial-suits|reda-mill|reggiani-mill|relaxed-trousers-guide|weba-mill)|giftcard_landing|gift-wrapping_(cart|mini-cart)|most-popular_(store-detail|store-locator)|search|shop-(french-linen|indonesian-mother-of-pearl|the-look)_(journal_expedition-jort-the-tailoring-trail|custommade|lookbuilder_summary-screen|minicart|slider(_[1-7])?|wishlist)|view-similar-items_oos|wishlist|you-might-also-like_pdp)$
 * @param properties.itemListName: Name of list where products are shown

For custommade, it's 'custom made', should match regex: ^(|black tie package|category page|category page filtered|category page landing|color swatch|colour swatch|custom made|discover the new collection|explore the collection|gift wrapping|giftcard landing|most popular|search|shop the look|view similar items|wishlist|you might also like)$
 * @param properties.productId: SKU of product

for custommade:
trousers: dyo-trousers
jacket: dyo-jacket
waistcoat: dyo-waistcoat
coat: dyo-coat
shirt: dyo-shirt
 * @param properties.category: Product Category, should match regex: ^(|belts|coats|cufflinks|gift card|gift-wrapping|gloves|handkerchiefs|hats|jackets|knitwear|scarves|shirts|shoecare|shoes|shorts|socks|suits|suspenders|ties|tiessub|trousers|waistcoats)$
 * @param properties.itemCategory2: no description
 * @param properties.itemCategory3: no description
 * @param properties.itemCategory4: no description
 * @param properties.itemCategory5: no description
 * @param properties.affiliation: This should be webstore-{{currencycode lowercase}}
 * @param properties.name: Name of product as listed in Salesforce, english only, lowercase
Will be gift card for giftcard

for custommade:
trousers: dyo-trousers
jacket: dyo-jacket
waistcoat: dyo-waistcoat
coat: dyo-coat
shirt: dyo-shirt
 * @param properties.variant: Should return size for all products except giftcard. For Giftcard should return 'physical' or 'electronic', should match regex: ^(|5|6|6\.5|7|7\.5|8|8\.5|9|9\.5|10|10\.5|11|12|13|14|14\s1\/2|15|15\s1\/2|15\s3\/4|16|16\s1\/2|17|17\s1\/2|18|18\s1\/2|19|22|23|24|25|26|27|28|29|30|32|34|35|36|37|38|39|40|41|42|43|44|45|46|47|48|50|52|54|56|58|60|62|75|85|90|94|95|98|102|105|106|110|114|118|14\s1\/2s|15\s1\/2l|16l|17\s1\/2l|17\s3\/4l|17l|18\s1\/2s|29\/32|30\/32|30l|30s|31\/32|31\/34|32\/32|32\/34|32l|32s|33\/32|33\/34|34\/32|34\/34|34l|34s|35\/34|35\/36|36\/34|36l|36s|37\/38|37l|37s|38-7|38l|38s|39\/40|39-42|39-7|39l|39l\/40l|40-7|40l|40s|41\/42|41l|41l\/42l|42l|42s|43\/44|43-46|43l|43l\/44l|44l|44s|45\/46|46l|46s|47l|47s|48l|48s|52l|52s|56s|CustomMade|electronic|l|m|one-size|physical|s|xl|xs|xxl)$
 * @param properties.price: Price of single product
 * @param properties.quantity: Amount of products added to cart
 * @param properties.position: Position of product, should always start with 1 
 * @param properties.imageUrl: URL of shown image/hero of product
Can sometimes be different from viewport url
 * @param properties.onlineMaterial: Material of product
 * @param properties.fitId: no description
 * @param properties.plpGridImages: The view of the product on PLP, how many products per row, and how many pictures if 1 item is shown on a row., should match regex: ^(|1_product_default|1_product_with_[1-3]_images_default|2_products_default|2_products_grid|3_products_grid|4_products_grid)$
 * @param properties.shopTheLookId: This describes the shop the look id, if available
 * @param properties.cartId: Cart ID of customer
 * @param properties.mixAndMatch: Should return 'true' or 'false' 
 * @param properties.productSetId: Product Set Id when product is mix and match
 * @param properties.sku: SKU of product, same as product_id
 * @param properties.fullLook: Send an additional variable that returns all the SKU's of the products when a full look is added to Wishlist (lookbuilder).
 * @param properties.value: Price * Quantity
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/MoZcZa9Kt.ArNrE0Iib}
 */
export function productAddedToWishlistOutOfStock(properties: ProductAddedToWishlistOutOfStockProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "Ygy1AgJzJ", name: "list_id", value: properties.listId});
  eventPropertiesArray.push({id: "6NzVmVGcY", name: "item_list_name", value: properties.itemListName});
  eventPropertiesArray.push({id: "RmqMZ4ONr", name: "product_id", value: properties.productId});
  eventPropertiesArray.push({id: "IiKR7dr0F", name: "category", value: properties.category});
  eventPropertiesArray.push({id: "sQMBHWFY_", name: "item_category2", value: properties.itemCategory2});
  eventPropertiesArray.push({id: "JieId3fKm", name: "item_category3", value: properties.itemCategory3});
  eventPropertiesArray.push({id: "5vOpX9hBs", name: "item_category4", value: properties.itemCategory4});
  eventPropertiesArray.push({id: "AtqbQIXpb", name: "item_category5", value: properties.itemCategory5});
  eventPropertiesArray.push({id: "c5sqtMlHz", name: "affiliation", value: properties.affiliation});
  eventPropertiesArray.push({id: "A8Oa9tHEe", name: "name", value: properties.name});
  eventPropertiesArray.push({id: "rNNvglLqV", name: "variant", value: properties.variant});
  eventPropertiesArray.push({id: "5RMsvPEmT", name: "price", value: properties.price});
  eventPropertiesArray.push({id: "fg1kaNXr_", name: "quantity", value: properties.quantity});
  eventPropertiesArray.push({id: "pBPbnpdFj", name: "position", value: properties.position});
  eventPropertiesArray.push({id: "EmHDBRDoN", name: "image_url", value: properties.imageUrl});
  properties.onlineMaterial !== undefined && properties.onlineMaterial !== null ?
    eventPropertiesArray.push({id: "h_-BHBawq", name: "online_material", value: properties.onlineMaterial}) :
    eventPropertiesArray.push({id: "h_-BHBawq", name: "online_material", value: null});
  properties.fitId !== undefined && properties.fitId !== null ?
    eventPropertiesArray.push({id: "SfqMYUwv4", name: "fit_id", value: properties.fitId}) :
    eventPropertiesArray.push({id: "SfqMYUwv4", name: "fit_id", value: null});
  eventPropertiesArray.push({id: "D8L1_0pcs", name: "plp_grid_images", value: properties.plpGridImages});
  eventPropertiesArray.push({id: "vPAMuxU06", name: "shop_the_look_id", value: properties.shopTheLookId});
  eventPropertiesArray.push({id: "14smmnfJh", name: "cart_id", value: properties.cartId});
  eventPropertiesArray.push({id: "3YGdl5qO_", name: "mix_and_match", value: properties.mixAndMatch});
  eventPropertiesArray.push({id: "kjpadIQOI", name: "product_set_id", value: properties.productSetId});
  eventPropertiesArray.push({id: "Yf36kEtqN", name: "sku", value: properties.sku});
  properties.fullLook !== undefined && properties.fullLook !== null ?
    eventPropertiesArray.push({id: "1Ull1Kol-", name: "full_look", value: properties.fullLook}) :
    eventPropertiesArray.push({id: "1Ull1Kol-", name: "full_look", value: null});
  eventPropertiesArray.push({id: "Kddfsr3Xx", name: "value", value: properties.value});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "cAQamFkP_", name: "brand", value: "suitsupply"});
  eventPropertiesArray.push({id: "gHO_HPRSg", name: "out_of_stock", value: "true"});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: "ecommerce_interactions"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertPrice(properties.price));
    messages = messages.concat(assertQuantity(properties.quantity));
    messages = messages.concat(assertPosition(properties.position));
    messages = messages.concat(assertValue(properties.value));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "MoZcZa9Kt.ArNrE0Iib", "d7e412a23006cbe7eab5a3e9efbb0ddb8a989008b0736fec1d9edb79f0c0bb10", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Product Added to Wishlist", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("MoZcZa9Kt.ArNrE0Iib", "Product Added to Wishlist", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Product Added to Wishlist': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Product Added to Wishlist", eventProperties, "MoZcZa9Kt.ArNrE0Iib", "d7e412a23006cbe7eab5a3e9efbb0ddb8a989008b0736fec1d9edb79f0c0bb10");
    }
    // destination Segment
    Segment.logEvent("Product Added to Wishlist", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SelectSizeProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string | null | undefined;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * select_size: When clicking the 'select size' button on PDP.

event_category:
If the event is happening on a journal page, the event_category will be plp_interactions, it's too much work for dev to create a specific journal_interactions.
For lookbuilder it will be lookbuilder_interactions


event_label: {{category}}_{{SKU}}

 * 
 * When to trigger this event:
 * 1. When clicking on the 'select size' button
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/QZ8ruF0wR/trigger/K_ScJetAs
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/QZ8ruF0wR}
 */
export function selectSize(properties: SelectSizeProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  properties.eventLabel !== undefined && properties.eventLabel !== null ?
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel}) :
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: null});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "QZ8ruF0wR", "9842a43da00156b11ffe4b8325c037107fe0893f0d62fd6e8f5b10728a44c294", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("select_size", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("QZ8ruF0wR", "select_size", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("select_size", eventProperties, "QZ8ruF0wR", "9842a43da00156b11ffe4b8325c037107fe0893f0d62fd6e8f5b10728a44c294");
    }
    // destination Segment
    Segment.logEvent("select_size", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SelectSizeSimilarProductsProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string | null | undefined;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * select_size: When clicking the 'select size' button on PDP.

event_category:
If the event is happening on a journal page, the event_category will be plp_interactions, it's too much work for dev to create a specific journal_interactions.
For lookbuilder it will be lookbuilder_interactions


event_label: {{category}}_{{SKU}}, event variant
 * 
 * When to trigger this event:
 * 1. When the user clicks on a product's "Select Size" button in the "Similar Products" list
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/QZ8ruF0wR.eBZz_RwUK/trigger/_81MS6-1axF
 * 2. 
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/QZ8ruF0wR.eBZz_RwUK/trigger/68xJMqJQDCp
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/QZ8ruF0wR.eBZz_RwUK}
 */
export function selectSizeSimilarProducts(properties: SelectSizeSimilarProductsProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  properties.eventLabel !== undefined && properties.eventLabel !== null ?
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel}) :
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: null});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "QZ8ruF0wR.eBZz_RwUK", "82e33733280b72732c93ba40d7d9a628f234742a4b71ec5644d86ee756e7b53b", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("select_size", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("QZ8ruF0wR.eBZz_RwUK", "select_size", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("select_size", eventProperties, "QZ8ruF0wR.eBZz_RwUK", "82e33733280b72732c93ba40d7d9a628f234742a4b71ec5644d86ee756e7b53b");
    }
    // destination Segment
    Segment.logEvent("select_size", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SizeChangedProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string | null | undefined;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * size_changed: When selecting a size in the size panel

event_label: {{category}}_{{SKU}}

event_category:
If the event is happening on a journal page, the event_category will be plp_interactions, it's too much work for dev to create a specific journal_interactions.
For lookbuilder it will be lookbuilder_interactions

 * 
 * When to trigger this event:
 * 1. When clicking on a size in the panel
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/FLODrHtrT/trigger/THklYHMK-
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/FLODrHtrT}
 */
export function sizeChanged(properties: SizeChangedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  properties.eventLabel !== undefined && properties.eventLabel !== null ?
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel}) :
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: null});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "FLODrHtrT", "b0bbb9771dce92fccac898587f4559dd1b8cd898bb2a6a715e6afd3a28dc43d9", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("size_changed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("FLODrHtrT", "size_changed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("size_changed", eventProperties, "FLODrHtrT", "b0bbb9771dce92fccac898587f4559dd1b8cd898bb2a6a715e6afd3a28dc43d9");
    }
    // destination Segment
    Segment.logEvent("size_changed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SizeChangedSimilarProductsProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string | null | undefined;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * size_changed: When selecting a size in the size panel

event_label: {{category}}_{{SKU}}

event_category:
If the event is happening on a journal page, the event_category will be plp_interactions, it's too much work for dev to create a specific journal_interactions.
For lookbuilder it will be lookbuilder_interactions, event variant
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/FLODrHtrT.Y3pSQ6ZDs}
 */
export function sizeChangedSimilarProducts(properties: SizeChangedSimilarProductsProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  properties.eventLabel !== undefined && properties.eventLabel !== null ?
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel}) :
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: null});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "FLODrHtrT.Y3pSQ6ZDs", "6a0bae54d4166fb867d2ac66013b776253df981d5b58cf943d0bca5ef63f878e", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("size_changed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("FLODrHtrT.Y3pSQ6ZDs", "size_changed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("size_changed", eventProperties, "FLODrHtrT.Y3pSQ6ZDs", "6a0bae54d4166fb867d2ac66013b776253df981d5b58cf943d0bca5ef63f878e");
    }
    // destination Segment
    Segment.logEvent("size_changed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SizeChangedBtcProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string | null | undefined;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  option: string;
  sku: string;
}
/**
 * size_changed: When selecting a size in the size panel

event_label: {{category}}_{{SKU}}

event_category:
If the event is happening on a journal page, the event_category will be plp_interactions, it's too much work for dev to create a specific journal_interactions.
For lookbuilder it will be lookbuilder_interactions, event variant
 * 
 * When to trigger this event:
 * 1. When the user selects a size in the "Select you size" panel
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/FLODrHtrT.1jhIbXq0k-rFqmmOlzPGH/trigger/iSkrkkk2-zIPe8uMBUojY
 * 2. When the user selects a size in the "Select you size" panel
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/FLODrHtrT.1jhIbXq0k-rFqmmOlzPGH/trigger/07uI9c7aDCZiTJhdGL99v
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * @param properties.option: This property describes the option chosen by the user when using a configurator tool.
 * @param properties.sku: SKU of product, same as product_id
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/FLODrHtrT.1jhIbXq0k-rFqmmOlzPGH}
 */
export function sizeChangedBtc(properties: SizeChangedBtcProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  properties.eventLabel !== undefined && properties.eventLabel !== null ?
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel}) :
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: null});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "0p2gAH62X", name: "option", value: properties.option});
  eventPropertiesArray.push({id: "Yf36kEtqN", name: "sku", value: properties.sku});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "FLODrHtrT.1jhIbXq0k-rFqmmOlzPGH", "b41706cc43698458061b7e94c309e1238ef68045233f8371d51bcbed484357de", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("size_changed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("FLODrHtrT.1jhIbXq0k-rFqmmOlzPGH", "size_changed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("size_changed", eventProperties, "FLODrHtrT.1jhIbXq0k-rFqmmOlzPGH", "b41706cc43698458061b7e94c309e1238ef68045233f8371d51bcbed484357de");
    }
    // destination Segment
    Segment.logEvent("size_changed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ChangeCountryProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string | null | undefined;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * change_country: When changing countries.
event_label: {{country from}}_{{country to}}
event_location: menu|pop-up|footer
 * 
 * When to trigger this event:
 * 1. When selecting a country
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/P8tUoYxJa/trigger/iAlBJNRHt
 * 2. When clicking on the change country button, in the pop up banner
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/P8tUoYxJa/trigger/XocwpVpmE
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/P8tUoYxJa}
 */
export function changeCountry(properties: ChangeCountryProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  properties.eventLabel !== undefined && properties.eventLabel !== null ?
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel}) :
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: null});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "P8tUoYxJa", "8ac63ad38b5c3aa89266fcaad7ee5aa7eeb49198eacd8ca6151432723c2943c1", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("change_country", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("P8tUoYxJa", "change_country", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("change_country", eventProperties, "P8tUoYxJa", "8ac63ad38b5c3aa89266fcaad7ee5aa7eeb49198eacd8ca6151432723c2943c1");
    }
    // destination Segment
    Segment.logEvent("change_country", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ChangeLanguageProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string | null | undefined;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * change_language: When switching between languages.
event_label: {{language from}}_{{language to}}
event_location: menu|pop-up|footer
 * 
 * When to trigger this event:
 * 1. When selecting a language
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/wv224noP0/trigger/dpnHqNIFZ
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/wv224noP0}
 */
export function changeLanguage(properties: ChangeLanguageProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  properties.eventLabel !== undefined && properties.eventLabel !== null ?
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel}) :
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: null});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "wv224noP0", "1fc51369e7c42816cd0c36b51d954aefcbe227f530136beb0cf8cc57716cce71", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("change_language", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("wv224noP0", "change_language", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("change_language", eventProperties, "wv224noP0", "1fc51369e7c42816cd0c36b51d954aefcbe227f530136beb0cf8cc57716cce71");
    }
    // destination Segment
    Segment.logEvent("change_language", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface HeaderAccountClickProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string | null | undefined;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * header_account_click: When clicking on the account logo in the header. 
event_label: white|transparent
event_location: header
 * 
 * When to trigger this event:
 * 1. When clicking on the account logo in the header
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/u1i6ex4HW/trigger/c4TmbDce1
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/u1i6ex4HW}
 */
export function headerAccountClick(properties: HeaderAccountClickProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  properties.eventLabel !== undefined && properties.eventLabel !== null ?
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel}) :
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: null});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "u1i6ex4HW", "8d1e6e53e7fb274127481f199b631f4c256d1042a7c1340d04e7ecd9ecf90b39", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("header_account_click", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("u1i6ex4HW", "header_account_click", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("header_account_click", eventProperties, "u1i6ex4HW", "8d1e6e53e7fb274127481f199b631f4c256d1042a7c1340d04e7ecd9ecf90b39");
    }
    // destination Segment
    Segment.logEvent("header_account_click", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface HeaderLogoClickProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string | null | undefined;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * header_logo_click: When clicking on the SuitSupply logo in the header. 
event_label: white|transparent
event_location: header
 * 
 * When to trigger this event:
 * 1. When clicking on the SuitSupply logo in the header
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/vJwt-sKKq/trigger/evtGMEfPQ
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/vJwt-sKKq}
 */
export function headerLogoClick(properties: HeaderLogoClickProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  properties.eventLabel !== undefined && properties.eventLabel !== null ?
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel}) :
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: null});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "vJwt-sKKq", "b74d3bfbf7b8e652fab87973e0290ba0d76c99d4ce48499b161462c86528afb5", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("header_logo_click", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("vJwt-sKKq", "header_logo_click", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("header_logo_click", eventProperties, "vJwt-sKKq", "b74d3bfbf7b8e652fab87973e0290ba0d76c99d4ce48499b161462c86528afb5");
    }
    // destination Segment
    Segment.logEvent("header_logo_click", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface HeaderMinicartClickProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string | null | undefined;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * header_minicart_click: When clicking on the minicart logo in the header. 
event_label: white|transparent
event_location: header
 * 
 * When to trigger this event:
 * 1. When clicking on the minicart logo in the header
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/Vzc7Bw6Sp/trigger/zaQ-uoQtx
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/Vzc7Bw6Sp}
 */
export function headerMinicartClick(properties: HeaderMinicartClickProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  properties.eventLabel !== undefined && properties.eventLabel !== null ?
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel}) :
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: null});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Vzc7Bw6Sp", "764a95582a9e0a83597b504918c718c5f108a35899fe8d8389a07c5b974bdf47", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("header_minicart_click", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Vzc7Bw6Sp", "header_minicart_click", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("header_minicart_click", eventProperties, "Vzc7Bw6Sp", "764a95582a9e0a83597b504918c718c5f108a35899fe8d8389a07c5b974bdf47");
    }
    // destination Segment
    Segment.logEvent("header_minicart_click", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface HeaderWishlistClickProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string | null | undefined;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * header_wishlist_click: When clicking on the wishlist logo in the header. 
event_label: white|transparent
event_location: header
 * 
 * When to trigger this event:
 * 1. When clicking on the wishlist logo in the header
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/1Rx8sEaGk/trigger/1_A8oMR5X
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/1Rx8sEaGk}
 */
export function headerWishlistClick(properties: HeaderWishlistClickProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  properties.eventLabel !== undefined && properties.eventLabel !== null ?
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel}) :
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: null});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "1Rx8sEaGk", "a4f739b5e89f2dd34cb92e9294043965e17b5f5457620945a425d324d8a22545", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("header_wishlist_click", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("1Rx8sEaGk", "header_wishlist_click", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("header_wishlist_click", eventProperties, "1Rx8sEaGk", "a4f739b5e89f2dd34cb92e9294043965e17b5f5457620945a425d324d8a22545");
    }
    // destination Segment
    Segment.logEvent("header_wishlist_click", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface MenuOpenedProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string | null | undefined;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * menu_opened: When clicking on the menu logo in the header. 
event_label: white|transparent
event_location: header
 * 
 * When to trigger this event:
 * 1. When clicking on the menu hamburger in the header
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/Rh7-qbVFo/trigger/Ici_uz6Uv
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/Rh7-qbVFo}
 */
export function menuOpened(properties: MenuOpenedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  properties.eventLabel !== undefined && properties.eventLabel !== null ?
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel}) :
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: null});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Rh7-qbVFo", "9df4122790686d4309baeeb85835a5ece9d18d75a6106cb87e2a537dbe0b0eaa", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("menu_opened", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Rh7-qbVFo", "menu_opened", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("menu_opened", eventProperties, "Rh7-qbVFo", "9df4122790686d4309baeeb85835a5ece9d18d75a6106cb87e2a537dbe0b0eaa");
    }
    // destination Segment
    Segment.logEvent("menu_opened", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface MenuClickedProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string | null | undefined;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * menu_clicked: When selecting an option in the menu

event_label: {{first level shoes, clothes etc}}_{{category}}_{{subcategory}} 
example:
1 level: clothing
2 levels: clothing_suits
3 levels: clothing_suits_classic-suits

event_location: menu
 * 
 * When to trigger this event:
 * 1. When clicking an item in the menu
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/q034uYqya/trigger/dTt9M_KVq
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/q034uYqya}
 */
export function menuClicked(properties: MenuClickedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  properties.eventLabel !== undefined && properties.eventLabel !== null ?
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel}) :
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: null});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "q034uYqya", "f11a6a7b3b10c61cf3ed0625454069fc92e85a87ed0063da3fe6b01ad96ae6df", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("menu_clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("q034uYqya", "menu_clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("menu_clicked", eventProperties, "q034uYqya", "f11a6a7b3b10c61cf3ed0625454069fc92e85a87ed0063da3fe6b01ad96ae6df");
    }
    // destination Segment
    Segment.logEvent("menu_clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface FooterClickProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * footer_click: When a link is clicked in the footer.

event_label: {{last path url}} example: returns-refunds.html|suits.  
for socials: facebook|tiktok|youtube|instagram
for contact: whatsapp|phone|email|wechat
for China: wechat-qr-official|wechat-miniprogram|weibo|https://www.beian.miit.gov.cn/
 * 
 * When to trigger this event:
 * 1. When clicking a link in the footer
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/8E2-KsTj0l/trigger/Pf0yTDV5q
 * 2. When clicking on the social icons
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/8E2-KsTj0l/trigger/HkHP6k1jC
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/8E2-KsTj0l}
 */
export function footerClick(properties: FooterClickProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "8E2-KsTj0l", "5263acd16632c7727e34c525bc8d2e3a14e205efa57c5e47568518e2322f7ce0", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("footer_click", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("8E2-KsTj0l", "footer_click", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("footer_click", eventProperties, "8E2-KsTj0l", "5263acd16632c7727e34c525bc8d2e3a14e205efa57c5e47568518e2322f7ce0");
    }
    // destination Segment
    Segment.logEvent("footer_click", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface NewsletterSubscribeProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * newsletter_subscribe: When clicking on the subscribe to email button

event_label: email_verified|email_error_{{error message}}
 * 
 * When to trigger this event:
 * 1. When clicking on the subscribe button
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/_M87doXAKz/trigger/5xFgRsIGI
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/_M87doXAKz}
 */
export function newsletterSubscribe(properties: NewsletterSubscribeProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "_M87doXAKz", "eccf7a9efcbfdc5a4e5d0640a93a6bc7b08f20112cc823bc5eb2ae2f13e05156", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("newsletter_subscribe", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("_M87doXAKz", "newsletter_subscribe", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("newsletter_subscribe", eventProperties, "_M87doXAKz", "eccf7a9efcbfdc5a4e5d0640a93a6bc7b08f20112cc823bc5eb2ae2f13e05156");
    }
    // destination Segment
    Segment.logEvent("newsletter_subscribe", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface FabricOptionSelectProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  cmOption: string | null | undefined;
}
/**
 * fabric_option_select: Firing when the fabric option is clicked.

- cm_option: {{clicked fabric group}}_{{clicked color code}} example: most-popular_black-pure-s110s-wool|black_black-wool-stretch

- event_location: cm_{{category}}_{{subcategory}} example: cm_suits_jacket
 * 
 * When to trigger this event:
 * 1. When a fabric is selected
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/yhrlHOrZQ/trigger/5QvaRyd3N
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * @param properties.cmOption: Describes which option was selected, and which outcome was chosen
Description is added in the event.
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/yhrlHOrZQ}
 */
export function fabricOptionSelect(properties: FabricOptionSelectProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  properties.cmOption !== undefined && properties.cmOption !== null ?
    eventPropertiesArray.push({id: "PAXuVgcO0", name: "cm_option", value: properties.cmOption}) :
    eventPropertiesArray.push({id: "PAXuVgcO0", name: "cm_option", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "yhrlHOrZQ", "d619349dc8403925896b5eafcffa612244d0eccee37220fa1e090ce504f20cb3", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("fabric_option_select", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("yhrlHOrZQ", "fabric_option_select", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("fabric_option_select", eventProperties, "yhrlHOrZQ", "d619349dc8403925896b5eafcffa612244d0eccee37220fa1e090ce504f20cb3");
    }
    // destination Segment
    Segment.logEvent("fabric_option_select", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface FabricOptionConfirmProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  cmOption: string | null | undefined;
}
/**
 * fabric_option_confirm: Firing when the fabric option is confirmed.

- cm_option: {{clicked fabric group}}_{{clicked color code}} example: most-popular_black-pure-s110s-wool|black_black-wool-stretch

- event_location: cm_{{category}}_{{subcategory}} example: cm_suits_jacket
 * 
 * When to trigger this event:
 * 1. When a fabric option is confirmed.
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/kCtNIDRn2/trigger/dIhRahME5
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * @param properties.cmOption: Describes which option was selected, and which outcome was chosen
Description is added in the event.
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/kCtNIDRn2}
 */
export function fabricOptionConfirm(properties: FabricOptionConfirmProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  properties.cmOption !== undefined && properties.cmOption !== null ?
    eventPropertiesArray.push({id: "PAXuVgcO0", name: "cm_option", value: properties.cmOption}) :
    eventPropertiesArray.push({id: "PAXuVgcO0", name: "cm_option", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "kCtNIDRn2", "d42201010229efb1e359b6dc3a3bc7c1ce2783fd6fae236b46896e09b23a72b6", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("fabric_option_confirm", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("kCtNIDRn2", "fabric_option_confirm", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("fabric_option_confirm", eventProperties, "kCtNIDRn2", "d42201010229efb1e359b6dc3a3bc7c1ce2783fd6fae236b46896e09b23a72b6");
    }
    // destination Segment
    Segment.logEvent("fabric_option_confirm", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface StyleOptionSelectProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  btcOption: string | null | undefined;
}
/**
 * style_option_select: Firing when the style option is selected.

- cm_option:{{selected option}} example: business|casual|classic-double-breasted

- event_location: cm_{{category}}_{{subcategory}} example: cm_suits_jacket
 * 
 * When to trigger this event:
 * 1. When a style is selected.
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/eRtiLrwzB/trigger/r-pdwSlnh
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * @param properties.btcOption: no description
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/eRtiLrwzB}
 */
export function styleOptionSelect(properties: StyleOptionSelectProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  properties.btcOption !== undefined && properties.btcOption !== null ?
    eventPropertiesArray.push({id: "bEtCdP6Ew", name: "btc_option", value: properties.btcOption}) :
    eventPropertiesArray.push({id: "bEtCdP6Ew", name: "btc_option", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "eRtiLrwzB", "5a94463823842837d9312162fc9bf4b774c905aa51c57965b845fd262666bb48", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("style_option_select", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("eRtiLrwzB", "style_option_select", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("style_option_select", eventProperties, "eRtiLrwzB", "5a94463823842837d9312162fc9bf4b774c905aa51c57965b845fd262666bb48");
    }
    // destination Segment
    Segment.logEvent("style_option_select", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface StyleOptionSelectBtcProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  btcOption: string | null | undefined;
}
/**
 * style_option_select: Firing when the style option is selected.

- cm_option:{{selected option}} example: business|casual|classic-double-breasted

- event_location: cm_{{category}}_{{subcategory}} example: cm_suits_jacket, variant description: When selecting a style (two-piece or three-piece)

btc_option: two-piece|three-piece
event_location: btc_suits
 * 
 * When to trigger this event:
 * 1. When selecting a style
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/eRtiLrwzB.icAnSf8FR/trigger/Eofccc3se
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * @param properties.btcOption: no description
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/eRtiLrwzB.icAnSf8FR}
 */
export function styleOptionSelectBtc(properties: StyleOptionSelectBtcProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  properties.btcOption !== undefined && properties.btcOption !== null ?
    eventPropertiesArray.push({id: "bEtCdP6Ew", name: "btc_option", value: properties.btcOption}) :
    eventPropertiesArray.push({id: "bEtCdP6Ew", name: "btc_option", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "eRtiLrwzB.icAnSf8FR", "5278c8b958ec507e0d2de2c7c232e88eab9a8f1ce341bb83cb3396e7a69a5f86", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("style_option_select", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("eRtiLrwzB.icAnSf8FR", "style_option_select", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("style_option_select", eventProperties, "eRtiLrwzB.icAnSf8FR", "5278c8b958ec507e0d2de2c7c232e88eab9a8f1ce341bb83cb3396e7a69a5f86");
    }
    // destination Segment
    Segment.logEvent("style_option_select", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface StyleOptionSelectCmProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * style_option_select: Firing when the style option is selected.

- cm_option:{{selected option}} example: business|casual|classic-double-breasted

- event_location: cm_{{category}}_{{subcategory}} example: cm_suits_jacket, event variant
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/eRtiLrwzB.pxxFF_WKY}
 */
export function styleOptionSelectCm(properties: StyleOptionSelectCmProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "eRtiLrwzB.pxxFF_WKY", "76ab8775be451e71f944d44ea9d2a2c5c857fb98e14b0828f77e0686789b400f", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("style_option_select", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("eRtiLrwzB.pxxFF_WKY", "style_option_select", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("style_option_select", eventProperties, "eRtiLrwzB.pxxFF_WKY", "76ab8775be451e71f944d44ea9d2a2c5c857fb98e14b0828f77e0686789b400f");
    }
    // destination Segment
    Segment.logEvent("style_option_select", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface StyleOptionConfirmProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  cmOption: string | null | undefined;
}
/**
 * style_option_confirm: Firing when the style option is selected.

- cm_option:{{selected option}} example: business|casual|classic-double-breasted

- event_location: cm_{{category}}_{{subcategory}} example: cm_suits_jacket
 * 
 * When to trigger this event:
 * 1. When a style is confirmed.
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/yhBSr917Q/trigger/BXyfxriJU
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * @param properties.cmOption: Describes which option was selected, and which outcome was chosen
Description is added in the event.
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/yhBSr917Q}
 */
export function styleOptionConfirm(properties: StyleOptionConfirmProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  properties.cmOption !== undefined && properties.cmOption !== null ?
    eventPropertiesArray.push({id: "PAXuVgcO0", name: "cm_option", value: properties.cmOption}) :
    eventPropertiesArray.push({id: "PAXuVgcO0", name: "cm_option", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "yhBSr917Q", "5f7ec1d8bb0db38c65468140d995aa81de5547fa07059e9fab8560ca2f5270d8", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("style_option_confirm", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("yhBSr917Q", "style_option_confirm", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("style_option_confirm", eventProperties, "yhBSr917Q", "5f7ec1d8bb0db38c65468140d995aa81de5547fa07059e9fab8560ca2f5270d8");
    }
    // destination Segment
    Segment.logEvent("style_option_confirm", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SummaryEditProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  cmOption: string | null | undefined;
}
/**
 * summary_edit: When clicking on 'edit' icon on the summary screen 

- event_location: cm_{{category}}_summaryexample cm_suits_summary
- cm_option: jacket|trousers|waistcoat

 * 
 * When to trigger this event:
 * 1. When clicking on 'edit' icon on the summary screen 
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/KNnHKBbfV/trigger/-TISpFDBA
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * @param properties.cmOption: Describes which option was selected, and which outcome was chosen
Description is added in the event.
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/KNnHKBbfV}
 */
export function summaryEdit(properties: SummaryEditProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  properties.cmOption !== undefined && properties.cmOption !== null ?
    eventPropertiesArray.push({id: "PAXuVgcO0", name: "cm_option", value: properties.cmOption}) :
    eventPropertiesArray.push({id: "PAXuVgcO0", name: "cm_option", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "KNnHKBbfV", "6aaa3e8dcd441374e3a7153a867bc5fa71ea22e2f697d998749eb46416fb2764", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("summary_edit", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("KNnHKBbfV", "summary_edit", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("summary_edit", eventProperties, "KNnHKBbfV", "6aaa3e8dcd441374e3a7153a867bc5fa71ea22e2f697d998749eb46416fb2764");
    }
    // destination Segment
    Segment.logEvent("summary_edit", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface StyleOptionChangeProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  cmOption: string | null | undefined;
}
/**
 * style_option_change: Firing when the style change/edit is selected.

- cm_option:{{selected option}} example: business|casual|classic-double-breasted

- event_location: {{category}}_{{subcategory}} example: cm_suits_jacket
 * 
 * When to trigger this event:
 * 1. When the option to change the style is clicked.
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/RxyJeDhDH/trigger/18NhXj3B6
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * @param properties.cmOption: Describes which option was selected, and which outcome was chosen
Description is added in the event.
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/RxyJeDhDH}
 */
export function styleOptionChange(properties: StyleOptionChangeProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  properties.cmOption !== undefined && properties.cmOption !== null ?
    eventPropertiesArray.push({id: "PAXuVgcO0", name: "cm_option", value: properties.cmOption}) :
    eventPropertiesArray.push({id: "PAXuVgcO0", name: "cm_option", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "RxyJeDhDH", "a9cc77795d94253b5e21564f26b9f7bb3fcdbf33a4c686a1c4e7c0a006707e02", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("style_option_change", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("RxyJeDhDH", "style_option_change", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("style_option_change", eventProperties, "RxyJeDhDH", "a9cc77795d94253b5e21564f26b9f7bb3fcdbf33a4c686a1c4e7c0a006707e02");
    }
    // destination Segment
    Segment.logEvent("style_option_change", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface StyleOptionChangeConfirmProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  cmOption: string | null | undefined;
}
/**
 * style_option_change_confirm: Firing when the pop up 'change style' is clicked.

- cm_option: {{selected option}} example: business|casual|classic-double-breasted

- event_location: cm_{{category}}_{{subcategory}} example: cm_suits_jacket
 * 
 * When to trigger this event:
 * 1. When the option to change the style is confirmed.
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/EvgoQxQSE/trigger/R0LLZPLto
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * @param properties.cmOption: Describes which option was selected, and which outcome was chosen
Description is added in the event.
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/EvgoQxQSE}
 */
export function styleOptionChangeConfirm(properties: StyleOptionChangeConfirmProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  properties.cmOption !== undefined && properties.cmOption !== null ?
    eventPropertiesArray.push({id: "PAXuVgcO0", name: "cm_option", value: properties.cmOption}) :
    eventPropertiesArray.push({id: "PAXuVgcO0", name: "cm_option", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "EvgoQxQSE", "17856851f9a301c53640372c933fc67416e25d9d6e2a8acd6543b94bbf9a9fea", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("style_option_change_confirm", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("EvgoQxQSE", "style_option_change_confirm", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("style_option_change_confirm", eventProperties, "EvgoQxQSE", "17856851f9a301c53640372c933fc67416e25d9d6e2a8acd6543b94bbf9a9fea");
    }
    // destination Segment
    Segment.logEvent("style_option_change_confirm", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface StyleOptionChangeCancelProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  cmOption: string | null | undefined;
}
/**
 * style_option_change_cancel: Firing when the pop up 'change style' is cancelled.

- cm_option: {{selected option}} example: business|casual|classic-double-breasted

- event_location: cm_{{category}}_{{subcategory}} example: cm_suits_jacket
 * 
 * When to trigger this event:
 * 1. When the option to change the style is cancelled.
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/viJG3zwB_/trigger/cAfAKMRSv
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * @param properties.cmOption: Describes which option was selected, and which outcome was chosen
Description is added in the event.
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/viJG3zwB_}
 */
export function styleOptionChangeCancel(properties: StyleOptionChangeCancelProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  properties.cmOption !== undefined && properties.cmOption !== null ?
    eventPropertiesArray.push({id: "PAXuVgcO0", name: "cm_option", value: properties.cmOption}) :
    eventPropertiesArray.push({id: "PAXuVgcO0", name: "cm_option", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "viJG3zwB_", "9f086ad438c822d038925a5d8e8abfdc34c88e947d89a2ad44b0ac2015f4d7c7", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("style_option_change_cancel", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("viJG3zwB_", "style_option_change_cancel", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("style_option_change_cancel", eventProperties, "viJG3zwB_", "9f086ad438c822d038925a5d8e8abfdc34c88e947d89a2ad44b0ac2015f4d7c7");
    }
    // destination Segment
    Segment.logEvent("style_option_change_cancel", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface JacketOptionSelectProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  btcOption: string;
}
/**
 * jacket_option_select: When selecting a fabric (black-wool|black-velvet|navy-wool)

btc_option: black-wool|black-velvet|navy-wool
event_location: btc_suits_jacket|btc_suits_trousers|btc_suits_shirt
 * 
 * When to trigger this event:
 * 1. When selecting a fabric
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/EU5N_T3DG/trigger/N8UFDe0Os
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * @param properties.btcOption: no description
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/EU5N_T3DG}
 */
export function jacketOptionSelect(properties: JacketOptionSelectProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "bEtCdP6Ew", name: "btc_option", value: properties.btcOption});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "EU5N_T3DG", "62a4ddfe0c641d470cce142bfc332075055c2300ca5a8351f6043b8c1f24cc83", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("jacket_option_select", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("EU5N_T3DG", "jacket_option_select", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("jacket_option_select", eventProperties, "EU5N_T3DG", "62a4ddfe0c641d470cce142bfc332075055c2300ca5a8351f6043b8c1f24cc83");
    }
    // destination Segment
    Segment.logEvent("jacket_option_select", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ShirtOptionSelectProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  btcOption: string;
}
/**
 * shirt_option_select: When selecting a shirt (pleated-bib|hidden-placket|pique-bib)

btc_option: {{SKU}}
event_location: btc_suits_shirt
 * 
 * When to trigger this event:
 * 1. When selecting a shirt 
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/ppI--NzO5/trigger/Q4NPkIiob
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * @param properties.btcOption: no description
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/ppI--NzO5}
 */
export function shirtOptionSelect(properties: ShirtOptionSelectProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "bEtCdP6Ew", name: "btc_option", value: properties.btcOption});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "ppI--NzO5", "9df9474470dd7aa1e37f242368f9d7536f1dab22cd9268839a1fc7739cd6f135", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("shirt_option_select", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("ppI--NzO5", "shirt_option_select", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("shirt_option_select", eventProperties, "ppI--NzO5", "9df9474470dd7aa1e37f242368f9d7536f1dab22cd9268839a1fc7739cd6f135");
    }
    // destination Segment
    Segment.logEvent("shirt_option_select", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ShoesOptionSelectProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  btcOption: string;
}
/**
 * shoes_option_select: When selecting a shoe (leather-oxford|velvet-slip-on)

btc_option: {{SKU}}
event_location: btc_suits_shoes
 * 
 * When to trigger this event:
 * 1. When selecting a shoe style
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/dun37FtuD/trigger/RtTovP3s0
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * @param properties.btcOption: no description
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/dun37FtuD}
 */
export function shoesOptionSelect(properties: ShoesOptionSelectProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "bEtCdP6Ew", name: "btc_option", value: properties.btcOption});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "dun37FtuD", "7085842d4d1415e80bc9e5063d32e99670c0f8ae3be7166584e92af240b403be", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("shoes_option_select", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("dun37FtuD", "shoes_option_select", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("shoes_option_select", eventProperties, "dun37FtuD", "7085842d4d1415e80bc9e5063d32e99670c0f8ae3be7166584e92af240b403be");
    }
    // destination Segment
    Segment.logEvent("shoes_option_select", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface BowtieOptionSelectProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string | null | undefined;
  btcOption: string;
}
/**
 * bowtie_option_select: When selecting a bow-tie (black-self-tied)

When confirming a bowtie (black-self-tied)
btc_option isn't name in this case since a lot of bowties have the same name (for example Black Self-Tied, therefor we use the SKU in the btc_option

btc_option: {{SKU}}
event_location: btc_suits_bow-tie
 * 
 * When to trigger this event:
 * 1. When selecting a bowtie 
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/2yi_RMQ3b/trigger/BpZWZq_qj
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * @param properties.btcOption: no description
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/2yi_RMQ3b}
 */
export function bowtieOptionSelect(properties: BowtieOptionSelectProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  properties.eventLocation !== undefined && properties.eventLocation !== null ?
    eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation}) :
    eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: null});
  eventPropertiesArray.push({id: "bEtCdP6Ew", name: "btc_option", value: properties.btcOption});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "2yi_RMQ3b", "9abc26c4318c4f82ce9503b027bd854b8166b0deacfef3a7c129b1d035b60ca3", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("bowtie_option_select", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("2yi_RMQ3b", "bowtie_option_select", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("bowtie_option_select", eventProperties, "2yi_RMQ3b", "9abc26c4318c4f82ce9503b027bd854b8166b0deacfef3a7c129b1d035b60ca3");
    }
    // destination Segment
    Segment.logEvent("bowtie_option_select", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CufflinkOptionSelectProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  btcOption: string;
}
/**
 * cufflink_option_select: When selecting a cufflink

btc_option isn't the name in this case since a lot of cufflinks have the same name (for example Silver round or Gold round), therefor we use the SKU in the btc_option

btc_option: {{SKU}}
event_location: btc_suits_cufflinks
 * 
 * When to trigger this event:
 * 1. When selecting a cufflink 
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/RkRz_Hj16/trigger/zInO1Vapu
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * @param properties.btcOption: no description
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/RkRz_Hj16}
 */
export function cufflinkOptionSelect(properties: CufflinkOptionSelectProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "bEtCdP6Ew", name: "btc_option", value: properties.btcOption});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "RkRz_Hj16", "1f6af409d4801ae0c66a9983c250173ddcefd6c0dc527e2dcc168e973ba73202", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("cufflink_option_select", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("RkRz_Hj16", "cufflink_option_select", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("cufflink_option_select", eventProperties, "RkRz_Hj16", "1f6af409d4801ae0c66a9983c250173ddcefd6c0dc527e2dcc168e973ba73202");
    }
    // destination Segment
    Segment.logEvent("cufflink_option_select", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SuspendersOptionSelectProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  btcOption: string;
}
/**
 * suspenders_option_select: When selecting suspenders

btc_option: {{SKU}}
event_location: btc_suits_suspenders
 * 
 * When to trigger this event:
 * 1. When selecting suspenders 
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/DZOC-lBrn/trigger/fS2cf2hM1
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * @param properties.btcOption: no description
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/DZOC-lBrn}
 */
export function suspendersOptionSelect(properties: SuspendersOptionSelectProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "bEtCdP6Ew", name: "btc_option", value: properties.btcOption});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "DZOC-lBrn", "608ef8ba60bd98bf03997707e68e6fa3ebceedf532810827c345655e6e0a8115", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("suspenders_option_select", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("DZOC-lBrn", "suspenders_option_select", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("suspenders_option_select", eventProperties, "DZOC-lBrn", "608ef8ba60bd98bf03997707e68e6fa3ebceedf532810827c345655e6e0a8115");
    }
    // destination Segment
    Segment.logEvent("suspenders_option_select", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface BtcSummaryEditProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  btcOption: string;
}
/**
 * btc_summary_edit: When clicking 'edit' icon on summary screen

event_location: btc_summary_{{category} example: btc_summary_jacket
 * 
 * When to trigger this event:
 * 1. When clicking on 'edit' icon on summary screen
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/4RLb03h05/trigger/Fye-Jl3tu
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * @param properties.btcOption: no description
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/4RLb03h05}
 */
export function btcSummaryEdit(properties: BtcSummaryEditProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "bEtCdP6Ew", name: "btc_option", value: properties.btcOption});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "4RLb03h05", "cbc46b6cc9811664ab38b49869a7eee5d49a7f0fe3e978ef3dd0098f2dec10d1", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("btc_summary_edit", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("4RLb03h05", "btc_summary_edit", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("btc_summary_edit", eventProperties, "4RLb03h05", "cbc46b6cc9811664ab38b49869a7eee5d49a7f0fe3e978ef3dd0098f2dec10d1");
    }
    // destination Segment
    Segment.logEvent("btc_summary_edit", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface LapelOptionSelectProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  btcOption: string;
}
/**
 * lapel_option_select: When selecting a lapel on jacket 

btc_option: {{SKU}}
event_location: btc_suits_jacket_lapel
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * @param properties.btcOption: no description
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/o7jV0_VNo}
 */
export function lapelOptionSelect(properties: LapelOptionSelectProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "bEtCdP6Ew", name: "btc_option", value: properties.btcOption});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "o7jV0_VNo", "5d71aa382f951cd12abb87904808e04e4e013c234e8ead3185402c7d9a6d76df", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("lapel_option_select", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("o7jV0_VNo", "lapel_option_select", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("lapel_option_select", eventProperties, "o7jV0_VNo", "5d71aa382f951cd12abb87904808e04e4e013c234e8ead3185402c7d9a6d76df");
    }
    // destination Segment
    Segment.logEvent("lapel_option_select", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PreviousStepProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * previous_step: when clicking on previous arrow in BTC configurator
event_category: btc_interactions
event_location: btc_{{category}}_{{subcategory}} example: btc_suits_jacket_lapel
 * 
 * When to trigger this event:
 * 1. 
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/nIjDUsmcwn/trigger/ySRM6FVek
 * 2. 
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/nIjDUsmcwn/trigger/Cq9EZLjcQ
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/nIjDUsmcwn}
 */
export function previousStep(properties: PreviousStepProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "nIjDUsmcwn", "4385bb644763fe13501909b4865a2ff2bcad03ec1eb6095dc6fe751b3db498db", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("previous_step", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("nIjDUsmcwn", "previous_step", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("previous_step", eventProperties, "nIjDUsmcwn", "4385bb644763fe13501909b4865a2ff2bcad03ec1eb6095dc6fe751b3db498db");
    }
    // destination Segment
    Segment.logEvent("previous_step", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface NextStepProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * next_step: when clicking on next arrow in BTC configurator
event_category: btc_interactions
event_location: btc_{{category}}_{{subcategory}} example: btc_suits_jacket_lapel
 * 
 * When to trigger this event:
 * 1. 
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/7pxnr-tZL/trigger/y1wt8zRXy
 * 2. 
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/7pxnr-tZL/trigger/5OS5ilICD
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/7pxnr-tZL}
 */
export function nextStep(properties: NextStepProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "7pxnr-tZL", "b97d8b97f3323e44f1166d2bf09c92e877571853cd68c4344f9001bef1a21e91", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("next_step", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("7pxnr-tZL", "next_step", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("next_step", eventProperties, "7pxnr-tZL", "b97d8b97f3323e44f1166d2bf09c92e877571853cd68c4344f9001bef1a21e91");
    }
    // destination Segment
    Segment.logEvent("next_step", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SizeGuideClickProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  btcOption: string;
}
/**
 * size_guide_click: When clicking on the 'size guide' button

btc_option: {{SKU}}
event_location: example: btc_suits_shirt
 * 
 * When to trigger this event:
 * 1. When clicking 'Size guide' button
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/-AnVCYt0T/trigger/xC8kgIxIk
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * @param properties.btcOption: no description
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/-AnVCYt0T}
 */
export function sizeGuideClick(properties: SizeGuideClickProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "bEtCdP6Ew", name: "btc_option", value: properties.btcOption});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "-AnVCYt0T", "5696a67dd85c2c402439fab2d7d4f2e66accf936c3a99ecde7c02e06940aedb1", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("size_guide_click", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("-AnVCYt0T", "size_guide_click", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("size_guide_click", eventProperties, "-AnVCYt0T", "5696a67dd85c2c402439fab2d7d4f2e66accf936c3a99ecde7c02e06940aedb1");
    }
    // destination Segment
    Segment.logEvent("size_guide_click", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SizeGuideClickSimilarProductsProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  btcOption: string;
}
/**
 * size_guide_click: When clicking on the 'size guide' button

btc_option: {{SKU}}
event_location: example: btc_suits_shirt, event variant
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * @param properties.btcOption: no description
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/-AnVCYt0T.eMbOY6NgQ}
 */
export function sizeGuideClickSimilarProducts(properties: SizeGuideClickSimilarProductsProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "bEtCdP6Ew", name: "btc_option", value: properties.btcOption});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "-AnVCYt0T.eMbOY6NgQ", "3242654bcbf3d6dfccdb5bb915a8d4044bfb9752ca22d3d286202ea9e3b6e671", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("size_guide_click", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("-AnVCYt0T.eMbOY6NgQ", "size_guide_click", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("size_guide_click", eventProperties, "-AnVCYt0T.eMbOY6NgQ", "3242654bcbf3d6dfccdb5bb915a8d4044bfb9752ca22d3d286202ea9e3b6e671");
    }
    // destination Segment
    Segment.logEvent("size_guide_click", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SizeConfirmProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  btcOption: string;
}
/**
 * size_confirm: When clicking on the 'confirm' button

btc_option: {{SKU}}
event_location: example: btc_suits_shirt
 * 
 * When to trigger this event:
 * 1. When clicking 'confirm' button
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/y80nFRNre/trigger/YUSb5ZlpJ
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * @param properties.btcOption: no description
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/y80nFRNre}
 */
export function sizeConfirm(properties: SizeConfirmProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "bEtCdP6Ew", name: "btc_option", value: properties.btcOption});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "y80nFRNre", "d1abcbfb1a2b2aef04ad28a5b98d4f3ccbfbdeb7d10bdf83eb97ecd41643a0aa", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("size_confirm", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("y80nFRNre", "size_confirm", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("size_confirm", eventProperties, "y80nFRNre", "d1abcbfb1a2b2aef04ad28a5b98d4f3ccbfbdeb7d10bdf83eb97ecd41643a0aa");
    }
    // destination Segment
    Segment.logEvent("size_confirm", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface TrousersOptionSelectProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  btcOption: string;
}
/**
 * trousers_option_select: When selecting a fabric (black-wool|black-velvet|navy-wool)

btc_option: black-wool|black-velvet|navy-wool
event_location: btc_suits_jacket|btc_suits_trousers|btc_suits_shirt
 * 
 * When to trigger this event:
 * 1. When selecting a fabric
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/CqpdLZEyR/trigger/yzkz_8B1U0
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * @param properties.btcOption: no description
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/CqpdLZEyR}
 */
export function trousersOptionSelect(properties: TrousersOptionSelectProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "bEtCdP6Ew", name: "btc_option", value: properties.btcOption});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "CqpdLZEyR", "25fc64cfb650343173c71fa7e967c9b1e3c849299a751be9965da6de84854bd8", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("trousers_option_select", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("CqpdLZEyR", "trousers_option_select", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("trousers_option_select", eventProperties, "CqpdLZEyR", "25fc64cfb650343173c71fa7e967c9b1e3c849299a751be9965da6de84854bd8");
    }
    // destination Segment
    Segment.logEvent("trousers_option_select", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface WaistcoatOptionSelectProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  btcOption: string;
}
/**
 * waistcoat_option_select: When selecting a fabric (black-wool|black-velvet|navy-wool)

btc_option: without_waistcoat|add_waistcoat
event_location: btc_suits_waistcoat
 * 
 * When to trigger this event:
 * 1. When selecting a waistcoat
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/vYvR050eC/trigger/n2_XXZLKL4
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * @param properties.btcOption: no description
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/vYvR050eC}
 */
export function waistcoatOptionSelect(properties: WaistcoatOptionSelectProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "bEtCdP6Ew", name: "btc_option", value: properties.btcOption});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "vYvR050eC", "20ef1c996c10cd9eaa86ebc4f0ff22b614280e1c9610ba928d6292ca03fc8671", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("waistcoat_option_select", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("vYvR050eC", "waistcoat_option_select", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("waistcoat_option_select", eventProperties, "vYvR050eC", "20ef1c996c10cd9eaa86ebc4f0ff22b614280e1c9610ba928d6292ca03fc8671");
    }
    // destination Segment
    Segment.logEvent("waistcoat_option_select", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SliderNextProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * slider_next: When clicking on 'next' on the slider

event_location: category-card_{{category}}
event_location: 
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/43HCIUZACc}
 */
export function sliderNext(properties: SliderNextProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "43HCIUZACc", "eb59d8b3399f6a4cbacffad3c07c1d056036fcf3dabe3e447255d6ba30a209fb", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("slider_next", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("43HCIUZACc", "slider_next", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("slider_next", eventProperties, "43HCIUZACc", "eb59d8b3399f6a4cbacffad3c07c1d056036fcf3dabe3e447255d6ba30a209fb");
    }
    // destination Segment
    Segment.logEvent("slider_next", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface OptionSelectedProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * option_selected: This event is fired when the user clicks on an option within a step in the configurator so we can see how many times the user change the options before confirming to the next step. 
option: {{SKU}}
event_location: btc_suits_bow-tie {{to be confirmed}}


-----
btc_option isn't name in this case since a lot of bowties have the same name (for example Black Self-Tied, therefor we use the SKU in the btc_option

 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/vppBK1T0M_I}
 */
export function optionSelected(properties: OptionSelectedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "vppBK1T0M_I", "c46e73fe4e3e791e94cf177cfb1eda2e435ad1e945f2b1147b0dd5c44a0c0358", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("option_selected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("vppBK1T0M_I", "option_selected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("option_selected", eventProperties, "vppBK1T0M_I", "c46e73fe4e3e791e94cf177cfb1eda2e435ad1e945f2b1147b0dd5c44a0c0358");
    }
    // destination Segment
    Segment.logEvent("option_selected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface OptionSelectedBtcProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  option: string;
  sku: string;
}
/**
 * option_selected: This event is fired when the user clicks on an option within a step in the configurator so we can see how many times the user change the options before confirming to the next step. 
option: {{SKU}}
event_location: btc_suits_bow-tie {{to be confirmed}}


-----
btc_option isn't name in this case since a lot of bowties have the same name (for example Black Self-Tied, therefor we use the SKU in the btc_option, variant description: This event is fired when the user clicks on an option within a step in the configurator so we can see how many times the user change the options before confirming to the next step. option: {{SKU}} event_location: btc_suits_bow-tie {{to be confirmed}} ----- btc_option isn't name in this case since a lot of bowties have the same name (for example Black Self-Tied, therefor we use the SKU in the btc_option
 * 
 * When to trigger this event:
 * 1. When the user clicks in one of the option within a step
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/vppBK1T0M_I.EncCALhG8/trigger/sVVlgA2qVRw
 * 2. When the user selects an option within the step
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/vppBK1T0M_I.EncCALhG8/trigger/HKWMVmK6m8K
 * 3. When the user selects an option within the step
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/vppBK1T0M_I.EncCALhG8/trigger/vET8iJLpeU
 * 4. When the user selects an option within the step
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/vppBK1T0M_I.EncCALhG8/trigger/dVPACtEd0
 * 5. When the user selects an option within the step
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/vppBK1T0M_I.EncCALhG8/trigger/s_16XADUm
 * 6. When the user selects an option within the step
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/vppBK1T0M_I.EncCALhG8/trigger/fvw4P-BbJ
 * 7. When the user selects an option within the step
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/vppBK1T0M_I.EncCALhG8/trigger/wb8ft9dBA
 * 8. When the user selects an option within the step
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/vppBK1T0M_I.EncCALhG8/trigger/cVs9t2flF
 * 9. When the user selects an option within the step
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/vppBK1T0M_I.EncCALhG8/trigger/0DhEOcUxI
 * 10. When the user selects an option within the step
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/vppBK1T0M_I.EncCALhG8/trigger/MnK9yn87s
 * 11. When the user selects an option within the step
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/vppBK1T0M_I.EncCALhG8/trigger/0NQOcdYKD
 * 12. When the user selects an option within the step
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/vppBK1T0M_I.EncCALhG8/trigger/ES5PVCJRA
 * 13. When the user selects an option within the step
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/vppBK1T0M_I.EncCALhG8/trigger/VOiFvJC9U
 * 14. When the user selects an option within the step
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/vppBK1T0M_I.EncCALhG8/trigger/foJRkY2fN
 * 15. When the user selects an option within the step
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/vppBK1T0M_I.EncCALhG8/trigger/8thrDztx9
 * 16. When the user selects an option within the step
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/vppBK1T0M_I.EncCALhG8/trigger/XtjhaL_eZjj
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel
, should match regex: configurator_(jacket|trousers|waistcoat|shirt|shoes|bow_tie|cufflinks|suspenders)
 * @param properties.option: This property describes the option chosen by the user when using a configurator tool.
 * @param properties.sku: SKU of product, same as product_id
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/vppBK1T0M_I.EncCALhG8}
 */
export function optionSelectedBtc(properties: OptionSelectedBtcProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "0p2gAH62X", name: "option", value: properties.option});
  eventPropertiesArray.push({id: "Yf36kEtqN", name: "sku", value: properties.sku});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "vppBK1T0M_I.EncCALhG8", "6a66c8df121e999f56c04402399e1aa93292365a87db12776b9ef631d82ce41e", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("option_selected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("vppBK1T0M_I.EncCALhG8", "option_selected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("option_selected", eventProperties, "vppBK1T0M_I.EncCALhG8", "6a66c8df121e999f56c04402399e1aa93292365a87db12776b9ef631d82ce41e");
    }
    // destination Segment
    Segment.logEvent("option_selected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface OptionConfirmedProperties {
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
}
/**
 * option_confirmed: This event is triggered when the user clicks the "Next" button within a step of the configurator, allowing us to track which item the user selected.
 * 
 * @param properties the properties associatied with this event
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/s6fGh_GONGm}
 */
export function optionConfirmed(properties: OptionConfirmedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "s6fGh_GONGm", "8c4c3edf5df345163a766ef29ab001cd062ab24288f4cd702f120a901546e4e1", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("option_confirmed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("s6fGh_GONGm", "option_confirmed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("option_confirmed", eventProperties, "s6fGh_GONGm", "8c4c3edf5df345163a766ef29ab001cd062ab24288f4cd702f120a901546e4e1");
    }
    // destination Segment
    Segment.logEvent("option_confirmed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface OptionConfirmedBtcProperties {
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  option: string;
  sku: string;
}
/**
 * option_confirmed: This event is triggered when the user clicks the "Next" button within a step of the configurator, allowing us to track which item the user selected., event variant
 * 
 * When to trigger this event:
 * 1. This event represents user interactions with the options displayed in the configurator. It is utilized to monitor which option the user has selected
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/s6fGh_GONGm.I9Ip6pAwO/trigger/N4It_Notb6b
 * 2. This event represents user interactions with the options displayed in the configurator. It is utilized to monitor which option the user has selected
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/s6fGh_GONGm.I9Ip6pAwO/trigger/OPvnf1lMF
 * 
 * @param properties the properties associatied with this event
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel
, should match regex: configurator_(jacket|trousers|waistcoat|shirt|shoes|bow_tie|cufflinks|suspenders)
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.option: This property describes the option chosen by the user when using a configurator tool.
 * @param properties.sku: SKU of product, same as product_id
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/s6fGh_GONGm.I9Ip6pAwO}
 */
export function optionConfirmedBtc(properties: OptionConfirmedBtcProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "0p2gAH62X", name: "option", value: properties.option});
  eventPropertiesArray.push({id: "Yf36kEtqN", name: "sku", value: properties.sku});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "s6fGh_GONGm.I9Ip6pAwO", "fb0d28b38c162666f35f96e298d89ee12adaecc514d2e6bc847132e5e6f8932a", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("option_confirmed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("s6fGh_GONGm.I9Ip6pAwO", "option_confirmed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("option_confirmed", eventProperties, "s6fGh_GONGm.I9Ip6pAwO", "fb0d28b38c162666f35f96e298d89ee12adaecc514d2e6bc847132e5e6f8932a");
    }
    // destination Segment
    Segment.logEvent("option_confirmed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface NextStepClickedProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * next_step_clicked: When clicking on the next arrow in the configurator
event_category: interactions
event_location: {{category}}_{{subcategory}} example: suits_jacket_lapel
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/gDHTCF7LbHx}
 */
export function nextStepClicked(properties: NextStepClickedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "gDHTCF7LbHx", "77053370df9ce16b94a6dee03698cf64d493ff4bbe2d4b1e4559b9097cacf4ee", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("next_step_clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("gDHTCF7LbHx", "next_step_clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("next_step_clicked", eventProperties, "gDHTCF7LbHx", "77053370df9ce16b94a6dee03698cf64d493ff4bbe2d4b1e4559b9097cacf4ee");
    }
    // destination Segment
    Segment.logEvent("next_step_clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface NextStepClickedBtcProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  option: string;
  sku: string;
}
/**
 * next_step_clicked: When clicking on the next arrow in the configurator
event_category: interactions
event_location: {{category}}_{{subcategory}} example: suits_jacket_lapel, event variant
 * 
 * When to trigger this event:
 * 1. When the user clicks in the "Next" button
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/gDHTCF7LbHx.Cc4dWMqEQ/trigger/5BMLFdHOH_0
 * 2. When the user clicks in the arrow button to go to the next step
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/gDHTCF7LbHx.Cc4dWMqEQ/trigger/eTA-ARz9m
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel
, should match regex: configurator_(jacket|trousers|waistcoat|shirt|shoes|bow_tie|cufflinks|suspenders)
 * @param properties.option: This property describes the option chosen by the user when using a configurator tool.
 * @param properties.sku: SKU of product, same as product_id
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/gDHTCF7LbHx.Cc4dWMqEQ}
 */
export function nextStepClickedBtc(properties: NextStepClickedBtcProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "0p2gAH62X", name: "option", value: properties.option});
  eventPropertiesArray.push({id: "Yf36kEtqN", name: "sku", value: properties.sku});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "gDHTCF7LbHx.Cc4dWMqEQ", "8ab9243442d79f77dde94ececfbbcec34a718b0b3dfda4bea23adb46724caeca", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("next_step_clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("gDHTCF7LbHx.Cc4dWMqEQ", "next_step_clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("next_step_clicked", eventProperties, "gDHTCF7LbHx.Cc4dWMqEQ", "8ab9243442d79f77dde94ececfbbcec34a718b0b3dfda4bea23adb46724caeca");
    }
    // destination Segment
    Segment.logEvent("next_step_clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface FinalStepClickedProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * final_step_clicked: This event represents user interactions with the options displayed in the configurator. It is utilized to monitor the frequency with which users switch between options within the same step. This one, in particular, represents the last step in a configurator. More details can be found in the variant's description.


event_category: interactions
event_location: {{category}}_{{subcategory}} example: suits_jacket_lapel
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/A1DO1tM0xmX}
 */
export function finalStepClicked(properties: FinalStepClickedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "A1DO1tM0xmX", "ce0feb548c635f6cc3e099a5e4d6e3deedc078d57674bdfc9643cc4d4aa1a54e", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("final_step_clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("A1DO1tM0xmX", "final_step_clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("final_step_clicked", eventProperties, "A1DO1tM0xmX", "ce0feb548c635f6cc3e099a5e4d6e3deedc078d57674bdfc9643cc4d4aa1a54e");
    }
    // destination Segment
    Segment.logEvent("final_step_clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface FinalStepClickedBtcProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  option: string;
  sku: string;
}
/**
 * final_step_clicked: This event represents user interactions with the options displayed in the configurator. It is utilized to monitor the frequency with which users switch between options within the same step. This one, in particular, represents the last step in a configurator. More details can be found in the variant's description.


event_category: interactions
event_location: {{category}}_{{subcategory}} example: suits_jacket_lapel, variant description: This event represents user interactions with the options displayed in the configurator. It is utilized to monitor the frequency with which users switch between options within the same step. This event represents the "Finish Look" button click in the Black Tie Configurator.
 * 
 * When to trigger this event:
 * 1. When the user clicks in the "Finish look" button
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/A1DO1tM0xmX.8V9k9z2QC/trigger/RuoLa533hp_
 * 2. When the user clicks in the button to finish the look
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/A1DO1tM0xmX.8V9k9z2QC/trigger/T5-70xZ6N
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel
, should match regex: configurator_(jacket|trousers|waistcoat|shirt|shoes|bow_tie|cufflinks|suspenders)
 * @param properties.option: This property describes the option chosen by the user when using a configurator tool.
 * @param properties.sku: SKU of product, same as product_id
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/A1DO1tM0xmX.8V9k9z2QC}
 */
export function finalStepClickedBtc(properties: FinalStepClickedBtcProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "0p2gAH62X", name: "option", value: properties.option});
  eventPropertiesArray.push({id: "Yf36kEtqN", name: "sku", value: properties.sku});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "A1DO1tM0xmX.8V9k9z2QC", "0d641f64d70d1ab171986100f3194ddeb583d3689971cde2b8d50cec84a3c82b", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("final_step_clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("A1DO1tM0xmX.8V9k9z2QC", "final_step_clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("final_step_clicked", eventProperties, "A1DO1tM0xmX.8V9k9z2QC", "0d641f64d70d1ab171986100f3194ddeb583d3689971cde2b8d50cec84a3c82b");
    }
    // destination Segment
    Segment.logEvent("final_step_clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SummaryEditClickedProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * summary_edit_clicked: When clicking on 'edit' icon on the summary screen 

- event_location: {{category}}_summary -> example cm_suits_summary
- option: jacket|trousers|waistcoat

 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/_fPvWfx3Sl8}
 */
export function summaryEditClicked(properties: SummaryEditClickedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "_fPvWfx3Sl8", "bc2298dd4b9f629286c9c8df5080f376c973cfd93fbd8f5bf0ca5bb3acce67b0", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("summary_edit_clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("_fPvWfx3Sl8", "summary_edit_clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("summary_edit_clicked", eventProperties, "_fPvWfx3Sl8", "bc2298dd4b9f629286c9c8df5080f376c973cfd93fbd8f5bf0ca5bb3acce67b0");
    }
    // destination Segment
    Segment.logEvent("summary_edit_clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SummaryEditClickedBtcProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  option: string;
  sku: string;
}
/**
 * summary_edit_clicked: When clicking on 'edit' icon on the summary screen 

- event_location: {{category}}_summary -> example cm_suits_summary
- option: jacket|trousers|waistcoat, event variant
 * 
 * When to trigger this event:
 * 1. When clicking 'Edit' button on summary screen
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/_fPvWfx3Sl8.MAF3QYL-c/trigger/6nVk_EuQUyw
 * 2. When clicking 'Edit' button on summary screen
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/_fPvWfx3Sl8.MAF3QYL-c/trigger/lAxU9FPe7
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel
, should match regex: configurator_(jacket|trousers|waistcoat|shirt|shoes|bow_tie|cufflinks|suspenders)
 * @param properties.option: This property describes the option chosen by the user when using a configurator tool.
 * @param properties.sku: SKU of product, same as product_id
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/_fPvWfx3Sl8.MAF3QYL-c}
 */
export function summaryEditClickedBtc(properties: SummaryEditClickedBtcProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "0p2gAH62X", name: "option", value: properties.option});
  eventPropertiesArray.push({id: "Yf36kEtqN", name: "sku", value: properties.sku});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "_fPvWfx3Sl8.MAF3QYL-c", "f863cddbb220b7e8cf8b8fdcaa0de17696f3de3e259b620419d5f9359c8a6fed", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("summary_edit_clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("_fPvWfx3Sl8.MAF3QYL-c", "summary_edit_clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("summary_edit_clicked", eventProperties, "_fPvWfx3Sl8.MAF3QYL-c", "f863cddbb220b7e8cf8b8fdcaa0de17696f3de3e259b620419d5f9359c8a6fed");
    }
    // destination Segment
    Segment.logEvent("summary_edit_clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SelectSizeClickedProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * select_size_clicked: When the user clicks in a change in the Select Size popup

 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/QjaFGpR033V}
 */
export function selectSizeClicked(properties: SelectSizeClickedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "QjaFGpR033V", "881bf30becd1ac2901bf5207e22bf299c5ba3055780b3fd31df3bf7e582d0ffa", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("select_size_clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("QjaFGpR033V", "select_size_clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("select_size_clicked", eventProperties, "QjaFGpR033V", "881bf30becd1ac2901bf5207e22bf299c5ba3055780b3fd31df3bf7e582d0ffa");
    }
    // destination Segment
    Segment.logEvent("select_size_clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SelectSizeClickedBtcProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  option: string;
  sku: string;
}
/**
 * select_size_clicked: When the user clicks in a change in the Select Size popup, event variant
 * 
 * When to trigger this event:
 * 1. When the user clicks in the "Select size" button in the summary page
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/QjaFGpR033V.iyCfhkoLP/trigger/p9apvu7FzT7
 * 2. When the user clicks in the "Select size" button in the summary page
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/QjaFGpR033V.iyCfhkoLP/trigger/D83kK-Oj0
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel
, should match regex: configurator_summary
 * @param properties.option: This property describes the option chosen by the user when using a configurator tool.
 * @param properties.sku: SKU of product, same as product_id
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/QjaFGpR033V.iyCfhkoLP}
 */
export function selectSizeClickedBtc(properties: SelectSizeClickedBtcProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "0p2gAH62X", name: "option", value: properties.option});
  eventPropertiesArray.push({id: "Yf36kEtqN", name: "sku", value: properties.sku});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "QjaFGpR033V.iyCfhkoLP", "9adc51042b132ae546aa286fe1465f76c0e36bdeb150ba3d8794eadd353a07ba", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("select_size_clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("QjaFGpR033V.iyCfhkoLP", "select_size_clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("select_size_clicked", eventProperties, "QjaFGpR033V.iyCfhkoLP", "9adc51042b132ae546aa286fe1465f76c0e36bdeb150ba3d8794eadd353a07ba");
    }
    // destination Segment
    Segment.logEvent("select_size_clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SizeConfirmedProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * size_confirmed: When clicking on the 'confirm' button

option: {{SKU}}
event_location: example: btc_suits_shirt
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/j8BUIWlv2v}
 */
export function sizeConfirmed(properties: SizeConfirmedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "j8BUIWlv2v", "95ab8ed1c121cda8cc49c926587d919f0a18f23e62d83d79f53370179ee1cf27", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("size_confirmed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("j8BUIWlv2v", "size_confirmed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("size_confirmed", eventProperties, "j8BUIWlv2v", "95ab8ed1c121cda8cc49c926587d919f0a18f23e62d83d79f53370179ee1cf27");
    }
    // destination Segment
    Segment.logEvent("size_confirmed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SizeConfirmedBtcProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  option: string;
  sku: string;
}
/**
 * size_confirmed: When clicking on the 'confirm' button

option: {{SKU}}
event_location: example: btc_suits_shirt, event variant
 * 
 * When to trigger this event:
 * 1. When the user clicks in the "Select size" button in the summary screen
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/j8BUIWlv2v._hAElxFpF/trigger/vfxF-tu1h4w
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel
, should match regex: pop\-up
 * @param properties.option: This property describes the option chosen by the user when using a configurator tool.
 * @param properties.sku: SKU of product, same as product_id
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/j8BUIWlv2v._hAElxFpF}
 */
export function sizeConfirmedBtc(properties: SizeConfirmedBtcProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "0p2gAH62X", name: "option", value: properties.option});
  eventPropertiesArray.push({id: "Yf36kEtqN", name: "sku", value: properties.sku});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "j8BUIWlv2v._hAElxFpF", "f91c69d1adedbbc27407e3c0ff4ac310a080f5e117339e014139b1255f8b356a", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("size_confirmed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("j8BUIWlv2v._hAElxFpF", "size_confirmed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("size_confirmed", eventProperties, "j8BUIWlv2v._hAElxFpF", "f91c69d1adedbbc27407e3c0ff4ac310a080f5e117339e014139b1255f8b356a");
    }
    // destination Segment
    Segment.logEvent("size_confirmed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PreviousStepClickedProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * previous_step_clicked: When clicking on the next arrow in the configurator
event_category: interactions
event_location: {{category}}_{{subcategory}} example: suits_jacket_lapel
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel

 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/eFhhXZXkYhp}
 */
export function previousStepClicked(properties: PreviousStepClickedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "eFhhXZXkYhp", "06c225be97b5431cd5b59eae86d5d706ef006a1da9af83f5f29374287d2dded5", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("previous_step_clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("eFhhXZXkYhp", "previous_step_clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("previous_step_clicked", eventProperties, "eFhhXZXkYhp", "06c225be97b5431cd5b59eae86d5d706ef006a1da9af83f5f29374287d2dded5");
    }
    // destination Segment
    Segment.logEvent("previous_step_clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PreviousStepClickedBtcProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string | null | undefined;
  gaSessionId: string | null | undefined;
  gaSessionNumber: string | null | undefined;
  fbFbp: string | null | undefined;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  option: string;
  sku: string;
}
/**
 * previous_step_clicked: When clicking on the next arrow in the configurator
event_category: interactions
event_location: {{category}}_{{subcategory}} example: suits_jacket_lapel, event variant
 * 
 * When to trigger this event:
 * 1. When the user clicks in the "Previous" button
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/eFhhXZXkYhp.Zf7UB1hBE/trigger/bKxYQul8PKh
 * 2. When the user clciks in the "Previous" icon 
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/eFhhXZXkYhp.Zf7UB1hBE/trigger/x1FjlGZEj
 * 
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID (account number)
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type 
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and 

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For out-of-stock panel: out_of_stock_panel
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
- For LB: lookbuilder_summary
- For Similar Products: similar_products_panel
, should match regex: configurator_(jacket|trousers|waistcoat|shirt|shoes|bow_tie|cufflinks|suspenders)
 * @param properties.option: This property describes the option chosen by the user when using a configurator tool.
 * @param properties.sku: SKU of product, same as product_id
 * 
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/c7EFmUT2v/events/eFhhXZXkYhp.Zf7UB1hBE}
 */
export function previousStepClickedBtc(properties: PreviousStepClickedBtcProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  properties.gaClientId !== undefined && properties.gaClientId !== null ?
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId}) :
    eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: null});
  properties.gaSessionId !== undefined && properties.gaSessionId !== null ?
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId}) :
    eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: null});
  properties.gaSessionNumber !== undefined && properties.gaSessionNumber !== null ?
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber}) :
    eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: null});
  properties.fbFbp !== undefined && properties.fbFbp !== null ?
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp}) :
    eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: null});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "0p2gAH62X", name: "option", value: properties.option});
  eventPropertiesArray.push({id: "Yf36kEtqN", name: "sku", value: properties.sku});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "eFhhXZXkYhp.Zf7UB1hBE", "99945bb99a86a12b62d5aa582fc2da96e9361b02e25da46af2575eb5588b2eba", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("previous_step_clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("eFhhXZXkYhp.Zf7UB1hBE", "previous_step_clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("previous_step_clicked", eventProperties, "eFhhXZXkYhp.Zf7UB1hBE", "99945bb99a86a12b62d5aa582fc2da96e9361b02e25da46af2575eb5588b2eba");
    }
    // destination Segment
    Segment.logEvent("previous_step_clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export default {
  AvoEnv,
  initAvo,
  avoInspectorApiKey,
  MixAndMatch,
  Language,
  LoggedIn,
  EventCategory,
  Country,
  Affiliation,
  Brand,
  OutOfStock,
  PageType,
  Currency,
  Creative,
  productListViewed,
  productListViewedSimilarProducts,
  productClicked,
  productClickedSimilarProducts,
  productViewed,
  productAdded,
  productAddedCm,
  pageLoaded,
  pageLoadedVirtualPageView,
  promotionViewed,
  promotionClicked,
  productAddedToWishlist,
  productAddedToWishlistOutOfStock,
  selectSize,
  selectSizeSimilarProducts,
  sizeChanged,
  sizeChangedSimilarProducts,
  sizeChangedBtc,
  changeCountry,
  changeLanguage,
  headerAccountClick,
  headerLogoClick,
  headerMinicartClick,
  headerWishlistClick,
  menuOpened,
  menuClicked,
  footerClick,
  newsletterSubscribe,
  fabricOptionSelect,
  fabricOptionConfirm,
  styleOptionSelect,
  styleOptionSelectBtc,
  styleOptionSelectCm,
  styleOptionConfirm,
  summaryEdit,
  styleOptionChange,
  styleOptionChangeConfirm,
  styleOptionChangeCancel,
  jacketOptionSelect,
  shirtOptionSelect,
  shoesOptionSelect,
  bowtieOptionSelect,
  cufflinkOptionSelect,
  suspendersOptionSelect,
  btcSummaryEdit,
  lapelOptionSelect,
  previousStep,
  nextStep,
  sizeGuideClick,
  sizeGuideClickSimilarProducts,
  sizeConfirm,
  trousersOptionSelect,
  waistcoatOptionSelect,
  sliderNext,
  optionSelected,
  optionSelectedBtc,
  optionConfirmed,
  optionConfirmedBtc,
  nextStepClicked,
  nextStepClickedBtc,
  finalStepClicked,
  finalStepClickedBtc,
  summaryEditClicked,
  summaryEditClickedBtc,
  selectSizeClicked,
  selectSizeClickedBtc,
  sizeConfirmed,
  sizeConfirmedBtc,
  previousStepClicked,
  previousStepClickedBtc,
}

// AVOMODULEMAP:"Avo"
// AVOEVENTMAP:["productListViewed","productListViewedSimilarProducts","productClicked","productClickedSimilarProducts","productViewed","productAdded","productAddedCm","pageLoaded","pageLoadedVirtualPageView","promotionViewed","promotionClicked","productAddedToWishlist","productAddedToWishlistOutOfStock","selectSize","selectSizeSimilarProducts","sizeChanged","sizeChangedSimilarProducts","sizeChangedBtc","changeCountry","changeLanguage","headerAccountClick","headerLogoClick","headerMinicartClick","headerWishlistClick","menuOpened","menuClicked","footerClick","newsletterSubscribe","fabricOptionSelect","fabricOptionConfirm","styleOptionSelect","styleOptionSelectBtc","styleOptionSelectCm","styleOptionConfirm","summaryEdit","styleOptionChange","styleOptionChangeConfirm","styleOptionChangeCancel","jacketOptionSelect","shirtOptionSelect","shoesOptionSelect","bowtieOptionSelect","cufflinkOptionSelect","suspendersOptionSelect","btcSummaryEdit","lapelOptionSelect","previousStep","nextStep","sizeGuideClick","sizeGuideClickSimilarProducts","sizeConfirm","trousersOptionSelect","waistcoatOptionSelect","sliderNext","optionSelected","optionSelectedBtc","optionConfirmed","optionConfirmedBtc","nextStepClicked","nextStepClickedBtc","finalStepClicked","finalStepClickedBtc","summaryEditClicked","summaryEditClickedBtc","selectSizeClicked","selectSizeClickedBtc","sizeConfirmed","sizeConfirmedBtc","previousStepClicked","previousStepClickedBtc"]
