import { generateImageSourcesForCloudinary } from '@susu/headless-commerce/components/ResponsiveImage/utils'
import type { MediaWrapperV2 } from '@susu/headless-commerce/gql/generated/graphql'
import type { Maybe } from 'graphql/jsutils/Maybe'

export type ImageData = {
  carouselImage: Maybe<MediaWrapperV2>
  imageCount: number
}

export const getImageData = ({ carouselImage, imageCount }: ImageData) => {
  const linkSizes = {
    xs: '100vw',
    md: '100vw',
    lg: imageCount === 2 ? '50vw' : '100vw',
  }

  const {
    cloudinaryMobileImage,
    cloudinaryTabletImage,
    cloudinaryDesktopImage,
    lazyloading,
    altText,
  } = carouselImage as MediaWrapperV2

  const images = {
    cloudinaryMobileImage,
    cloudinaryTabletImage,
    cloudinaryDesktopImage,
  }

  const {
    desktopSrcSet,
    mobileSrcSet,
    tabletSrcSet,
    desktopSizes,
    mobileSizes,
    tabletSizes,
    smallestUrl,
  } = generateImageSourcesForCloudinary(images, linkSizes)

  return {
    altText,
    lazyloading,
    desktopSrcSet,
    mobileSrcSet,
    tabletSrcSet,
    desktopSizes,
    mobileSizes,
    tabletSizes,
    smallestUrl,
  }
}
