/**
 * Decodes HTML entities in a string
 * @param label - string to decode
 * @returns decoded string
 */
export const decodeHTML = (label: string | undefined) => {
  if (!label) {
    return ''
  }
  const parser = new DOMParser()
  const doc = parser.parseFromString(label, 'text/html')
  return doc.body.textContent || ''
}
