import type { ProductTrackingData } from '@susu/headless-commerce/components/ProductCard/ClientProductCardImageLink'
import { cart } from '@susu/headless-commerce/contexts/cart'
import { CMSHeaderType } from '@susu/headless-commerce/contexts/header/types'
import {
  getLocationIdValue,
  getURLTrackingValue,
} from '@susu/headless-commerce/features/configurator/utils/getURLTrackingValue'
import type { Product } from '@susu/headless-commerce/gql/generated/graphql'
import type {
  AffiliationValueType,
  CountryValueType,
  CreativeValueType,
  CurrencyValueType,
  EventCategoryValueType,
  LanguageValueType,
  LoggedInValueType,
  MixAndMatchValueType,
  PageTypeValueType,
  ProductClickedProperties,
  ProductViewedProperties,
  ProductsCart,
  PromotionViewedProperties,
} from '@susu/headless-commerce/libs/avo/avo'
import { productClicked } from '@susu/headless-commerce/libs/avo/avo'
import type { EnrichEventsProps } from '@susu/headless-commerce/libs/segment/utils'
import { enrichEvent } from '@susu/headless-commerce/libs/segment/utils'
import type { CountryConfiguration } from '@susu/headless-commerce/types/CountryConfiguration'
import type { PageType } from '@susu/headless-commerce/types/PageType'
import { isServer } from '@susu/headless-commerce/utils/environment'
import { pathnameWithoutLocalePrefix } from '@susu/headless-commerce/utils/pathname'
import type {
  SSMProduct,
  SSMTrackingProduct,
} from '@susu/headless-commerce/utils/tracking/GA'
import type { PromotionEvents } from '@susu/headless-commerce/utils/tracking/tracking'
import { isNotNull } from '@susu/null'
import { valuesToLowerCase } from '@susu/object'
import { isNotUndefined } from '@susu/undefined'

export type CartTrackingProduct = {
  amount: string
  identifier: string
  variation: string
  currency: string
  variant_id: string
  marketingcloudpid: string
  quantity: string
}

export type CartTrackingOneSize = {
  amount: string
  identifier: string
  variation: string
  currency: string
  variant_id: string
  marketingcloudpid: string
  quantity: string
}

export type CartTrackingCustomMade = {
  amount: string
  currency: string
  identifier: string
  quantity: string
  variant_id: string
}

export type CartTrackingGiftCard = {
  identifier: string
  amount: string
  currency: string
  variant_id: string
  quantity: string
}

export type CartProduct =
  | CartTrackingProduct
  | CartTrackingOneSize
  | CartTrackingCustomMade
  | CartTrackingGiftCard

export type SegmentPromotionData = {
  page_type: PageType
  url: string
  location_id: string
  promotion_id: string
  name: string
  creative: string
  position: number
}

export type CommonEventProperties = {
  country: CountryValueType
  currency: CurrencyValueType
  fbFbc: string
  fbFbp: string
  gaAwClid: string
  gaClientId: string
  gaSessionId: string
  gaSessionNumber: string
  language: LanguageValueType
  localeVisited: string
  loggedIn: LoggedInValueType
  userId: string
}

export type SegmentProductProps = {
  enrichProps: EnrichEventsProps
  product: Product
  tracking: ProductTrackingData
  pageType: PageType
  quantity: number
  productSetId?: string
  variant: string
  isConfiguratorProduct?: boolean
}

export type SegmentProduct = ProductClickedProperties | ProductViewedProperties

export enum SegmentHeaderType {
  HEADER_IS_TRANSPARENT = 'transparent',
  HEADER_IS_NOT_TRANSPARENT = 'white',
  HEADER_IS_TRANSPARENT_BLACK = 'black',
}

export const getSegmentHeaderTypeByCMSHeaderType = (
  cmsHeaderType: CMSHeaderType,
) => {
  if (cmsHeaderType === CMSHeaderType.HEADER_IS_NOT_TRANSPARENT) {
    return SegmentHeaderType.HEADER_IS_NOT_TRANSPARENT
  }

  return SegmentHeaderType.HEADER_IS_TRANSPARENT
}

export const URLData = (): {
  url: string
  locationId: string
  path: string
} => {
  if (isServer()) {
    return {
      url: '',
      locationId: '',
      path: '',
    }
  }

  const currentURL = new URL(window.location.href)
  const { pathname } = currentURL
  const withoutLocale = pathnameWithoutLocalePrefix(pathname)

  return {
    url: currentURL.href.replace(pathname, withoutLocale),
    locationId: currentURL.href,
    path: withoutLocale,
  }
}

export const ssmToProductCart = (
  item: SSMProduct,
  country: CountryConfiguration,
): ProductsCart => {
  const { amount, identifier, variation, currency, quantity, variantId } =
    item as SSMTrackingProduct

  return {
    amount: Number.parseFloat(amount),
    identifier: identifier.toLowerCase(),
    variation,
    currency: currency.toLowerCase() as CurrencyValueType,
    variantId: variantId.toLowerCase(),
    quantity: Number.parseInt(quantity, 10),
    marketingcloudpid: `${country.siteID}_${identifier}`.toLowerCase(),
  } as unknown as ProductsCart
}

export const segmentPromotionData = (
  promotionEvents: PromotionEvents,
  pageType: PageType,
): Omit<PromotionViewedProperties, keyof CommonEventProperties> => {
  const { url, locationId } = URLData()

  const { promotionClick, promotionCreative, promotionPosition } =
    promotionEvents

  return {
    pageType: pageType as PageTypeValueType,
    url,
    locationId,
    promotionId: promotionClick as string,
    name: promotionClick as string,
    creative: promotionCreative as CreativeValueType,
    position: promotionPosition ?? 1,
    ssMedium: undefined,
    ssSource: '',
    eventLabel: '',
    eventLocation: '',
  }
}

export const segmentProduct = ({
  enrichProps,
  isConfiguratorProduct,
  pageType,
  product,
  productSetId,
  quantity,
  tracking,
  variant,
}: SegmentProductProps): SegmentProduct => {
  const {
    badge,
    categoryId,
    fitId,
    id,
    mobileImage,
    name,
    nameInEnglish,
    onlineMaterial,
    price,
  } = product
  const { list, listId, position } = tracking
  const { url, locationId } = URLData()
  const { currency } = enrichEvent(enrichProps, {})

  const urlModified = isConfiguratorProduct
    ? getURLTrackingValue(
        new URL(window.location.href),
        categoryId as EventCategoryValueType,
      )
    : url

  const locationIdModified = isConfiguratorProduct
    ? getLocationIdValue(
        new URL(window.location.href),
        categoryId as EventCategoryValueType,
      )
    : locationId

  const eventData = enrichEvent(enrichProps, {
    affiliation: `webstore-${currency}` as AffiliationValueType,
    cartId: cart.value?.id as string,
    category: categoryId as EventCategoryValueType,
    fitId: fitId || '',
    fullLook: '',
    imageUrl: mobileImage?.url as string,
    itemCategory2: '',
    itemCategory3: '',
    itemCategory4: '',
    itemCategory5: '',
    itemListName: list,
    listId: listId,
    locationId: locationIdModified || '',
    mixAndMatch: (badge === 'label.badge-mix-match' || productSetId?.length
      ? 'true'
      : 'false') as MixAndMatchValueType,
    name: nameInEnglish?.length ? nameInEnglish : name,
    onlineMaterial: onlineMaterial || '',
    pageType: pageType || '',
    plpGridImages: '',
    position: position || 1,
    price: price || 0,
    productId: id,
    productSetId: productSetId ?? '',
    quantity: quantity || 1,
    shopTheLookId: '',
    sku: id,
    url: urlModified || '',
    value: price * quantity,
    variant: variant || '',
  })

  return valuesToLowerCase(eventData)
}

export const getLastPathUrl = (url: string): string => {
  return url.split('/')[url.split('/').length - 1]
}

export const segmentTrackingProductClick = (props: SegmentProductProps) => {
  const { product } = props
  const isConfiguratorProduct = props.isConfiguratorProduct ?? false

  if (product.type === 'master' || product.type === 'item') {
    productClicked(
      segmentProduct({
        ...props,
        isConfiguratorProduct,
      }) as ProductClickedProperties,
    )
  }

  if (product.type === 'set') {
    if (isNotUndefined(product.setProducts) && isNotNull(product.setProducts)) {
      for (const prd of product.setProducts) {
        productClicked(
          segmentProduct({
            ...props,
            product: prd,
            productSetId: product.id,
          }) as ProductClickedProperties,
        )
      }
    }
  }
}
