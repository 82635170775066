import { pathnameWithoutLocalePrefix } from '@susu/headless-commerce/utils/pathname'
import { usePathname } from 'next/navigation'
import type { PageTypeValueType } from '../libs/avo/avo'
import type { PageType } from '../types/PageType'

export const usePageType = (): PageType => {
  const pathname = pathnameWithoutLocalePrefix(usePathname())

  if (
    pathname.startsWith('/discover-all-suits') ||
    pathname.startsWith('/shop-tuxedo-collection')
  ) {
    return 'category_landing'
  }

  if (pathname.startsWith('/journal')) {
    return 'journal'
  }

  if (pathname.startsWith('/men/black-tie-package')) {
    return 'black_tie_configurator'
  }

  if (pathname === '/') {
    return 'home'
  }

  return 'home'
}
