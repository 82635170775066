'use client'

import ExternalLink from '@susu/headless-commerce/components/ExternalLink/ExternalLink'
import { ResponsiveImage } from '@susu/headless-commerce/components/ResponsiveImage/ResponsiveImage'
import { generateImageSourcesForCloudinary } from '@susu/headless-commerce/components/ResponsiveImage/utils'
import type { Locale } from '@susu/headless-commerce/config/locale'
import type {
  CarouselWithText,
  MediaWrapperV2,
  NavigationLink,
} from '@susu/headless-commerce/gql/generated/graphql'
import { usePageType } from '@susu/headless-commerce/hooks/usePageType'
import type { CountryConfiguration } from '@susu/headless-commerce/types/CountryConfiguration'
import { generateUrlFromLinkContent } from '@susu/headless-commerce/utils/UrlGenerator'
import type { PromotionEvents } from '@susu/headless-commerce/utils/tracking/tracking'
import {
  trackClickPromotion,
  trackImpressionPromotion,
} from '@susu/headless-commerce/utils/tracking/tracking'
import classNames from 'classnames'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styles from './ImageCarousel.module.scss'

import {
  isDesktopScreen,
  isMobileScreen,
  isTabletScreen,
} from '@susu/headless-commerce/utils/responsiveUtils'
import { isNull } from 'packages/null/dist'
import { isUndefined } from 'packages/undefined/dist'
import { getImageData } from './getImageData'

export type ImageCarouselProps = {
  country: CountryConfiguration
  carousel: CarouselWithText
  locale: Locale
  imageCount: number
}

export const ImageCarousel = ({
  country,
  carousel,
  locale,
  imageCount,
}: ImageCarouselProps) => {
  const {
    colorPalette,
    desktopCarrousel,
    imagesCollection,
    link,
    mobileCarrousel,
    tabletCarrousel,
    title,
  } = carousel
  const { promotionEvents } = link || {}

  const pageType = usePageType()
  const [isViewportPercentageFlagSet, setIsViewportPercentageFlagSet] =
    useState<boolean>(false)
  const imageCarouselRef = useRef<HTMLDivElement>(null)
  const [activeIndexPicture, setActiveIndexPicture] = useState<number>(0)

  // CSS classes
  const imageCarouselClass = styles['image-carousel']
  const imageCarouselWrapperClass = classNames(
    styles['image-carousel__wrapper'],
    {
      [styles['image-carousel__wrapper--carousel-mobile']]:
        mobileCarrousel?.toLowerCase() === 'yes',
      [styles['image-carousel__wrapper--carousel-tablet']]:
        tabletCarrousel?.toLowerCase() === 'yes',
      [styles['image-carousel__wrapper--carousel-desktop']]:
        desktopCarrousel?.toLowerCase() === 'yes',
      [styles['image-carousel__wrapper--static-desktop']]:
        desktopCarrousel?.toLowerCase() === 'no',
    },
  )
  const imageTitleClasses = classNames(
    'body-small-medium-sm title-03-medium-lg',
    styles[`image-carousel__title-text--${colorPalette}`],
    styles['image-carousel__title-text'],
  )

  if (isUndefined(imagesCollection) || isNull(imagesCollection)) {
    return null
  }

  const handleLinkTrackingClick = useCallback(() => {
    trackClickPromotion(
      imageCarouselRef,
      promotionEvents as PromotionEvents,
      pageType,
      {
        locale,
        country,
      },
    )
  }, [country, locale, pageType, promotionEvents])

  // Handle scroll function for setting the carousel animation
  const handleScroll = () => {
    if (isNull(imageCarouselRef.current)) {
      return
    }
    const { height, top } = imageCarouselRef.current.getBoundingClientRect()

    if (
      (isMobileScreen &&
        mobileCarrousel?.toLowerCase() === 'yes' &&
        isMobileScreen) ||
      (isTabletScreen &&
        tabletCarrousel?.toLowerCase() === 'yes' &&
        isTabletScreen)
    ) {
      // Two thirds of a viewport height
      const viewportPercentageFlag = Math.round((height / 3) * 2)
      if (!isViewportPercentageFlagSet && top < viewportPercentageFlag) {
        setIsViewportPercentageFlagSet(true)
        setInterval(() => {
          setActiveIndexPicture((activeIndexPicture) =>
            activeIndexPicture === 0 ? 1 : 0,
          )
        }, 3000)
      }
    }
  }

  // Bind scroll listener
  useEffect(() => {
    if (isNull(imageCarouselRef.current)) {
      return
    }
    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  })

  // Tracking effect for promotion impression
  useEffect(() => {
    if (imageCarouselRef.current) {
      trackImpressionPromotion(
        imageCarouselRef,
        promotionEvents as PromotionEvents,
        pageType,
        {
          locale,
          country,
        },
      )
    }
  }, [country, locale, pageType, promotionEvents])

  // OST-19217 current workaround for the image carousel block on desktop
  useEffect(() => {
    if (isDesktopScreen) {
      const imageCarouselBlock = document.querySelectorAll(
        '[class*="image-carousel-block"]',
      )[2]
      const targetElement = imageCarouselBlock?.querySelector(
        ':scope > :nth-child(2) > :first-child > :first-child > :nth-child(2)',
      )
      if (targetElement instanceof HTMLElement) {
        targetElement.style.display = 'none'
      }
    }
  }, [])

  return (
    <div
      className={imageCarouselClass}
      ref={imageCarouselRef}
      onClick={handleLinkTrackingClick}
      onKeyDown={handleLinkTrackingClick}
    >
      <ExternalLink
        className={styles['image-carousel__link']}
        href={generateUrlFromLinkContent(
          link as NavigationLink,
          country.siteID,
          locale,
        )}
        automationId={String(link?.automationId)}
      >
        <div className={imageCarouselWrapperClass}>
          {imagesCollection.items.map((carouselImage, index) => {
            // Image data
            const {
              altText,
              desktopSizes,
              desktopSrcSet,
              lazyloading,
              mobileSizes,
              mobileSrcSet,
              smallestUrl,
              tabletSizes,
              tabletSrcSet,
            } = getImageData({ carouselImage, imageCount })

            // Classes
            const responsiveImageClasses = classNames(
              styles['image-carousel__image'],
              {
                [styles['image-carousel__image--visible']]:
                  index === activeIndexPicture || isDesktopScreen,
              },
            )

            return (
              <ResponsiveImage
                alt={altText ?? ''}
                className={responsiveImageClasses}
                desktopSizes={desktopSizes}
                desktopSrcSet={desktopSrcSet}
                imgStyle={{ height: '100%', width: '100%', objectFit: 'cover' }}
                key={altText}
                lazyloading={lazyloading ?? true}
                mobileSizes={mobileSizes}
                mobileSrcSet={mobileSrcSet}
                pictureStyle={{ display: 'flex' }}
                src={smallestUrl}
                tabletSizes={tabletSizes}
                tabletSrcSet={tabletSrcSet}
              />
            )
          })}
        </div>
        <div className={styles['image-carousel__title']}>
          <div className={imageTitleClasses}>{title}</div>
        </div>
      </ExternalLink>
    </div>
  )
}
